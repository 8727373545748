<div class="modal fade" id="upload_photo_video" tabindex="-1" aria-labelledby="upload_photo_videoLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Photo/Video</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <ul class="nav nav-tabs" id="myTabContentUpload" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="photo-tab" data-bs-toggle="tab" data-bs-target="#photo" type="button"
              role="tab" aria-controls="photo" aria-selected="true">Photo</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video" type="button"
              role="tab" aria-controls="video" aria-selected="false">Video</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContentUploadContent">
          <div class="tab-pane fade show active" id="photo" role="tabpanel" aria-labelledby="photo-tab">
            <div class="form-group">
              <label class="form-label">Select images</label>
              <div class="row">
                <div class="col-10">
                  <input type="text" name="" class="form-control" placeholder="Or Drag and drop files here">
                </div>
                <div class="col-2 text-right">
                  <a class="btn btn-purple-dark">Select</a>
                </div>
              </div>
            </div>
            <p>Select images to upload (jpg, jepg, png and gif). </p>
            <div class="form-group">
              <label class="form-label">Description</label>
              <textarea type="text" name="" class="form-control" placeholder="Description"></textarea>
            </div>
            <div class="form-group">
              <label class="form-label">Tags</label>
            </div>
            <div class="white_box">
              <ul class="list-inline badge_tag">
                <li class="list-inline-item">
                  <span class="badge bg-green">
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                        fill="white"></path>
                    </svg>
                    AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
                  </span>
                </li>
                <li class="list-inline-item">
                  <span class="badge bg-green">
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                        fill="white"></path>
                    </svg>
                    HUNGER &amp; FOOD SYSTEMS <span class="close"><i class="fa fa-close"></i></span>
                  </span>
                </li>
                <li class="list-inline-item">
                  <span class="badge bg-green">
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                        fill="white"></path>
                    </svg>
                    TERRESTRIAL ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
                  </span>
                </li>
              </ul>
            </div>
            <hr>
            <button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Add</button>
          </div>
          <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
            <div class="form-group">
              <label class="form-label">Video Link</label>
              <input type="text" name="" class="form-control" placeholder="YouTube Video URL">
            </div>
            <div class="form-group">
              <label class="form-label">Description</label>
              <textarea type="text" name="" class="form-control" placeholder="Description"></textarea>
            </div>
            <div class="form-group">
              <label class="form-label">Tags</label>
            </div>
            <div class="white_box">
              <ul class="list-inline badge_tag">
                <li class="list-inline-item">
                  <span class="badge bg-green">
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                        fill="white"></path>
                    </svg>
                    AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
                  </span>
                </li>
                <li class="list-inline-item">
                  <span class="badge bg-green">
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                        fill="white"></path>
                    </svg>
                    HUNGER &amp; FOOD SYSTEMS <span class="close"><i class="fa fa-close"></i></span>
                  </span>
                </li>
                <li class="list-inline-item">
                  <span class="badge bg-green">
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                        fill="white"></path>
                    </svg>
                    TERRESTRIAL ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
                  </span>
                </li>
              </ul>
            </div>
            <hr>
            <button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
