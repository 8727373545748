<section class="profile_header" style="background: url(../assets/images/profile_header1.png);">
<div class="container">
	<div class="row">
		<div class="col-12">
			<div class="white_box">
				<div class="row">
					<div class="col-12 col-md-10">
						<div class="profile_flex">
							<div class="img_box">
								<img src="../assets/images/profile_header_user1.png" alt="#">
							</div>
							<div class="text_box">
								<h4>Santa Ana Trail Cleanup
									<div class="dropdown">
										<button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
											data-bs-toggle="dropdown" aria-expanded="false">
											<svg width="17" height="17" viewBox="0 0 17 17" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd"
													d="M10.0823 1.24413C9.67959 -0.414708 7.32042 -0.414709 6.9177 1.24413C6.65756 2.31571 5.42985 2.82424 4.48817 2.25047C3.03044 1.36226 1.36226 3.03044 2.25047 4.48817C2.82424 5.42985 2.31571 6.65756 1.24413 6.9177C-0.414709 7.32041 -0.414708 9.67959 1.24413 10.0823C2.31571 10.3424 2.82424 11.5701 2.25047 12.5118C1.36226 13.9696 3.03044 15.6377 4.48818 14.7495C5.42985 14.1758 6.65756 14.6843 6.9177 15.7559C7.32042 17.4147 9.67959 17.4147 10.0823 15.7559C10.3424 14.6843 11.5701 14.1758 12.5118 14.7495C13.9696 15.6377 15.6377 13.9696 14.7495 12.5118C14.1758 11.5701 14.6843 10.3424 15.7559 10.0823C17.4147 9.67959 17.4147 7.32041 15.7559 6.9177C14.6843 6.65756 14.1758 5.42985 14.7495 4.48817C15.6377 3.03044 13.9696 1.36226 12.5118 2.25047C11.5701 2.82424 10.3424 2.31571 10.0823 1.24413ZM8.5 11.6875C10.2604 11.6875 11.6875 10.2604 11.6875 8.5C11.6875 6.73959 10.2604 5.3125 8.5 5.3125C6.73959 5.3125 5.3125 6.73959 5.3125 8.5C5.3125 10.2604 6.73959 11.6875 8.5 11.6875Z"
													fill="#272D35" />
											</svg>
										</button>
										<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
											<li><a class="dropdown-item" href="#">Action</a></li>
											<li><a class="dropdown-item" href="#">Another action</a></li>
											<li><a class="dropdown-item" href="#">Something else here</a></li>
										</ul>
									</div>
								</h4>
								<h6>Ann Arbor, Michigan, USA | <a href="#">Community Forest Alliance</a></h6>
								<p>We’re committed to honoring and protecting our local forests by cleaning up
									litter, participating in local hiker education, and participating in planning
									for a more regenerative future for this great land.</p>
								<h5>Purpose</h5>
								<ul class="list-inline">
									<li class="list-inline-item">
										<span class="badge bg-green">
											<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
													fill="white" />
											</svg>
											terrestrial ecology</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-2">
						<div class="profile_btn_flex">
							<a id="opened-donation-modal" href="#" class="btn btn-green" data-bs-toggle="modal"
								data-bs-target="#donation_popup">Donate</a>
							<a href="#" class="btn btn-purple-dark">Contact</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</section>
<section class="profile_dashboard">
<div class="top_navigation">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
						<button class="nav-link active" id="overview-tab" data-bs-toggle="tab"
							data-bs-target="#overview" type="button" role="tab" aria-controls="overview"
							aria-selected="true">Overview</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="feed-tab" data-bs-toggle="tab" data-bs-target="#feed"
							type="button" role="tab" aria-controls="feed" aria-selected="false">Feed</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="management-tab" data-bs-toggle="tab"
							data-bs-target="#management" type="button" role="tab" aria-controls="management"
							aria-selected="false">Management</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard"
							type="button" role="tab" aria-controls="dashboard"
							aria-selected="false">Dashboard</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="discussion-tab" data-bs-toggle="tab"
							data-bs-target="#discussion" type="button" role="tab" aria-controls="discussion"
							aria-selected="false">Discussion</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="collaborators-tab" data-bs-toggle="tab"
							data-bs-target="#collaborators" type="button" role="tab" aria-controls="collaborators"
							aria-selected="false">Collaborators</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects"
							type="button" role="tab" aria-controls="projects"
							aria-selected="false">Projects</button>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<div class="tab-content" id="myTabContent">
	<div class="tab-pane fade" id="overview" role="tabpanel" aria-labelledby="overview-tab">
		<div class="container">
			<div class="gutter_padding">
				<div class="row justify-content-between">
					<div class="col-12 col-md-7">
						<h4>Project Details</h4>
						<p>Like many of the best ideas, Clean Trails was more of a slow trickle than a sudden
							thunderclap. Steve Jewett was always an avid hiker and climber, and gradually he became
							aware of trash on his favorite trails. He and his hiking partner, Bill Willoughby began
							to pick up items like cigarette butts, candy wrappers, and water bottle caps on their
							hikes together; at first just picking up what they could fit in their pockets and later
							carrying a bag with them.<br><br>

							There was still the problem of trash they didn’t want to touch with their bare hands, so
							one day Steve started trying out various types of barbecue tongs. Steve and Bill started
							to make a game of it; Bill would spot the trash and Steve would pick it up. If Bill
							missed a piece, Steve would point it out with a very loud, “You missed one!” They were
							having fun, and other hikers on the trail noticed and wanted to join in on the fun too.
						</p>
						<img src="../assets/images/profile_overview_img1.png" alt="#" class="profile_overview_img">
						<p>While hiking, Steve and Bill would talk about your typical “save the world” topics, like
							ending hunger, homelessness, the world’s economic woes, and especially how their
							favorite places were becoming spoiled, so they began to brainstorm about how to motivate
							others to have fun while cleaning trails. They wanted to expand the idea to a nationwide
							movement.<br><br>

							Both Steve and Bill are serial entrepreneurs, Steve focusing in sales and Bill in
							marketing. Using their business knowledge base, they searched for concepts that would
							allow scaling nationally. While they hiked, they continually processed their ideas and
							concepts, validating some and eliminating those that they found impractical.<br><br>

							Steve and Bill then tested their concepts on hikers they encountered, discussing their
							successes and failures to discover which were the most effective and discarding those
							that did not work. They applied business and marketing processes to include the
							newly-learned information, creating a plan for the successful launch of a sustainable
							nonprofit organization called Clean Trails. They envisioned an operation that would be
							financially stable and not rely upon continuous fundraising, with a plan that would
							impact every type of trail, leading to a cleaner outdoor environment throughout the
							United States.<br><br>

							Knowing the importance of bringing in smart people who have experience in non-profits,
							fundraising and community organizing, Steve and Bill began the search to recruit board
							members and advisors. The next task was to bring in volunteers to support the local
							outdoor communities. Expanding into colleges, universities and academia, the
							organization has been successful in attracting a wide spectrum of hikers, bikers,
							skiers, snow boarders and outdoor enthusiasts. We are actively engaged in increasing our
							membership base and board and advisor representation.<br><br>

							Nulla facilisi. Morbi eget leo vel diam tempor sollicitudin et eu odio. Pellentesque et
							risus quis leo hendrerit mattis. Vivamus varius consequat commodo. Nam non fermentum
							neque. Morbi sagittis nisl euismod, rutrum arcu in, gravida ligula. Mauris mattis mollis
							eros ut posuere.<br><br>

							Cras hendrerit ornare ipsum non ultricies. Aliquam aliquet lorem sed leo pharetra, eget
							dignissim ex lobortis. Vivamus ut dignissim nunc. Curabitur fringilla ligula in lectus
							tristique hendrerit et quis justo. Quisque facilisis tincidunt ante convallis venenatis.
							Quisque lorem urna, accumsan vel dui vitae, volutpat fringilla neque. Donec eu
							condimentum sem, vel condimentum massa. Ut sit amet nunc rutrum, commodo odio at,
							maximus urna. Phasellus id diam et metus pretium elementum. Nulla facilisi. Cras non
							turpis tincidunt, efficitur augue vel, ullamcorper velit. Fusce tempor sapien nisi, eu
							semper orci pulvinar vitae.</p>
					</div>
					<div class="col-12 col-md-4">
						<div class="white_box">
							<h5>Progress</h5>
							<hr>
							<div class="progress_wrap">
								<div class="progress_text_lg">$56,395 <span>Raised</span></div>
								<div class="progress_text_grey">/ $70,000 Goal</div>
								<div class="progress">
									<div class="progress-bar bg-green" role="progressbar" style="width: 75%"
										aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
							</div>
							<hr>
							<h5 class="small_text">Hours Contributed</h5>
							<div class="progress_wrap">
								<div class="progress_text_lg">537 <span> hours</span></div>
							</div>
							<hr>
							<h5 class="small_text">Results</h5>
							<div class="row">
								<div class="col-6 br-grey">
									<div class="progress_wrap">
										<div class="progress_text_lg">10<span class="grey">/100</span>
											<div class="progress_text_grey">Tons litter cleaned</div>
										</div>
										<div class="progress">
											<div class="progress-bar bg-green" role="progressbar" style="width: 75%"
												aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
								</div>
								<div class="col-6">
									<div class="progress_wrap">
										<div class="progress_text_lg">70<span class="grey">/200</span>
											<div class="progress_text_grey">Trees planted</div>
										</div>
										<div class="progress">
											<div class="progress-bar bg-green" role="progressbar" style="width: 75%"
												aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
								</div>
							</div>
							<hr>
							<div class="row">
								<div class="col-6 br-grey">
									<div class="progress_wrap">
										<div class="progress_text_lg">30<span class="grey">/200</span>
											<div class="progress_text_grey">Miles marked</div>
										</div>
										<div class="progress">
											<div class="progress-bar bg-green" role="progressbar" style="width: 75%"
												aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
								</div>
								<div class="col-6">
									<div class="progress_wrap">
										<div class="progress_text_lg">141<span class="grey">/300</span>
											<div class="progress_text_grey">Volunteers</div>
										</div>
										<div class="progress">
											<div class="progress-bar bg-green" role="progressbar" style="width: 75%"
												aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="white_box">
							<h5>Needs</h5>
							<hr>
							<h5 class="badge_heading">Skills</h5>
							<ul class="list-inline badge_tag">
								<li class="list-inline-item">
									<span class="badge bg-red">
										<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
												fill="white" />
										</svg>
										ADVERTISING
									</span>
								</li>
								<li class="list-inline-item">
									<span class="badge bg-red">
										<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
												fill="white" />
										</svg>
										SOIL ANALYSIS
									</span>
								</li>
								<li class="list-inline-item">
									<span class="badge bg-red">
										<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
												fill="white" />
										</svg>
										ENVIRONMENTAL POLICY
									</span>
								</li>
							</ul>
							<hr>
							<h5 class="badge_heading">Knowledge</h5>
							<ul class="list-inline badge_tag">
								<li class="list-inline-item">
									<span class="badge bg-gold">
										<svg width="9" height="12" viewBox="0 0 9 12" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M0.75043 11.91C0.43704 12.1354 0 11.9115 0 11.5254V2.05293C0 0.91913 0.91913 0 2.05293 0H6.78941C7.92326 0 8.84234 0.91913 8.84234 2.05293V11.5254C8.84234 11.9115 8.40535 12.1354 8.09191 11.91L4.42116 9.26898L0.75043 11.91Z"
												fill="white" />
										</svg>
										EARTH SCIENCE <span class="close"><i class="fa fa-close"></i></span>
									</span>
								</li>
								<li class="list-inline-item">
									<span class="badge bg-gold">
										<svg width="9" height="12" viewBox="0 0 9 12" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M0.75043 11.91C0.43704 12.1354 0 11.9115 0 11.5254V2.05293C0 0.91913 0.91913 0 2.05293 0H6.78941C7.92326 0 8.84234 0.91913 8.84234 2.05293V11.5254C8.84234 11.9115 8.40535 12.1354 8.09191 11.91L4.42116 9.26898L0.75043 11.91Z"
												fill="white" />
										</svg>
										CLIMATE <span class="close"><i class="fa fa-close"></i></span>
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="tab-pane fade" id="feed" role="tabpanel" aria-labelledby="feed-tab">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-8">
					<div class="white_box">
						<div class="row">
							<div class="col-6">
								<h5>tasks</h5>
							</div>
							<div class="col-6 text-right">
								<a href="#" class="btn btn-white" data-bs-toggle="modal"
									data-bs-target="#new_task_popup">+ Add Task</a>
							</div>
						</div>
						<hr>
						<div class="feed_table">
							<table class="table">
								<thead>
									<tr>
										<th width="35%">name</th>
										<th>Group</th>
										<th width="20%">Due</th>
										<th width="5%"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											Trail Cleanup Week of 5/31
										</td>
										<td>
											Community Forest Alliance
										</td>
										<td>
											5/31
										</td>
										<td width="5%" class="text-right">
											<a href="#" class="btn btn-purple-dark">GO</a>
										</td>
									</tr>
									<tr>
										<td>
											Complete research & mapping
										</td>
										<td>
											Community Forest Alliance
										</td>
										<td>
											6/26
										</td>
										<td width="5%" class="text-right">
											<a href="#" class="btn-purple-dark">GO</a>
										</td>
									</tr>
									<tr>
										<td>
											Supervising volunteers
										</td>
										<td>
											Community Forest Alliance
										</td>
										<td>
											7/30
										</td>
										<td width="5%" class="text-right">
											<a href="#" class="btn-purple-dark">GO</a>
										</td>
									</tr>
									<tr>
										<td>
											Graphic design for flyers
										</td>
										<td>
											Community Forest Alliance
										</td>
										<td>
											8/6
										</td>
										<td width="5%" class="text-right">
											<a href="#" class="btn-purple-dark">GO</a>
										</td>
									</tr>
									<tr>
										<td>
											Updates to website
										</td>
										<td>
											Community Forest Alliance
										</td>
										<td>
											9/15
										</td>
										<td width="5%" class="text-right">
											<a href="#" class="btn-purple-dark">GO</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="post_creater">
						<textarea name="" placeholder="Hi Leslie, what’s on your mind?"
							class="form-control"></textarea>
						<div class="post_creater_opt">
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="opt_wrap">
										<a href="#" class="btn btn-purple-dark">Post</a>
										<a href="#" class="opt_flex">
											<span class="icon"><img src="../assets/images/gear.svg"></span> Post
											Settings
										</a>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="opt_wrap opt_wrap2">
										<a href="javascript:void(0)" class="opt_flex" data-bs-toggle="modal"
										data-bs-target="#upload">
											Attachment <span class="icon"><img
													src="../assets/images/zip.svg"></span>
										</a>
										<a href="#" class="opt_flex" data-bs-toggle="modal"
											data-bs-target="#upload_photo_video">
											Photo/Video <span class="icon"><img
													src="../assets/images/photo.svg"></span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ul class="nav nav-tabs" id="myTabPost" role="tablist">
						<li class="nav-item" role="presentation">
							<button class="nav-link active" id="all_post-tab" data-bs-toggle="tab"
								data-bs-target="#all_post" type="button" role="tab" aria-controls="all_post"
								aria-selected="true">All Post</button>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" id="pin_post-tab" data-bs-toggle="tab"
								data-bs-target="#pin_post" type="button" role="tab" aria-controls="pin_post"
								aria-selected="false">Pinned Post (5)</button>
						</li>
					</ul>
					<div class="tab-content" id="myTabPostContent">
						<div class="tab-pane fade p-0 show active" id="all_post" role="tabpanel"
							aria-labelledby="all_post-tab">
							<div class="post_box">
								<div class="posted_content">
									<div class="poster_wrap">
										<div class="row">
											<div class="col-7">
												<div class="poster_flex">
													<img src="../assets/images/collab1.png" alt="#">
													<div class="text_box">
														<div class="name">Jenny Wilson</div>
														<div class="time_posted">1 day old</div>
													</div>
												</div>
											</div>
											<div class="col-5">
												<div class="post_opt">
													<span class="post_pinn"><img src="../assets/images/pin.svg"
															alt="#"></span>
													<div class="dropdown">
														<button class="dropdown-toggle" type="button"
															id="dropdownMenuButton1" data-bs-toggle="dropdown"
															aria-expanded="false">
															<img src="../assets/images/ellipse.svg" alt="#">
														</button>
														<ul class="dropdown-menu"
															aria-labelledby="dropdownMenuButton1">
															<li><a class="dropdown-item" href="#">Pin Post</a></li>
															<li><a class="dropdown-item" href="#">Delete</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										facilisis eros ut tortor suscipit, vel rhoncus quam placerat. Ut tincidunt
										eu nulla eget laoreet. Curabitur eu finibus diam. Pellentesque luctus tellus
										lorem, vel mollis nulla mattis consectetur. Suspendisse id convallis est.
									</p>
									<img src="../assets/images/feed1.png" alt="#" class="posted_img">
								</div>
								<div class="post_discussion">
									<div class="comment_flex">
										<img src="../assets/images/collab1.png" alt="#">
										<form method="post">
											<div class="form-group">
												<textarea class="form-control"
													placeholder="Leave a comment"></textarea>
												<a href="#" class="btn btn-grey">Post</a>
											</div>
										</form>
									</div>
									<div class="commented_flex">
										<img src="../assets/images/collab2.png" alt="#">
										<div class="text_box">
											<div class="comment_box">
												<a href="#" class="name">Jenny Wilson</a>
												<p>Proin ornare nunc diam, ac posuere nunc pellentesque sit amet.
												</p>
											</div>
											<ul class="list-inline comment_info">
												<li class="list-inline-item"><a href="#">Reply</a></li>
												<li class="list-inline-item"><a href="#">1 day old</a></li>
												<li class="list-inline-item"><a href="#">Delete Comment</a></li>
											</ul>
										</div>
									</div>
									<div class="replied_wrap">
										<div class="commented_flex">
											<img src="../assets/images/collab3.png" alt="#">
											<div class="text_box">
												<div class="comment_box">
													<a href="#" class="name">Eleanor Pena</a>
													<p><a href="#">Jenny Wilson</a> of course!</p>
												</div>
												<ul class="list-inline comment_info">
													<li class="list-inline-item"><a href="#">Reply</a></li>
													<li class="list-inline-item"><a href="#">20m</a></li>
												</ul>
											</div>
										</div>
										<div class="comment_flex">
											<img src="../assets/images/collab1.png" alt="#">
											<form method="post">
												<div class="form-group">
													<textarea class="form-control"
														placeholder="Leave a comment"></textarea>
													<a href="#" class="btn btn-grey">Post</a>
												</div>
											</form>
										</div>
									</div>
									<div class="commented_flex">
										<img src="../assets/images/collab4.png" alt="#">
										<div class="text_box">
											<div class="comment_box">
												<a href="#" class="name">Kathryn Murphy</a>
												<p>Dooooooooope</p>
											</div>
											<ul class="list-inline comment_info">
												<li class="list-inline-item"><a href="#">Reply</a></li>
												<li class="list-inline-item"><a href="#">20m</a></li>
											</ul>
										</div>
									</div>
									<a href="#" class="view_comment_link">View all 30 comments</a>
								</div>
							</div>
							<div class="post_box">
								<div class="posted_content">
									<div class="poster_wrap">
										<div class="row">
											<div class="col-7">
												<div class="poster_flex">
													<img src="../assets/images/collab1.png" alt="#">
													<div class="text_box">
														<div class="name">Esther Howard</div>
														<div class="time_posted">2 days old</div>
													</div>
												</div>
											</div>
											<div class="col-5">
												<div class="post_opt">
													<div class="dropdown">
														<button class="dropdown-toggle" type="button"
															id="dropdownMenuButton1" data-bs-toggle="dropdown"
															aria-expanded="false">
															<img src="../assets/images/ellipse.svg" alt="#">
														</button>
														<ul class="dropdown-menu"
															aria-labelledby="dropdownMenuButton1">
															<li><a class="dropdown-item" href="#">Pin Post</a></li>
															<li><a class="dropdown-item" href="#">Delete</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										facilisis eros ut tortor suscipit, vel rhoncus quam placerat. Ut tincidunt
										eu nulla eget laoreet. Curabitur eu finibus diam. Pellentesque luctus tellus
										lorem, vel mollis nulla mattis consectetur. Suspendisse id convallis est.
										Sed leo tellus, ullamcorper id finibus et, gravida ac justo. Proin ornare
										nunc diam, ac posuere nunc pellentesque sit amet. Donec eu dignissim neque,
										sed maximus massa.<br><br>
										Ut rhoncus eget arcu in consectetur. Mauris turpis ligula, pellentesque id
										imperdiet a, auctor non nunc. Etiam bibendum varius nunc. Interdum et
										malesuada fames ac ante ipsum primis in faucibus. Vivamus quis ipsum vel
										felis commodo maximus in sodales sem. Duis ut efficitur nunc. Pellentesque
										malesuada faucibus enim sagittis rhoncus.</p>
									<a href="#" class="website_link_box">
										<div class="img_box">
											<img src="../assets/images/link_img.png" alt="#">
										</div>
										<div class="text_box">
											<div class="url">lipsum.com</div>
											<div class="name">Wikipedia, the free encyclopedia</div>
											<div class="desc">Welcome to Wikipedia,. the free encyclopedia that
												anyone can edit.</div>
										</div>
									</a>
								</div>
								<div class="post_discussion">
									<div class="comment_flex mb-0">
										<img src="../assets/images/collab1.png" alt="#">
										<form method="post">
											<div class="form-group">
												<textarea class="form-control"
													placeholder="Leave a comment"></textarea>
												<a href="#" class="btn btn-grey">Post</a>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="post_box">
								<div class="posted_content">
									<div class="poster_wrap">
										<div class="row">
											<div class="col-7">
												<div class="poster_flex">
													<img src="../assets/images/collab1.png" alt="#">
													<div class="text_box">
														<div class="name">Esther Howard</div>
														<div class="time_posted">2 days old</div>
													</div>
												</div>
											</div>
											<div class="col-5">
												<div class="post_opt">
													<div class="dropdown">
														<button class="dropdown-toggle" type="button"
															id="dropdownMenuButton1" data-bs-toggle="dropdown"
															aria-expanded="false">
															<img src="../assets/images/ellipse.svg" alt="#">
														</button>
														<ul class="dropdown-menu"
															aria-labelledby="dropdownMenuButton1">
															<li><a class="dropdown-item" href="#">Pin Post</a></li>
															<li><a class="dropdown-item" href="#">Delete</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										facilisis eros ut tortor suscipit, vel rhoncus quam placerat. Ut tincidunt
										eu nulla eget laoreet. Curabitur eu finibus diam. Pellentesque luctus tellus
										lorem, vel mollis nulla mattis consectetur. Suspendisse id convallis est.
									</p>
									<div class="uploaded_img">
										<div class="row">
											<div class="col-12 col-md-6">
												<div class="img_box">
													<img src="../assets/images/feed2.png" alt="#">
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="row">
													<div class="col-12 col-md-6">
														<div class="img_box">
															<img src="../assets/images/feed3.png" alt="#">
														</div>
													</div>
													<div class="col-12 col-md-6">
														<div class="img_box">
															<img src="../assets/images/feed4.png" alt="#">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-12 col-md-6">
														<div class="img_box">
															<img src="../assets/images/feed5.png" alt="#">
														</div>
													</div>
													<div class="col-12 col-md-6">
														<div class="img_box">
															<img src="../assets/images/feed6.png" alt="#">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="post_discussion">
									<div class="comment_flex mb-0">
										<img src="../assets/images/collab1.png" alt="#">
										<form method="post">
											<div class="form-group">
												<textarea class="form-control"
													placeholder="Leave a comment"></textarea>
												<a href="#" class="btn btn-grey">Post</a>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="post_box">
								<div class="posted_content">
									<div class="poster_wrap">
										<div class="row">
											<div class="col-7">
												<div class="poster_flex">
													<img src="../assets/images/collab1.png" alt="#">
													<div class="text_box">
														<div class="name">Esther Howard</div>
														<div class="time_posted">2 days old</div>
													</div>
												</div>
											</div>
											<div class="col-5">
												<div class="post_opt">
													<div class="dropdown">
														<button class="dropdown-toggle" type="button"
															id="dropdownMenuButton1" data-bs-toggle="dropdown"
															aria-expanded="false">
															<img src="../assets/images/ellipse.svg" alt="#">
														</button>
														<ul class="dropdown-menu"
															aria-labelledby="dropdownMenuButton1">
															<li><a class="dropdown-item" href="#">Pin Post</a></li>
															<li><a class="dropdown-item" href="#">Delete</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										facilisis eros ut tortor suscipit, vel rhoncus quam placerat. Ut tincidunt
										eu nulla eget laoreet. Curabitur eu finibus diam. Pellentesque luctus tellus
										lorem, vel mollis nulla mattis consectetur. Suspendisse id convallis est.
									</p>
								</div>
								<div class="post_discussion">
									<div class="comment_flex mb-0">
										<img src="../assets/images/collab1.png" alt="#">
										<form method="post">
											<div class="form-group">
												<textarea class="form-control"
													placeholder="Leave a comment"></textarea>
												<a href="#" class="btn btn-grey">Post</a>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade p-0" id="pin_post" role="tabpanel" aria-labelledby="pin_post-tab">
							<div class="post_box">
								<div class="posted_content">
									<div class="poster_wrap">
										<div class="row">
											<div class="col-7">
												<div class="poster_flex">
													<img src="../assets/images/collab1.png" alt="#">
													<div class="text_box">
														<div class="name">Jenny Wilson</div>
														<div class="time_posted">1 day old</div>
													</div>
												</div>
											</div>
											<div class="col-5">
												<div class="post_opt">
													<span class="post_pinn"><img src="../assets/images/pin.svg"
															alt="#"></span>
													<div class="dropdown">
														<button class="dropdown-toggle" type="button"
															id="dropdownMenuButton1" data-bs-toggle="dropdown"
															aria-expanded="false">
															<img src="../assets/images/ellipse.svg" alt="#">
														</button>
														<ul class="dropdown-menu"
															aria-labelledby="dropdownMenuButton1">
															<li><a class="dropdown-item" href="#">Pin Post</a></li>
															<li><a class="dropdown-item" href="#">Delete</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										facilisis eros ut tortor suscipit, vel rhoncus quam placerat. Ut tincidunt
										eu nulla eget laoreet. Curabitur eu finibus diam. Pellentesque luctus tellus
										lorem, vel mollis nulla mattis consectetur. Suspendisse id convallis est.
									</p>
									<img src="../assets/images/feed1.png" alt="#" class="posted_img">
								</div>
								<div class="post_discussion">
									<div class="comment_flex">
										<img src="../assets/images/collab1.png" alt="#">
										<form method="post">
											<div class="form-group">
												<textarea class="form-control"
													placeholder="Leave a comment"></textarea>
												<a href="#" class="btn btn-grey">Post</a>
											</div>
										</form>
									</div>
									<div class="commented_flex">
										<img src="../assets/images/collab2.png" alt="#">
										<div class="text_box">
											<div class="comment_box">
												<a href="#" class="name">Jenny Wilson</a>
												<p>Proin ornare nunc diam, ac posuere nunc pellentesque sit amet.
												</p>
											</div>
											<ul class="list-inline comment_info">
												<li class="list-inline-item"><a href="#">Reply</a></li>
												<li class="list-inline-item"><a href="#">1 day old</a></li>
												<li class="list-inline-item"><a href="#">Delete Comment</a></li>
											</ul>
										</div>
									</div>
									<div class="replied_wrap">
										<div class="commented_flex">
											<img src="../assets/images/collab3.png" alt="#">
											<div class="text_box">
												<div class="comment_box">
													<a href="#" class="name">Eleanor Pena</a>
													<p><a href="#">Jenny Wilson</a> of course!</p>
												</div>
												<ul class="list-inline comment_info">
													<li class="list-inline-item"><a href="#">Reply</a></li>
													<li class="list-inline-item"><a href="#">20m</a></li>
												</ul>
											</div>
										</div>
										<div class="comment_flex">
											<img src="../assets/images/collab1.png" alt="#">
											<form method="post">
												<div class="form-group">
													<textarea class="form-control"
														placeholder="Leave a comment"></textarea>
													<a href="#" class="btn btn-grey">Post</a>
												</div>
											</form>
										</div>
									</div>
									<div class="commented_flex">
										<img src="../assets/images/collab4.png" alt="#">
										<div class="text_box">
											<div class="comment_box">
												<a href="#" class="name">Kathryn Murphy</a>
												<p>Dooooooooope</p>
											</div>
											<ul class="list-inline comment_info">
												<li class="list-inline-item"><a href="#">Reply</a></li>
												<li class="list-inline-item"><a href="#">20m</a></li>
											</ul>
										</div>
									</div>
									<a href="#" class="view_comment_link">View all 30 comments</a>
								</div>
							</div>
							<div class="post_box">
								<div class="posted_content">
									<div class="poster_wrap">
										<div class="row">
											<div class="col-7">
												<div class="poster_flex">
													<img src="../assets/images/collab1.png" alt="#">
													<div class="text_box">
														<div class="name">Esther Howard</div>
														<div class="time_posted">2 days old</div>
													</div>
												</div>
											</div>
											<div class="col-5">
												<div class="post_opt">
													<div class="dropdown">
														<button class="dropdown-toggle" type="button"
															id="dropdownMenuButton1" data-bs-toggle="dropdown"
															aria-expanded="false">
															<img src="../assets/images/ellipse.svg" alt="#">
														</button>
														<ul class="dropdown-menu"
															aria-labelledby="dropdownMenuButton1">
															<li><a class="dropdown-item" href="#">Pin Post</a></li>
															<li><a class="dropdown-item" href="#">Delete</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										facilisis eros ut tortor suscipit, vel rhoncus quam placerat. Ut tincidunt
										eu nulla eget laoreet. Curabitur eu finibus diam. Pellentesque luctus tellus
										lorem, vel mollis nulla mattis consectetur. Suspendisse id convallis est.
										Sed leo tellus, ullamcorper id finibus et, gravida ac justo. Proin ornare
										nunc diam, ac posuere nunc pellentesque sit amet. Donec eu dignissim neque,
										sed maximus massa.<br><br>
										Ut rhoncus eget arcu in consectetur. Mauris turpis ligula, pellentesque id
										imperdiet a, auctor non nunc. Etiam bibendum varius nunc. Interdum et
										malesuada fames ac ante ipsum primis in faucibus. Vivamus quis ipsum vel
										felis commodo maximus in sodales sem. Duis ut efficitur nunc. Pellentesque
										malesuada faucibus enim sagittis rhoncus.</p>
									<a href="#" class="website_link_box">
										<div class="img_box">
											<img src="../assets/images/link_img.png" alt="#">
										</div>
										<div class="text_box">
											<div class="url">lipsum.com</div>
											<div class="name">Wikipedia, the free encyclopedia</div>
											<div class="desc">Welcome to Wikipedia,. the free encyclopedia that
												anyone can edit.</div>
										</div>
									</a>
								</div>
								<div class="post_discussion">
									<div class="comment_flex mb-0">
										<img src="../assets/images/collab1.png" alt="#">
										<form method="post">
											<div class="form-group">
												<textarea class="form-control"
													placeholder="Leave a comment"></textarea>
												<a href="#" class="btn btn-grey">Post</a>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="post_box">
								<div class="posted_content">
									<div class="poster_wrap">
										<div class="row">
											<div class="col-7">
												<div class="poster_flex">
													<img src="../assets/images/collab1.png" alt="#">
													<div class="text_box">
														<div class="name">Esther Howard</div>
														<div class="time_posted">2 days old</div>
													</div>
												</div>
											</div>
											<div class="col-5">
												<div class="post_opt">
													<div class="dropdown">
														<button class="dropdown-toggle" type="button"
															id="dropdownMenuButton1" data-bs-toggle="dropdown"
															aria-expanded="false">
															<img src="../assets/images/ellipse.svg" alt="#">
														</button>
														<ul class="dropdown-menu"
															aria-labelledby="dropdownMenuButton1">
															<li><a class="dropdown-item" href="#">Pin Post</a></li>
															<li><a class="dropdown-item" href="#">Delete</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										facilisis eros ut tortor suscipit, vel rhoncus quam placerat. Ut tincidunt
										eu nulla eget laoreet. Curabitur eu finibus diam. Pellentesque luctus tellus
										lorem, vel mollis nulla mattis consectetur. Suspendisse id convallis est.
									</p>
									<div class="uploaded_img">
										<div class="row">
											<div class="col-12 col-md-6">
												<div class="img_box">
													<img src="../assets/images/feed2.png" alt="#">
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="row">
													<div class="col-12 col-md-6">
														<div class="img_box">
															<img src="../assets/images/feed3.png" alt="#">
														</div>
													</div>
													<div class="col-12 col-md-6">
														<div class="img_box">
															<img src="../assets/images/feed4.png" alt="#">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-12 col-md-6">
														<div class="img_box">
															<img src="../assets/images/feed5.png" alt="#">
														</div>
													</div>
													<div class="col-12 col-md-6">
														<div class="img_box">
															<img src="../assets/images/feed6.png" alt="#">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="post_discussion">
									<div class="comment_flex mb-0">
										<img src="../assets/images/collab1.png" alt="#">
										<form method="post">
											<div class="form-group">
												<textarea class="form-control"
													placeholder="Leave a comment"></textarea>
												<a href="#" class="btn btn-grey">Post</a>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="post_box">
								<div class="posted_content">
									<div class="poster_wrap">
										<div class="row">
											<div class="col-7">
												<div class="poster_flex">
													<img src="../assets/images/collab1.png" alt="#">
													<div class="text_box">
														<div class="name">Esther Howard</div>
														<div class="time_posted">2 days old</div>
													</div>
												</div>
											</div>
											<div class="col-5">
												<div class="post_opt">
													<div class="dropdown">
														<button class="dropdown-toggle" type="button"
															id="dropdownMenuButton1" data-bs-toggle="dropdown"
															aria-expanded="false">
															<img src="../assets/images/ellipse.svg" alt="#">
														</button>
														<ul class="dropdown-menu"
															aria-labelledby="dropdownMenuButton1">
															<li><a class="dropdown-item" href="#">Pin Post</a></li>
															<li><a class="dropdown-item" href="#">Delete</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										facilisis eros ut tortor suscipit, vel rhoncus quam placerat. Ut tincidunt
										eu nulla eget laoreet. Curabitur eu finibus diam. Pellentesque luctus tellus
										lorem, vel mollis nulla mattis consectetur. Suspendisse id convallis est.
									</p>
								</div>
								<div class="post_discussion">
									<div class="comment_flex mb-0">
										<img src="../assets/images/collab1.png" alt="#">
										<form method="post">
											<div class="form-group">
												<textarea class="form-control"
													placeholder="Leave a comment"></textarea>
												<a href="#" class="btn btn-grey">Post</a>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="white_box">
						<div class="row">
							<div class="col-6">
								<h5 class="mt-1">Collaborators</h5>
							</div>
							<div class="col-6 text-right">
								<a href="#" class="view_all_link">View All</a>
							</div>
						</div>
						<hr>
						<div class="collab_flex">
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab1.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab2.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab3.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab4.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab5.png" alt="#">
								</div>
							</div>
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab6.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab7.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab8.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab9.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab10.png" alt="#">
								</div>
							</div>
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab11.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab12.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab13.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab14.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab15.png" alt="#">
								</div>
							</div>
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab16.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab17.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab18.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab19.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab20.png" alt="#">
								</div>
							</div>
						</div>
					</div>
					<div class="white_box">
						<div class="row">
							<div class="col-6">
								<h5 class="mt-1">calendar</h5>
							</div>
							<div class="col-6 text-right">
								<span class="setting_icon"><img src="../assets/images/gear.svg"></span>
							</div>
						</div>
						<hr>
						<ul class="nav nav-tabs mt-1 mb-1" id="myTabCalendar" role="tablist">
							<li class="nav-item" role="presentation">
								<button class="nav-link active" id="day1-tab" data-bs-toggle="tab"
									data-bs-target="#day1" type="button" role="tab" aria-controls="day1"
									aria-selected="true">Day</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="month-tab" data-bs-toggle="tab" data-bs-target="#month"
									type="button" role="tab" aria-controls="month"
									aria-selected="false">Month</button>
							</li>
						</ul>
						<hr>
						<div class="tab-content" id="myTabCalendarContent">
							<div class="tab-pane fade p-0 show active" id="day1" role="tabpanel"
								aria-labelledby="day1-tab">
								<div class="day_wrap">
									<div class="row">
										<div class="col-8">
											<h6>October 25, 2021</h6>
										</div>
										<div class="col-4">
											<ul class="list-inline navigate">
												<li class="list-inline-item">
													<a href="#">
														<img src="../assets/images/arrow-left.svg" alt="#">
													</a>
												</li>
												<li class="list-inline-item">
													<a href="#">
														<img src="../assets/images/arrow-right.svg" alt="#">
													</a>
												</li>
											</ul>
										</div>
									</div>
									<a href="#" class="day_flex">Online forum series <span>All Day</span></a>
									<a href="#" class="day_flex">Better world festival <span>12:00pm</span></a>
									<a href="#" class="day_flex">Internation day for girls <span>Add Day</span></a>
									<a href="#" class="day_flex">Environment meetup <span>3:00pm</span></a>
									<a href="#" class="day_flex">Cleanliness drive <span>6:00pm</span></a>
									<a href="#" class="day_flex">Climate Collage <span>8:00pm</span></a>
									<div class="text-center">
										<a href="#" class="add_day" data-bs-toggle="modal"
											data-bs-target="#calendar_filter_popup">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<rect y="14" width="30" height="2" fill="#7B3E6D" />
												<rect x="16" width="30" height="2" transform="rotate(90 16 0)"
													fill="#7B3E6D" />
											</svg>
										</a>
									</div>
								</div>
							</div>
							<div class="tab-pane fade p-0" id="month" role="tabpanel" aria-labelledby="month-tab">
								<div class="calendar_flex">
									<div class="calendar_row">
										<div class="calendar_th">Mo</div>
										<div class="calendar_th">Tu</div>
										<div class="calendar_th">We</div>
										<div class="calendar_th">Th</div>
										<div class="calendar_th">Fr</div>
										<div class="calendar_th">Sa</div>
										<div class="calendar_th">Su</div>
									</div>
									<div class="calendar_row">
										<div class="calendar_td">
										</div>
										<div class="calendar_td">
										</div>
										<div class="calendar_td">
										</div>
										<div class="calendar_td">
											<div class="inner">
												1<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												2<span class="active_icon active"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												3<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												4<span class="active_icon"></span>
											</div>
										</div>
									</div>
									<div class="calendar_row">
										<div class="calendar_td">
											<div class="inner">
												5<span class="active_icon active"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												6<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												7<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td selected">
											<div class="inner">
												8<span class="active_icon active"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												9<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												10<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												11<span class="active_icon"></span>
											</div>
										</div>
									</div>
									<div class="calendar_row">
										<div class="calendar_td">
											<div class="inner">
												12<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												13<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												14<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												15<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												16<span class="active_icon active"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												17<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												18<span class="active_icon"></span>
											</div>
										</div>
									</div>
									<div class="calendar_row">
										<div class="calendar_td">
											<div class="inner">
												19<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												20<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												21<span class="active_icon active"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												22<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												23<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												24<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												25<span class="active_icon"></span>
											</div>
										</div>
									</div>
									<div class="calendar_row">
										<div class="calendar_td">
											<div class="inner">
												26<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												27<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												28<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												29<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												30<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
											<div class="inner">
												31<span class="active_icon"></span>
											</div>
										</div>
										<div class="calendar_td">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="white_box pb-0">
						<h5>to do's</h5>
						<hr>
						<div class="manage_table">
							<table class="table">
								<tr>
									<td width="25%">
										<label class="label green">05/31
											<input type="checkbox" checked="checked">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Email Lauren
									</td>
									<td width="5%" class="text-right">
										<span class="cancel"><img src="../assets/images/cross.svg" alt="#"></span>
									</td>
								</tr>
								<tr>
									<td>
										<label class="label green">06/01
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Send team email reminder
									</td>
									<td class="text-right">
									</td>
								</tr>
								<tr>
									<td>
										<label class="label green">06/04
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Pick up supplies
									</td>
									<td class="text-right">
									</td>
								</tr>
								<tr>
									<td>
										<label class="label green">6/04
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Work on copy with Lauren
									</td>
									<td class="text-right">
									</td>
								</tr>
								<tr>
									<td>
										<label class="label green">06/07
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Remember to draft team memo
									</td>
									<td class="text-right">
									</td>
								</tr>
								<tr class="adder" data-bs-toggle="modal" data-bs-target="#to_do_popup">
									<td>
										<label class="label green">MM/DD
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Task Description..
									</td>
									<td class="text-right">
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="white_box pb-0">
						<h5>Attachments</h5>
						<hr>
						<div class="manage_table">
							<table class="table">
								<tr>
									<td>
										<div class="table_flex">
											<img src="../assets/images/zip.svg" alt="#"> TrailMap.pdf
										</div>
									</td>
									<td width="5%" class="text-right">
										<a href="#" class="download_link">Download</a>
									</td>
								</tr>
								<tr>
									<td>
										<div class="table_flex">
											<img src="../assets/images/zip.svg" alt="#"> VolunteerList.pdf
										</div>
									</td>
									<td width="5%" class="text-right">
										<a href="#" class="download_link">Download</a>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="tab-pane fade" id="management" role="tabpanel" aria-labelledby="management-tab">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="white_box white_box_manage">
						<div class="top_option">
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="button_flex">
										<a href="#" class="btn-purple-dark" data-bs-toggle="modal" data-bs-target="#new_task_popup">+ Add Task</a>
										<a href="#" class="btn-white" data-bs-toggle="modal" data-bs-target="#reporting_popup">Report measurable result</a>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="list_flex">
										<ul class="nav nav-tabs" id="myTabManagement" role="tablist">
											<li class="nav-item" role="presentation">
												<button class="nav-link active" id="day-tab" data-bs-toggle="tab"
													data-bs-target="#day" type="button" role="tab"
													aria-controls="day" aria-selected="true">
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0 11.0769H4.15385V6.92308H0V11.0769ZM0 4.15385H4.15385V0H0V4.15385ZM0 18H4.15385V13.8462H0V18ZM6.92308 18H11.0769V13.8462H6.92308V18ZM13.8462 11.0769H18V6.92308H13.8462V11.0769ZM6.92308 11.0769H11.0769V6.92308H6.92308V11.0769ZM13.8462 18H18V13.8462H13.8462V18ZM13.8462 0V4.15385H18V0H13.8462ZM6.92308 4.15385H11.0769V0H6.92308V4.15385Z"
															fill="white" />
													</svg>
												</button>
											</li>
											<li class="nav-item" role="presentation">
												<button class="nav-link" id="week-tab" data-bs-toggle="tab"
													data-bs-target="#week" type="button" role="tab"
													aria-controls="week" aria-selected="false">
													<svg width="24" height="14" viewBox="0 0 24 14" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0.75 8.25H3.25V5.75H0.75V8.25ZM0.75 13.25H3.25V10.75H0.75V13.25ZM0.75 3.25H3.25V0.75H0.75V3.25ZM5.75 8.25H23.25V5.75H5.75V8.25ZM5.75 13.25H23.25V10.75H5.75V13.25ZM5.75 0.75V3.25H23.25V0.75H5.75Z"
															fill="white" />
													</svg>
												</button>
											</li>
										</ul>
										<ul class="list-inline view_opt">
											<li class="list-inline-item">
												<a href="#" class="active">
													Day
												</a>
											</li>
											<li class="list-inline-item">
												<a href="#">
													Week
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-content" id="myTabManagementContent">
							<div class="tab-pane fade show active p-0" id="day" role="tabpanel"
								aria-labelledby="day-tab">
								<div class="gant_chart_wrap">
									<table class="table m-0">
										<thead>
											<tr>
												<th>Title</th>
												<th>Assigned Resources</th>
												<th>Team Lead</th>
												<th>Sun 5/31</th>
												<th>Mon 6/01</th>
												<th>Tue 6/02</th>
												<th>Wed 6/03</th>
												<th>Thu 6/04</th>
												<th>Fri 6/05</th>
												<th>Sat 6/06</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Trail Cleanup Week of 5/31</td>
												<td>Courtney Henry</td>
												<td>
													<div class="team_flex"><img src="../assets/images/collab12.png">
														Annette Black</div>
												</td>
												<td colspan="7">
													<div class="black_bar"></div>
												</td>
											</tr>
											<tr>
												<td>Complete research & mapping</td>
												<td>Savannah Nguyen</td>
												<td>
													<div class="team_flex"><img src="../assets/images/collab13.png">
														Robert Fox</div>
												</td>
												<td colspan="7">
												</td>
											</tr>
											<tr>
												<td>Supervising volunteers</td>
												<td>Kristin Watson</td>
												<td>
													<div class="team_flex"><img src="../assets/images/collab14.png">
														Jane Cooper</div>
												</td>
												<td colspan="7" class="pt-1 pb-1">
													<div class="gant_box bg-green" style="width: 65%">
														<img src="../assets/images/collab1.png" alt="#">
														<div class="text_box">
															Supervising<br><span>Peter Jones</span>
														</div>
														<div class="gant_scroll"></div>
													</div>
												</td>
											</tr>
											<tr>
												<td>Reporting & documentation</td>
												<td>Marvin McKinney</td>
												<td>
													<div class="team_flex"><img src="../assets/images/collab15.png">
														Leslie Alexander</div>
												</td>
												<td colspan="7" class="pt-1 pb-1">
													<div class="gant_box bg-gold"
														style="width: 35%;margin-left: 65%;">
														<img src="../assets/images/collab2.png" alt="#">
														<div class="text_box">
															Documenting<br><span>Peter Jones</span>
														</div>
														<div class="gant_scroll"></div>
													</div>
												</td>
											</tr>
											<tr>
												<td>Graphic design for flyers</td>
												<td>Esther Howard</td>
												<td>
													<div class="team_flex"><img src="../assets/images/collab16.png">
														Wade Warren</div>
												</td>
												<td colspan="7">
												</td>
											</tr>
											<tr>
												<td>Updates to website</td>
												<td>Cameron Williamson</td>
												<td>
													<div class="team_flex"><img src="../assets/images/collab17.png">
														Jerome Bell</div>
												</td>
												<td colspan="7">
												</td>
											</tr>
											<tr>
												<td>Volunteer recruitment</td>
												<td>Dianne Russell</td>
												<td>
													<div class="team_flex"><img src="../assets/images/collab18.png">
														Eleanor Pena</div>
												</td>
												<td colspan="7">
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="tab-pane fade p-0" id="week" role="tabpanel" aria-labelledby="week-tab">
								<div class="list_view">
									<table class="table">
										<thead>
											<tr>
												<th>name</th>
												<th width="30%">Due</th>
												<th width="5%"></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Lorem ipsum dolor sit amet</td>
												<td>10/14</td>
												<td class="text-right">
													<a href="#" class="btn btn-purple-dark">GO</a>
												</td>
											</tr>
											<tr>
												<td>Lorem ipsum dolor sit amet</td>
												<td>10/14</td>
												<td class="text-right">
													<a href="#" class="btn btn-purple-dark">GO</a>
												</td>
											</tr>
											<tr>
												<td>Lorem ipsum dolor sit amet</td>
												<td>10/14</td>
												<td class="text-right">
													<a href="#" class="btn btn-purple-dark">GO</a>
												</td>
											</tr>
											<tr>
												<td>Lorem ipsum dolor sit amet</td>
												<td>10/14</td>
												<td class="text-right">
													<a href="#" class="btn btn-purple-dark">GO</a>
												</td>
											</tr>
											<tr>
												<td>Lorem ipsum dolor sit amet</td>
												<td>10/14</td>
												<td class="text-right">
													<a href="#" class="btn btn-purple-dark">GO</a>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-8">
					<div class="white_box pb-0">
						<h5>to do's</h5>
						<div class="manage_table">
							<table class="table">
								<tr>
									<td width="15%">
										<label class="label green">05/31
											<input type="checkbox" checked="checked">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Email Lauren
									</td>
									<td width="5%" class="text-right">
										<span class="cancel"><img src="../assets/images/cross.svg" alt="#"></span>
									</td>
								</tr>
								<tr>
									<td>
										<label class="label green">06/01
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Send team email reminder
									</td>
									<td class="text-right">
									</td>
								</tr>
								<tr>
									<td>
										<label class="label green">06/04
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Pick up supplies
									</td>
									<td class="text-right">
									</td>
								</tr>
								<tr>
									<td>
										<label class="label green">6/04
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Work on copy with Lauren
									</td>
									<td class="text-right">
									</td>
								</tr>
								<tr>
									<td>
										<label class="label green">06/07
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Remember to draft team memo
									</td>
									<td class="text-right">
									</td>
								</tr>
								<tr class="adder">
									<td>
										<label class="label green">MM/DD
											<input type="checkbox">
											<span class="checkmark"></span>
										</label>
									</td>
									<td>
										Task Description..
									</td>
									<td class="text-right">
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="white_box pb-0">
						<h5>Attachments</h5>
						<div class="manage_table">
							<table class="table">
								<tr>
									<td>
										<div class="table_flex">
											<img src="../assets/images/zip.svg" alt="#"> TrailMap.pdf
										</div>
									</td>
									<td width="5%" class="text-right">
										<a href="#" class="download_link">Download</a>
									</td>
								</tr>
								<tr>
									<td>
										<div class="table_flex">
											<img src="../assets/images/zip.svg" alt="#"> VolunteerList.pdf
										</div>
									</td>
									<td width="5%" class="text-right">
										<a href="#" class="download_link">Download</a>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="tab-pane fade" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
		<div class="container">
			<div class="gutter_padding">
				<div class="row justify-content-between">
					<div class="col-12">
						<div class="impact_wrap">
							<h5>Impact</h5>
							<p>Let’s take a look at all of the impact we have made with this project so far.</p>
							<div class="row">
								<div class="col single-col">
									<div class="impact_flex impact_flex1">
										<span>
											<h3>10</h3>
											<h5>tons LITTER CLEANED<br><span>THIS MONTH</span></h5>
											<img src="../assets/images/garbage.svg" alt="#">
										</span>
									</div>
								</div>
								<div class="col single-col">
									<div class="impact_flex impact_flex2">
										<span>
											<h3>70</h3>
											<h5>TREES PLANTED<br><span>THIS MONTH</span></h5>
											<img src="../assets/images/tree.svg" alt="#">
										</span>
									</div>
								</div>
								<div class="col single-col">
									<div class="impact_flex impact_flex3">
										<span>
											<h3>30</h3>
											<h5>MILES MARKED<br><span>THIS MONTH</span></h5>
											<img src="../assets/images/signpost.svg" alt="#">
										</span>
									</div>
								</div>
								<div class="col single-col">
									<div class="impact_flex impact_flex4">
										<span>
											<h3>141</h3>
											<h5>VOLUNTEERS<br><span>THIS MONTH</span></h5>
											<img src="../assets/images/volunteer.svg" alt="#">
										</span>
									</div>
								</div>
								<div class="col single-col">
									<div class="impact_flex impact_flex5">
										<span>
											<h3>12</h3>
											<h5>tons carbon offset<br><span>TOTAL</span></h5>
											<img src="../assets/images/weight.svg" alt="#">
										</span>
									</div>
								</div>
								<div class="col-12">
									<a href="#" class="btn btn-white">Report measurable result</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<ul class="nav nav-tabs" id="myTab1" role="tablist">
				<li class="nav-item" role="presentation">
					<button class="nav-link active" id="funding-tab" data-bs-toggle="tab" data-bs-target="#funding"
						type="button" role="tab" aria-controls="funding" aria-selected="true">
						<h5>Funding</h5>
						<p>Thank you to those who have financially contributed!</p>
						<div class="graph_flex">
							<img src="../assets/images/graph1.png" alt="#">
							<div class="text_box">
								<span>
									<h2>76<span>%</span></h2>
									<h4>$56,395<span>/ $70,000</span></h4>
									<h5>Fund Raised</h5>
								</span>
							</div>
						</div>
						<hr>
						<div class="text-center">
							<a href="#" class="btn-purple-dark">View Detail</a>
						</div>
					</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link" id="hour-tab" data-bs-toggle="tab" data-bs-target="#hour" type="button"
						role="tab" aria-controls="hour" aria-selected="false">
						<h5>Hours Contributed</h5>
						<p>It truly takes a village.</p>
						<div class="graph_flex">
							<img src="../assets/images/graph2.png" alt="#">
							<div class="text_box">
								<span>
									<h2>537</h2>
									<h5>Hours Contributed</h5>
								</span>
							</div>
						</div>
						<hr>
						<div class="text-center">
							<a href="#" class="btn-purple-dark">View Detail</a>
						</div>
					</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link" id="collab-tab" data-bs-toggle="tab" data-bs-target="#collab"
						type="button" role="tab" aria-controls="collab" aria-selected="false">
						<h5>Collaborators</h5>
						<p>Take a further look at who’s contributed to this project.</p>
						<div class="collab_flex">
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab1.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab2.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab3.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab4.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab5.png" alt="#">
								</div>
							</div>
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab6.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab7.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab8.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab9.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab10.png" alt="#">
								</div>
							</div>
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab11.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab12.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab13.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab14.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab15.png" alt="#">
								</div>
							</div>
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab16.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab17.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab18.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab19.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab20.png" alt="#">
								</div>
							</div>
							<div class="collab_row">
								<div class="collab_col">
									<img src="../assets/images/collab1.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab2.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab3.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab4.png" alt="#">
								</div>
								<div class="collab_col">
									<img src="../assets/images/collab5.png" alt="#">
								</div>
							</div>
						</div>
						<hr>
						<div class="text-center">
							<a href="#" class="btn-purple-dark">View Detail</a>
						</div>
					</button>
				</li>
			</ul>
			<div class="tab-content" id="myTab1Content">
				<div class="tab-pane fade show active" id="funding" role="tabpanel" aria-labelledby="funding-tab">
					<div class="dashboard_data">
						<h5>Funding</h5>
						<hr>
						<div class="table-box">
							<table class="table">
								<thead>
									<tr>
										<th>Name</th>
										<th width="55%">Date</th>
										<th width="5%" class="text-right">Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab1.png"
													alt="#">Eleanor Pena</div>
										</td>
										<td>12/12/2021</td>
										<td class="text-right">$33,000</td>
									</tr>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab6.png"
													alt="#">Kristin Watson</div>
										</td>
										<td>12/12/2021</td>
										<td class="text-right">$20,000</td>
									</tr>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab11.png"
													alt="#">Savannah Nguyen</div>
										</td>
										<td>12/12/2021</td>
										<td class="text-right">$5,000</td>
									</tr>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab16.png"
													alt="#">Albert Flores</div>
										</td>
										<td>12/12/2021</td>
										<td class="text-right">$5,000</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="tab-pane fade" id="hour" role="tabpanel" aria-labelledby="hour-tab">
					<div class="dashboard_data">
						<h5>Detail</h5>
						<hr>
						<div class="table-box">
							<table class="table" style="min-width: 100%;">
								<thead>
									<tr>
										<th style="padding-left: 3.75rem;">Skill</th>
										<th class="text-right pe-0">Hours</th>
									</tr>
								</thead>
							</table>
							<div class="accordion" id="filter_inner">
								<div class="accordion-item type1">
									<h2 class="accordion-header" id="headingOne_inner">
										<button class="accordion-button collapsed" type="button"
											data-bs-toggle="collapse" data-bs-target="#collapseOne_inner"
											aria-expanded="true" aria-controls="collapseOne_inner">
											Environmental <span>263 Hours</span>
										</button>
									</h2>
									<div id="collapseOne_inner" class="accordion-collapse collapse"
										aria-labelledby="headingOne_inner" data-bs-parent="#filter_inner">
										<div class="accordion-body">
											<ul>
												<li>
													Darlene Robertson <span>200 Hours</span>
												</li>
												<li>
													Brooklyn Simmons <span>63 Hours</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item type2">
									<h2 class="accordion-header" id="headingTwo_inner">
										<button class="accordion-button collapsed" type="button"
											data-bs-toggle="collapse" data-bs-target="#collapseTwo_inner"
											aria-expanded="false" aria-controls="collapseTwo_inner">
											Communications <span>263 Hours</span>
										</button>
									</h2>
									<div id="collapseTwo_inner" class="accordion-collapse collapse"
										aria-labelledby="headingTwo_inner" data-bs-parent="#filter_inner">
										<div class="accordion-body">
											<ul>
												<li>
													Darlene Robertson <span>200 Hours</span>
												</li>
												<li>
													Brooklyn Simmons <span>63 Hours</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item type3">
									<h2 class="accordion-header" id="headingThree_inner">
										<button class="accordion-button collapsed" type="button"
											data-bs-toggle="collapse" data-bs-target="#collapseThree_inner"
											aria-expanded="false" aria-controls="collapseThree_inner">
											Data Analytics <span>263 Hours</span>
										</button>
									</h2>
									<div id="collapseThree_inner" class="accordion-collapse collapse"
										aria-labelledby="headingThree_inner" data-bs-parent="#filter_inner">
										<div class="accordion-body">
											<ul>
												<li>
													Darlene Robertson <span>200 Hours</span>
												</li>
												<li>
													Brooklyn Simmons <span>63 Hours</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item type4">
									<h2 class="accordion-header" id="headingFour_inner">
										<button class="accordion-button collapsed" type="button"
											data-bs-toggle="collapse" data-bs-target="#collapseFour_inner"
											aria-expanded="false" aria-controls="collapseFour_inner">
											Skilled Labor <span>263 Hours</span>
										</button>
									</h2>
									<div id="collapseFour_inner" class="accordion-collapse collapse"
										aria-labelledby="headingFour_inner" data-bs-parent="#filter_inner">
										<div class="accordion-body">
											<ul>
												<li>
													Darlene Robertson <span>200 Hours</span>
												</li>
												<li>
													Brooklyn Simmons <span>63 Hours</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item type4">
									<h2 class="accordion-header" id="headingFive_inner">
										<button class="accordion-button collapsed" type="button"
											data-bs-toggle="collapse" data-bs-target="#collapseFive_inner"
											aria-expanded="false" aria-controls="collapseFive_inner">
											Research <span>263 Hours</span>
										</button>
									</h2>
									<div id="collapseFive_inner" class="accordion-collapse collapse"
										aria-labelledby="headingFive_inner" data-bs-parent="#filter_inner">
										<div class="accordion-body">
											<ul>
												<li>
													Darlene Robertson <span>200 Hours</span>
												</li>
												<li>
													Brooklyn Simmons <span>63 Hours</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tab-pane fade" id="collab" role="tabpanel" aria-labelledby="collab-tab">
					<div class="dashboard_data">
						<h5>Detail</h5>
						<hr>
						<div class="table-box">
							<table class="table">
								<thead>
									<tr>
										<th>Name</th>
										<th width="55%">Hours</th>
										<th width="5%" class="text-right">Skill</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab1.png"
													alt="#">Eleanor Pena</div>
										</td>
										<td>150 hours</td>
										<td class="text-right">Skill</td>
									</tr>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab6.png"
													alt="#">Kristin Watson</div>
										</td>
										<td>150 hours</td>
										<td class="text-right">Skill</td>
									</tr>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab11.png"
													alt="#">Savannah Nguyen</div>
										</td>
										<td>150 hours</td>
										<td class="text-right">Skill</td>
									</tr>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab16.png"
													alt="#">Albert Flores</div>
										</td>
										<td>150 hours</td>
										<td class="text-right">Skill</td>
									</tr>
									<tr>
										<td>
											<div class="name_flex"><img src="../assets/images/collab1.png"
													alt="#">Bessie Cooper</div>
										</td>
										<td>150 hours</td>
										<td class="text-right">Skill</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="tab-pane fade" id="discussion" role="tabpanel" aria-labelledby="discussion-tab">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-3">
					<div class="filter_head">
						<div class="row">
							<div class="col-7">
								<h5>Discussion</h5>
							</div>
							<div class="col-5 text-right">
								<a href="#" class="btn btn-purple-dark" data-bs-toggle="modal"
									data-bs-target="#add_thread_popup">+ ADD NEW</a>
							</div>
						</div>
						<hr>
						<label class="label_text">Search Topic</label>
						<form method="post">
							<input type="text" name="" placeholder="Search" class="form-control">
							<button class="search-button">
								<svg width="27" height="27" viewBox="0 0 27 27" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849"
										rx="1.97924"
										transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)"
										fill="#272D35" stroke="#272D35" stroke-width="0.0972" />
									<ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594"
										transform="rotate(45 13.5 13.5)" stroke="#272D35" stroke-width="3" />
								</svg>
							</button>
						</form>
					</div>
					<div class="marketplace_filter_card">
						<div class="accordion" id="filter_main2">
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingOne2">
									<button class="accordion-button" type="button" data-bs-toggle="collapse"
										data-bs-target="#collapseOne2" aria-expanded="true"
										aria-controls="collapseOne2" style="border-top: 0">
										Filters
									</button>
								</h2>
								<div id="collapseOne2" class="accordion-collapse collapse show"
									aria-labelledby="headingOne2" data-bs-parent="#filter_main2">
									<div class="accordion-body">
										<div class="accordion" id="filter_inner2">
											<div class="accordion-item type0">
												<h2 class="accordion-header" id="headingOne22">
													<button class="accordion-button" type="button"
														data-bs-toggle="collapse" data-bs-target="#collapseOne22"
														aria-expanded="true" aria-controls="collapseOne22">
														Groups
													</button>
												</h2>
												<div id="collapseOne22" class="accordion-collapse collapse show"
													aria-labelledby="headingOne22" data-bs-parent="#filter_inner2">
													<div class="accordion-body">
														<ul>
															<li>
																<label class="label black">Community Forest Alliance
																	<input type="checkbox" checked="checked">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label black">Activist Arts Collective
																	Workshop
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label black">Lunch for Kids
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li class="selector">
																<span class="select_all">select all</span>
																<span class="deselect_all">deselect all</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div class="accordion-item type0">
												<h2 class="accordion-header" id="headingOne23">
													<button class="accordion-button" type="button"
														data-bs-toggle="collapse" data-bs-target="#collapseOne23"
														aria-expanded="true" aria-controls="collapseOne23">
														Projects
													</button>
												</h2>
												<div id="collapseOne23" class="accordion-collapse collapse show"
													aria-labelledby="headingOne23" data-bs-parent="#filter_inner2">
													<div class="accordion-body">
														<ul>
															<li>
																<label class="label black">Permaculture Workshop
																	<input type="checkbox" checked="checked">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label black">Urban Farm Initiative
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label black">Food Train Nashik
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li class="selector">
																<span class="select_all">select all</span>
																<span class="deselect_all">deselect all</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div class="accordion-item type1">
												<h2 class="accordion-header" id="headingOne_inner2">
													<button class="accordion-button collapsed" type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseOne_inner2" aria-expanded="true"
														aria-controls="collapseOne_inner2">
														Purpose
													</button>
												</h2>
												<div id="collapseOne_inner2" class="accordion-collapse collapse"
													aria-labelledby="headingOne_inner2"
													data-bs-parent="#filter_inner2">
													<div class="accordion-body">
														<ul>
															<li>
																<label class="label green">Aquatic Ecology
																	<input type="checkbox" checked="checked">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label green">Civil Rights
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label green">Clean Energy
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label green">Clean Water & Sanitation
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<span class="deselect_all">deselect all</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div class="accordion-item type2">
												<h2 class="accordion-header" id="headingTwo_inner2">
													<button class="accordion-button collapsed" type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseTwo_inner2" aria-expanded="false"
														aria-controls="collapseTwo_inner2">
														Skills
													</button>
												</h2>
												<div id="collapseTwo_inner2" class="accordion-collapse collapse"
													aria-labelledby="headingTwo_inner2"
													data-bs-parent="#filter_inner2">
													<div class="accordion-body">
														<ul>
															<li class="heading">ANIMAL WELFARE</li>
															<li>
																<label class="label red">Animal Care
																	<input type="checkbox" checked="checked">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label red">Aquatic Animal Care
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label red">Veterinary
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li class="heading">ARTS</li>
															<li>
																<label class="label red">Acting
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label red">Dance
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label red">Drawing
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<span class="deselect_all">deselect all</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div class="accordion-item type3">
												<h2 class="accordion-header" id="headingThree_inner2">
													<button class="accordion-button collapsed" type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseThree_inner2" aria-expanded="false"
														aria-controls="collapseThree_inner2">
														KNOWLEDGE
													</button>
												</h2>
												<div id="collapseThree_inner2" class="accordion-collapse collapse"
													aria-labelledby="headingThree_inner2"
													data-bs-parent="#filter_inner2">
													<div class="accordion-body">
														<ul>
															<li class="heading">BUSINESS</li>
															<li>
																<label class="label gold">Accounting
																	<input type="checkbox" checked="checked">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label gold">Business Ethics
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label gold">Entrepreneurship
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<span class="deselect_all">deselect all</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-9">
					<div class="card_search_result mt-0">
						<div class="post_box">
							<div class="posted_content">
								<div class="poster_wrap">
									<div class="row">
										<div class="col-10">
											<h3>Integer augue felis, lobortis ut eros in, iaculis malesuada lectus.
											</h3>
											<h5>Posted On 12/25/2021 in Santa Ana Trail Cleanup</h5>
										</div>
										<div class="col-2">
											<div class="post_opt">
												<div class="dropdown">
													<button class="dropdown-toggle" type="button"
														id="dropdownMenuButton1" data-bs-toggle="dropdown"
														aria-expanded="false">
														<img src="../assets/images/ellipse.svg" alt="#">
													</button>
													<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
														<li><a class="dropdown-item" href="#">Pin Post</a></li>
														<li><a class="dropdown-item" href="#">Delete</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<p>Ut tincidunt libero dui, in mollis massa viverra in. Morbi et nulla ac lorem
									semper placerat eu sit amet velit. Mauris eget elit pharetra, euismod ipsum
									dignissim, convallis risus. Sed congue nibh sed accumsan volutpat. In velit
									eros, volutpat eu sagittis et, varius at ipsum. Nunc sollicitudin, lorem ut
									euismod dictum, erat quam rhoncus urna, facilisis pulvinar ante sapien ac
									metus.<br><br>

									Integer eleifend justo quam, et varius urna condimentum nec. Donec mattis
									aliquam elit et pulvinar. Etiam tincidunt purus et ante iaculis elementum. Fusce
									sed metus id nibh dignissim pulvinar. Donec eget dictum erat. Vivamus nec erat
									at lacus volutpat hendrerit eu a erat. Interdum et malesuada fames ac ante ipsum
									primis in faucibus.</p>
								<div class="poster_wrap mt-0 mb-0">
									<div class="row">
										<div class="col-12 col-md-5">
											<div class="poster_flex">
												<img src="../assets/images/collab1.png" alt="#">
												<div class="text_box">
													<div class="time_posted">Author</div>
													<div class="name">Darlene Robertson</div>
												</div>
											</div>
										</div>
										<div class="col-12 col-md-7">
											<ul class="list-inline badge_tag">
												<li class="list-inline-item">
													<span class="badge bg-green">
														Aquatic Ecology
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-red">
														Animal..
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-gold">
														Accounting
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-black">
														+1
													</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="post_discussion">
								<div class="comment_flex">
									<img src="../assets/images/collab1.png" alt="#">
									<form method="post">
										<div class="form-group">
											<textarea class="form-control" placeholder="Leave a comment"></textarea>
											<a href="#" class="btn btn-grey">Post</a>
										</div>
									</form>
								</div>
								<div class="commented_flex">
									<img src="../assets/images/collab2.png" alt="#">
									<div class="text_box">
										<div class="comment_box">
											<a href="#" class="name">Jenny Wilson</a>
											<p>Proin ornare nunc diam, ac posuere nunc pellentesque sit amet.</p>
										</div>
										<ul class="list-inline comment_info">
											<li class="list-inline-item"><a href="#">Reply</a></li>
											<li class="list-inline-item"><a href="#">1 day old</a></li>
											<li class="list-inline-item"><a href="#">Delete Comment</a></li>
										</ul>
									</div>
								</div>
								<div class="commented_flex">
									<img src="../assets/images/collab4.png" alt="#">
									<div class="text_box">
										<div class="comment_box">
											<a href="#" class="name">Kathryn Murphy</a>
											<p>Dooooooooope</p>
										</div>
										<ul class="list-inline comment_info">
											<li class="list-inline-item"><a href="#">Reply</a></li>
											<li class="list-inline-item"><a href="#">20m</a></li>
										</ul>
									</div>
								</div>
								<a href="#" class="view_comment_link">View all 30 comments</a>
							</div>
						</div>
						<div class="post_box">
							<div class="posted_content">
								<div class="poster_wrap">
									<div class="row">
										<div class="col-10">
											<h3>Curabitur et massa iaculis, pretium leo sit amet</h3>
											<h5>Posted On 12/25/2021 in Santa Ana Trail Cleanup</h5>
										</div>
										<div class="col-2">
											<div class="post_opt">
												<div class="dropdown">
													<button class="dropdown-toggle" type="button"
														id="dropdownMenuButton1" data-bs-toggle="dropdown"
														aria-expanded="false">
														<img src="../assets/images/ellipse.svg" alt="#">
													</button>
													<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
														<li><a class="dropdown-item" href="#">Pin Post</a></li>
														<li><a class="dropdown-item" href="#">Delete</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<p>Proin interdum sapien quis maximus iaculis. Cras vitae tortor laoreet, imperdiet
									nibh in, ullamcorper turpis. Maecenas turpis sem, fermentum ac orci non,
									hendrerit vulputate ipsum. Nulla pharetra imperdiet ipsum, sit amet mattis leo
									porta nec. Duis scelerisque sit amet nunc quis bibendum. Orci varius natoque
									penatibus et magnis dis parturient montes.</p>
								<div class="poster_wrap mt-0 mb-0">
									<div class="row">
										<div class="col-12 col-md-5">
											<div class="poster_flex">
												<img src="../assets/images/collab1.png" alt="#">
												<div class="text_box">
													<div class="time_posted">Author</div>
													<div class="name">Darlene Robertson</div>
												</div>
											</div>
										</div>
										<div class="col-12 col-md-7">
											<ul class="list-inline badge_tag">
												<li class="list-inline-item">
													<span class="badge bg-green">
														Aquatic Ecology
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-red">
														Animal..
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-gold">
														Accounting
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-black">
														+1
													</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="post_discussion">
								<div class="comment_flex mb-0">
									<img src="../assets/images/collab1.png" alt="#">
									<form method="post">
										<div class="form-group">
											<textarea class="form-control" placeholder="Leave a comment"></textarea>
											<a href="#" class="btn btn-grey">Post</a>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div class="post_box">
							<div class="posted_content">
								<div class="poster_wrap">
									<div class="row">
										<div class="col-10">
											<h3>Integer at libero non dolor varius mollis. Praesent in erat tellus.
											</h3>
											<h5>Posted On 12/25/2021 in Santa Ana Trail Cleanup</h5>
										</div>
										<div class="col-2">
											<div class="post_opt">
												<div class="dropdown">
													<button class="dropdown-toggle" type="button"
														id="dropdownMenuButton1" data-bs-toggle="dropdown"
														aria-expanded="false">
														<img src="../assets/images/ellipse.svg" alt="#">
													</button>
													<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
														<li><a class="dropdown-item" href="#">Pin Post</a></li>
														<li><a class="dropdown-item" href="#">Delete</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<p>Morbi quis sodales odio, eget lacinia ligula. Integer arcu magna, viverra ut erat
									ac, maximus vestibulum ante. Vestibulum fermentum est eget justo malesuada,
									vitae mollis odio convallis. Curabitur lacinia nibh risus, vitae convallis nisi
									elementum sit amet. Nunc ex justo, laoreet in nisi at, vehicula tempor nisi.
									Duis fringilla, justo euismod porttitor eleifend, elit nisi consectetur felis,
									vel tincidunt lectus risus eu orci.<br><br>

									Sed neque nisl, luctus in bibendum eget, viverra nec felis. Vestibulum porta
									tortor justo, quis cursus mauris maximus a. In nec ex orci. Aenean vulputate
									elementum risus in faucibus. Vivamus blandit, sapien non porta condimentum, orci
									augue pellentesque quam, vel faucibus ipsum sem vitae diam. Nullam ac ex et
									mauris porttitor dapibus nec sed ipsum. Orci varius natoque penatibus et magnis
									dis parturient montes, nascetur ridiculus mus. Vestibulum ac urna suscipit,
									pulvinar purus in, dapibus tellus.<br><br>

									Maecenas gravida est quis urna efficitur, vitae malesuada dolor facilisis. Sed
									neque ipsum, ornare eu vestibulum sed, condimentum eu augue. Maecenas eget sem
									eu lacus lobortis suscipit fringilla a massa. Fusce porta lacinia nibh,
									facilisis consequat ex aliquet at. Integer enim ex, luctus eu aliquet eget,
									sagittis nec justo. In molestie urna quis dolor dictum, quis dignissim mauris
									feugiat. Nullam ut enim quis mi tristique ultrices. Nulla vulputate dui orci,
									lacinia tristique orci rhoncus et. Etiam blandit luctus quam in pellentesque.
									Morbi volutpat, orci id porta pulvinar, sapien enim mattis eros, et facilisis
									arcu libero quis neque. Aliquam sit amet elit eu tortor malesuada laoreet.
									Nullam a justo nisl.<br><br>

									Cras consequat faucibus lacinia. Curabitur et massa iaculis, pretium leo sit
									amet, porttitor eros. Maecenas velit nisl, feugiat vitae dui nec, fermentum
									sagittis diam. Fusce finibus mattis bibendum. Etiam at bibendum nisl.</p>
								<div class="poster_wrap mt-0 mb-0">
									<div class="row">
										<div class="col-12 col-md-5">
											<div class="poster_flex">
												<img src="../assets/images/collab1.png" alt="#">
												<div class="text_box">
													<div class="time_posted">Author</div>
													<div class="name">Darlene Robertson</div>
												</div>
											</div>
										</div>
										<div class="col-12 col-md-7">
											<ul class="list-inline badge_tag">
												<li class="list-inline-item">
													<span class="badge bg-green">
														Aquatic Ecology
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-red">
														Animal..
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-gold">
														Accounting
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-black">
														+1
													</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="post_discussion">
								<div class="comment_flex mb-0">
									<img src="../assets/images/collab1.png" alt="#">
									<form method="post">
										<div class="form-group">
											<textarea class="form-control" placeholder="Leave a comment"></textarea>
											<a href="#" class="btn btn-grey">Post</a>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div class="post_box">
							<div class="posted_content">
								<div class="poster_wrap">
									<div class="row">
										<div class="col-10">
											<h3>Sed neque nisl, luctus in bibendum eget</h3>
											<h5>Posted On 12/25/2021 in Santa Ana Trail Cleanup</h5>
										</div>
										<div class="col-2">
											<div class="post_opt">
												<div class="dropdown">
													<button class="dropdown-toggle" type="button"
														id="dropdownMenuButton1" data-bs-toggle="dropdown"
														aria-expanded="false">
														<img src="../assets/images/ellipse.svg" alt="#">
													</button>
													<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
														<li><a class="dropdown-item" href="#">Pin Post</a></li>
														<li><a class="dropdown-item" href="#">Delete</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<p>Nullam ut enim quis mi tristique ultrices. Nulla vulputate dui orci, lacinia
									tristique orci rhoncus et. Etiam blandit luctus quam in pellentesque. Morbi
									volutpat, orci id porta pulvinar, sapien enim mattis eros, et facilisis arcu
									libero quis neque. Aliquam sit amet elit eu tortor malesuada laoreet. Nullam a
									justo nisl.</p>
								<div class="poster_wrap mt-0 mb-0">
									<div class="row">
										<div class="col-12 col-md-5">
											<div class="poster_flex">
												<img src="../assets/images/collab1.png" alt="#">
												<div class="text_box">
													<div class="time_posted">Author</div>
													<div class="name">Darlene Robertson</div>
												</div>
											</div>
										</div>
										<div class="col-12 col-md-7">
											<ul class="list-inline badge_tag">
												<li class="list-inline-item">
													<span class="badge bg-green">
														Aquatic Ecology
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-red">
														Animal..
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-gold">
														Accounting
													</span>
												</li>
												<li class="list-inline-item">
													<span class="badge bg-black">
														+1
													</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="post_discussion">
								<div class="comment_flex mb-0">
									<img src="../assets/images/collab1.png" alt="#">
									<form method="post">
										<div class="form-group">
											<textarea class="form-control" placeholder="Leave a comment"></textarea>
											<a href="#" class="btn btn-grey">Post</a>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="tab-pane fade" id="collaborators" role="tabpanel" aria-labelledby="collaborators-tab">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-3">
					<div class="marketplace_filter_card">
						<div class="accordion" id="filter_main2">
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingOne2">
									<button class="accordion-button" type="button" data-bs-toggle="collapse"
										data-bs-target="#collapseOne2" aria-expanded="true"
										aria-controls="collapseOne2" style="border-top: 0">
										Filters
									</button>
								</h2>
								<div id="collapseOne2" class="accordion-collapse collapse show"
									aria-labelledby="headingOne2" data-bs-parent="#filter_main2">
									<div class="accordion-body">
										<div class="accordion" id="filter_inner2">
											<div class="accordion-item type1">
												<h2 class="accordion-header" id="headingOne_inner2">
													<button class="accordion-button collapsed" type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseOne_inner2" aria-expanded="true"
														aria-controls="collapseOne_inner2">
														Purpose
													</button>
												</h2>
												<div id="collapseOne_inner2" class="accordion-collapse collapse"
													aria-labelledby="headingOne_inner2"
													data-bs-parent="#filter_inner2">
													<div class="accordion-body">
														<ul>
															<li>
																<label class="label green">Aquatic Ecology
																	<input type="checkbox" checked="checked">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label green">Civil Rights
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label green">Clean Energy
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label green">Clean Water & Sanitation
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<span class="deselect_all">deselect all</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div class="accordion-item type2">
												<h2 class="accordion-header" id="headingTwo_inner2">
													<button class="accordion-button collapsed" type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseTwo_inner2" aria-expanded="false"
														aria-controls="collapseTwo_inner2">
														Skills
													</button>
												</h2>
												<div id="collapseTwo_inner2" class="accordion-collapse collapse"
													aria-labelledby="headingTwo_inner2"
													data-bs-parent="#filter_inner2">
													<div class="accordion-body">
														<ul>
															<li class="heading">ANIMAL WELFARE</li>
															<li>
																<label class="label red">Animal Care
																	<input type="checkbox" checked="checked">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label red">Aquatic Animal Care
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label red">Veterinary
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li class="heading">ARTS</li>
															<li>
																<label class="label red">Acting
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label red">Dance
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label red">Drawing
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<span class="deselect_all">deselect all</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div class="accordion-item type3">
												<h2 class="accordion-header" id="headingThree_inner2">
													<button class="accordion-button collapsed" type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseThree_inner2" aria-expanded="false"
														aria-controls="collapseThree_inner2">
														KNOWLEDGE
													</button>
												</h2>
												<div id="collapseThree_inner2" class="accordion-collapse collapse"
													aria-labelledby="headingThree_inner2"
													data-bs-parent="#filter_inner2">
													<div class="accordion-body">
														<ul>
															<li class="heading">BUSINESS</li>
															<li>
																<label class="label gold">Accounting
																	<input type="checkbox" checked="checked">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label gold">Business Ethics
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<label class="label gold">Entrepreneurship
																	<input type="checkbox">
																	<span class="checkmark"></span>
																</label>
															</li>
															<li>
																<span class="deselect_all">deselect all</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-9">
					<div class="card_search_result mt-0">
						<div class="row">
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people1.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people2.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people3.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people4.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people5.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people6.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people7.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people8.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people9.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people10.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people11.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="card">
									<ul class="list-inline badge_tag">
										<li class="list-inline-item">
											<span class="badge bg-green">
												Aquatic..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-red">
												Animal..
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-gold">
												Accounting
											</span>
										</li>
										<li class="list-inline-item">
											<span class="badge bg-black">
												+1
											</span>
										</li>
									</ul>
									<div class="text-center">
										<div class="img_box">
											<img src="../assets/images/people12.png" alt="#">
											<div class="active_icon"></div>
										</div>
									</div>
									<div class="card-body">
										<div class="text-center">
											<h5 class="card-title">Courtney Henry</h5>
											<h6>Writer</h6>
										</div>
										<p class="card-text">Proin vulputate leo ac bibendum rutrum. Nulla facilisi.
											Vivamus a diam sem...</p>
										<div class="bottom_flex">
											<a href="#" class="btn btn-purple-dark">Contact</a>
											<a href="#" class="read_more_link">Read More</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="tab-pane fade show active" id="projects" role="tabpanel" aria-labelledby="projects-tab">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-3">
					<div class="marketplace_filter">
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item" role="presentation">
								<button class="nav-link active" id="project-tab" data-bs-toggle="tab"
									data-bs-target="#project" type="button" role="tab" aria-controls="project"
									aria-selected="true">Projects</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="group-tab" data-bs-toggle="tab" data-bs-target="#group"
									type="button" role="tab" aria-controls="group"
									aria-selected="false">Groups</button>
							</li>
						</ul>
						<a href="#" class="btn btn-white">+ Create New Group</a>
						<hr>
						<h5>Location</h5>
						<form method="post">
							<input type="text" name="" placeholder="Search a location" class="form-control">
							<button class="search-button">
								<svg width="27" height="27" viewBox="0 0 27 27" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849"
										rx="1.97924"
										transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)"
										fill="#272D35" stroke="#272D35" stroke-width="0.0972" />
									<ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594"
										transform="rotate(45 13.5 13.5)" stroke="#272D35" stroke-width="3" />
								</svg>
							</button>
						</form>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-12">
					<div class="tab-content" id="myTabContent">
						<div class="tab-pane fade show active p-0" id="project" role="tabpanel"
							aria-labelledby="project-tab">
							<div class="row">
								<div class="col-12 col-md-3">
									<div class="marketplace_filter_card">
										<div class="accordion" id="filter_main">
											<div class="accordion-item">
												<h2 class="accordion-header" id="headingOne">
													<button class="accordion-button" type="button"
														data-bs-toggle="collapse" data-bs-target="#collapseOne"
														aria-expanded="true" aria-controls="collapseOne">
														Filters
													</button>
												</h2>
												<div id="collapseOne" class="accordion-collapse collapse show"
													aria-labelledby="headingOne" data-bs-parent="#filter_main">
													<div class="accordion-body">
														<div class="accordion" id="filter_inner">
															<div class="accordion-item type1">
																<h2 class="accordion-header" id="headingOne_inner">
																	<button class="accordion-button collapsed"
																		type="button" data-bs-toggle="collapse"
																		data-bs-target="#collapseOne_inner"
																		aria-expanded="true"
																		aria-controls="collapseOne_inner">
																		Purpose
																	</button>
																</h2>
																<div id="collapseOne_inner"
																	class="accordion-collapse collapse"
																	aria-labelledby="headingOne_inner"
																	data-bs-parent="#filter_inner">
																	<div class="accordion-body">
																		<ul>
																			<li>
																				<label class="label green">Aquatic
																					Ecology
																					<input type="checkbox"
																						checked="checked">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label green">Civil
																					Rights
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label green">Clean
																					Energy
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label green">Clean
																					Water & Sanitation
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<span class="deselect_all">deselect
																					all</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div class="accordion-item type2">
																<h2 class="accordion-header" id="headingTwo_inner">
																	<button class="accordion-button collapsed"
																		type="button" data-bs-toggle="collapse"
																		data-bs-target="#collapseTwo_inner"
																		aria-expanded="false"
																		aria-controls="collapseTwo_inner">
																		Skills
																	</button>
																</h2>
																<div id="collapseTwo_inner"
																	class="accordion-collapse collapse"
																	aria-labelledby="headingTwo_inner"
																	data-bs-parent="#filter_inner">
																	<div class="accordion-body">
																		<ul>
																			<li class="heading">ANIMAL WELFARE</li>
																			<li>
																				<label class="label red">Animal Care
																					<input type="checkbox"
																						checked="checked">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label red">Aquatic
																					Animal Care
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label red">Veterinary
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li class="heading">ARTS</li>
																			<li>
																				<label class="label red">Acting
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label red">Dance
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label red">Drawing
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<span class="deselect_all">deselect
																					all</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div class="accordion-item type3">
																<h2 class="accordion-header"
																	id="headingThree_inner">
																	<button class="accordion-button collapsed"
																		type="button" data-bs-toggle="collapse"
																		data-bs-target="#collapseThree_inner"
																		aria-expanded="false"
																		aria-controls="collapseThree_inner">
																		Expertise
																	</button>
																</h2>
																<div id="collapseThree_inner"
																	class="accordion-collapse collapse"
																	aria-labelledby="headingThree_inner"
																	data-bs-parent="#filter_inner">
																	<div class="accordion-body">
																		<ul>
																			<li class="heading">BUSINESS</li>
																			<li>
																				<label class="label gold">Accounting
																					<input type="checkbox"
																						checked="checked">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label gold">Business
																					Ethics
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label
																					class="label gold">Entrepreneurship
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<span class="deselect_all">deselect
																					all</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div class="accordion-item type4">
																<h2 class="accordion-header" id="headingFour_inner">
																	<button class="accordion-button collapsed"
																		type="button" data-bs-toggle="collapse"
																		data-bs-target="#collapseFour_inner"
																		aria-expanded="false"
																		aria-controls="collapseFour_inner">
																		Funding Goal
																	</button>
																</h2>
																<div id="collapseFour_inner"
																	class="accordion-collapse collapse"
																	aria-labelledby="headingFour_inner"
																	data-bs-parent="#filter_inner">
																	<div class="accordion-body">
																		<ul>
																			<li class="form">
																				<input type="number" maxlength="4"
																					value="1000" class="from" />
																				<input type="number" maxlength="4"
																					value="500000" class="to" />
																				<input type="text"
																					class="js-range-slider"
																					name="my_range" value=""
																					data-skin="round"
																					data-type="double"
																					data-min="1000"
																					data-max="500000"
																					data-grid="false" />
																			</li>
																			<li>
																				<label class="label green2">under
																					10%
																					<input type="checkbox"
																						checked="checked">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label green2">Over 90%
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<span class="deselect_all">deselect
																					all</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-9">
									<div class="card_search_result card_search_result_m">
										<ul class="list-inline badge_tag">
											<li class="list-inline-item">
												<span class="badge bg-green">
													<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
															fill="white" />
													</svg>
													AQUATIC ECOLOGY <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-green">
													<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
															fill="white" />
													</svg>
													HUNGER & FOOD SYSTEMS <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-green">
													<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
															fill="white" />
													</svg>
													TERRESTRIAL ECOLOGY <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-red">
													<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
															fill="white" />
													</svg>
													ACCOUNTING <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-red">
													<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
															fill="white" />
													</svg>
													BOOKKEEPING <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-gold">
													<svg width="9" height="12" viewBox="0 0 9 12" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0.75043 11.91C0.43704 12.1354 0 11.9115 0 11.5254V2.05293C0 0.91913 0.91913 0 2.05293 0H6.78941C7.92326 0 8.84234 0.91913 8.84234 2.05293V11.5254C8.84234 11.9115 8.40535 12.1354 8.09191 11.91L4.42116 9.26898L0.75043 11.91Z"
															fill="white" />
													</svg>
													ACCOUNTING <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
										</ul>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																bookkeeping
															</span>
														</li>
													</ul>
													<img src="../assets/images/img1.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Santa Ana Trail Cleanup</h5>
														<h6>Santa Ana, Texas, USA</h6>
														<p class="card-text">Connecting women with mentorship &
															access to applicable techniques for daily...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$60,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+1
															</span>
														</li>
													</ul>
													<img src="../assets/images/img2.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Urban Farm Initiative</h5>
														<h6>Memphis, Tennessee, USA</h6>
														<p class="card-text">Weekly training with local leaders to
															explore techniques for community...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$13,654 Raised</span>
															<span class="progress_flex_span">$15,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-gold">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+1
															</span>
														</li>
													</ul>
													<img src="../assets/images/img3.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Food Train Nashik</h5>
														<h6>Nashik, Maharashtra, India</h6>
														<p class="card-text">Making healthy foods easily accessible
															by connecting local public transit wit...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$92,395 Raised</span>
															<span class="progress_flex_span">$100,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+4
															</span>
														</li>
													</ul>
													<img src="../assets/images/img4.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Beach Cleanup</h5>
														<h6>Redondo Beach, California, USA</h6>
														<p class="card-text">Volunteer cleanup of public beaches
															that line the coasts Come join...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$64,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																bookkeeping
															</span>
														</li>
													</ul>
													<img src="../assets/images/img5.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Arts & Culture Festival</h5>
														<h6>San Francisco, California, USA</h6>
														<p class="card-text">Connecting local businesses and
															creatives to share in a , food, and...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$9,845 Raised</span>
															<span class="progress_flex_span">$10,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																aquatic
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-gold">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+1
															</span>
														</li>
													</ul>
													<img src="../assets/images/img6.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Local Watershed Stewardship</h5>
														<h6>Chelan, Washington, USA </h6>
														<p class="card-text">A volunteer run project committed to
															wise stewardship of local, so th...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																bookkeeping
															</span>
														</li>
													</ul>
													<img src="../assets/images/img7.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Open Mic & Benefit Show</h5>
														<h6>Nashville, Tennessee, USA </h6>
														<p class="card-text">Local artists and musicians coming
															together to for the food bank...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																aquatic
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+5
															</span>
														</li>
													</ul>
													<img src="../assets/images/img8.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Activist Arts Collective Workshop
														</h5>
														<h6>Providence, Rhode Island, USA</h6>
														<p class="card-text">How can we best engage community
															members the arts to benefit...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$10,345 Raised</span>
															<span class="progress_flex_span">$12,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																bookkeeping
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-gold">
																ACCOUNTING
															</span>
														</li>
													</ul>
													<img src="../assets/images/img9.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">After School Music Workshop</h5>
														<h6>Cleveland, Ohio, USA</h6>
														<p class="card-text">Immersing local teens with access to
															safe programming empowering...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$41,000 Raised</span>
															<span class="progress_flex_span">$45,300 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
													</ul>
													<img src="../assets/images/img10.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Infrastructure for Local Farms</h5>
														<h6>Sa Pa, Lao Cai, Vietnam</h6>
														<p class="card-text">Help equip local farms with tools they
															need to grow for their communit...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-gold">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+1
															</span>
														</li>
													</ul>
													<img src="../assets/images/img11.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Lunch for Kids</h5>
														<h6>CDMX, Mexico </h6>
														<p class="card-text">Help connect children with healthy
															lunches the intersection of San Migue...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$140,000 Raised</span>
															<span class="progress_flex_span">$150,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																aquatic
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+5
															</span>
														</li>
													</ul>
													<img src="../assets/images/img12.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">March for Climate Justice</h5>
														<h6>Harlem, New York, USA</h6>
														<p class="card-text">Support local movement builders in
															bringing voice crisis that fac...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade p-0" id="group" role="tabpanel" aria-labelledby="group-tab">
							<div class="row">
								<div class="col-12 col-md-3">
									<div class="marketplace_filter_card">
										<div class="accordion" id="filter_main1">
											<div class="accordion-item">
												<h2 class="accordion-header" id="headingOne1">
													<button class="accordion-button" type="button"
														data-bs-toggle="collapse" data-bs-target="#collapseOne1"
														aria-expanded="true" aria-controls="collapseOne1">
														Filters
													</button>
												</h2>
												<div id="collapseOne1" class="accordion-collapse collapse show"
													aria-labelledby="headingOne1" data-bs-parent="#filter_main1">
													<div class="accordion-body">
														<div class="accordion" id="filter_inner1">
															<div class="accordion-item type1">
																<h2 class="accordion-header" id="headingOne_inner1">
																	<button class="accordion-button collapsed"
																		type="button" data-bs-toggle="collapse"
																		data-bs-target="#collapseOne_inner1"
																		aria-expanded="true"
																		aria-controls="collapseOne_inner1">
																		Purpose
																	</button>
																</h2>
																<div id="collapseOne_inner1"
																	class="accordion-collapse collapse"
																	aria-labelledby="headingOne_inner1"
																	data-bs-parent="#filter_inner1">
																	<div class="accordion-body">
																		<ul>
																			<li>
																				<label class="label green">Aquatic
																					Ecology
																					<input type="checkbox"
																						checked="checked">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label green">Civil
																					Rights
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label green">Clean
																					Energy
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label green">Clean
																					Water & Sanitation
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<span class="deselect_all">deselect
																					all</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div class="accordion-item type2">
																<h2 class="accordion-header" id="headingTwo_inner1">
																	<button class="accordion-button collapsed"
																		type="button" data-bs-toggle="collapse"
																		data-bs-target="#collapseTwo_inner1"
																		aria-expanded="false"
																		aria-controls="collapseTwo_inner1">
																		Skills
																	</button>
																</h2>
																<div id="collapseTwo_inner1"
																	class="accordion-collapse collapse"
																	aria-labelledby="headingTwo_inner1"
																	data-bs-parent="#filter_inner1">
																	<div class="accordion-body">
																		<ul>
																			<li class="heading">ANIMAL WELFARE</li>
																			<li>
																				<label class="label red">Animal Care
																					<input type="checkbox"
																						checked="checked">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label red">Aquatic
																					Animal Care
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label red">Veterinary
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li class="heading">ARTS</li>
																			<li>
																				<label class="label red">Acting
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label red">Dance
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label red">Drawing
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<span class="deselect_all">deselect
																					all</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div class="accordion-item type3">
																<h2 class="accordion-header"
																	id="headingThree_inner1">
																	<button class="accordion-button collapsed"
																		type="button" data-bs-toggle="collapse"
																		data-bs-target="#collapseThree_inner1"
																		aria-expanded="false"
																		aria-controls="collapseThree_inner1">
																		Expertise
																	</button>
																</h2>
																<div id="collapseThree_inner1"
																	class="accordion-collapse collapse"
																	aria-labelledby="headingThree_inner1"
																	data-bs-parent="#filter_inner1">
																	<div class="accordion-body">
																		<ul>
																			<li class="heading">BUSINESS</li>
																			<li>
																				<label class="label gold">Accounting
																					<input type="checkbox"
																						checked="checked">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label gold">Business
																					Ethics
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label
																					class="label gold">Entrepreneurship
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<span class="deselect_all">deselect
																					all</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div class="accordion-item type4">
																<h2 class="accordion-header"
																	id="headingFour_inner1">
																	<button class="accordion-button collapsed"
																		type="button" data-bs-toggle="collapse"
																		data-bs-target="#collapseFour_inner1"
																		aria-expanded="false"
																		aria-controls="collapseFour_inner1">
																		Funding Goal
																	</button>
																</h2>
																<div id="collapseFour_inner1"
																	class="accordion-collapse collapse"
																	aria-labelledby="headingFour_inner1"
																	data-bs-parent="#filter_inner1">
																	<div class="accordion-body">
																		<ul>
																			<li class="form">
																				<input type="number" maxlength="4"
																					value="1000" class="from" />
																				<input type="number" maxlength="4"
																					value="500000" class="to" />
																				<input type="text"
																					class="js-range-slider"
																					name="my_range" value=""
																					data-skin="round"
																					data-type="double"
																					data-min="1000"
																					data-max="500000"
																					data-grid="false" />
																			</li>
																			<li>
																				<label class="label green2">under
																					10%
																					<input type="checkbox"
																						checked="checked">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<label class="label green2">Over 90%
																					<input type="checkbox">
																					<span class="checkmark"></span>
																				</label>
																			</li>
																			<li>
																				<span class="deselect_all">deselect
																					all</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-9">
									<div class="card_search_result">
										<ul class="list-inline badge_tag">
											<li class="list-inline-item">
												<span class="badge bg-green">
													<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
															fill="white" />
													</svg>
													AQUATIC ECOLOGY <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-green">
													<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
															fill="white" />
													</svg>
													HUNGER & FOOD SYSTEMS <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-green">
													<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
															fill="white" />
													</svg>
													TERRESTRIAL ECOLOGY <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-red">
													<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
															fill="white" />
													</svg>
													ACCOUNTING <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-red">
													<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
															fill="white" />
													</svg>
													BOOKKEEPING <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
											<li class="list-inline-item">
												<span class="badge bg-gold">
													<svg width="9" height="12" viewBox="0 0 9 12" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0.75043 11.91C0.43704 12.1354 0 11.9115 0 11.5254V2.05293C0 0.91913 0.91913 0 2.05293 0H6.78941C7.92326 0 8.84234 0.91913 8.84234 2.05293V11.5254C8.84234 11.9115 8.40535 12.1354 8.09191 11.91L4.42116 9.26898L0.75043 11.91Z"
															fill="white" />
													</svg>
													ACCOUNTING <span class="close"><i
															class="fa fa-close"></i></span>
												</span>
											</li>
										</ul>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																bookkeeping
															</span>
														</li>
													</ul>
													<img src="../assets/images/img13.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Santa Ana Trail Cleanup</h5>
														<h6>Santa Ana, Texas, USA</h6>
														<p class="card-text">Connecting women with mentorship &
															access to applicable techniques for daily...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$60,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+1
															</span>
														</li>
													</ul>
													<img src="../assets/images/img14.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Urban Farm Initiative</h5>
														<h6>Memphis, Tennessee, USA</h6>
														<p class="card-text">Weekly training with local leaders to
															explore techniques for community...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$13,654 Raised</span>
															<span class="progress_flex_span">$15,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-gold">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+1
															</span>
														</li>
													</ul>
													<img src="../assets/images/img15.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Food Train Nashik</h5>
														<h6>Nashik, Maharashtra, India</h6>
														<p class="card-text">Making healthy foods easily accessible
															by connecting local public transit wit...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$92,395 Raised</span>
															<span class="progress_flex_span">$100,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+4
															</span>
														</li>
													</ul>
													<img src="../assets/images/img16.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Beach Cleanup</h5>
														<h6>Redondo Beach, California, USA</h6>
														<p class="card-text">Volunteer cleanup of public beaches
															that line the coasts Come join...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$64,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																bookkeeping
															</span>
														</li>
													</ul>
													<img src="../assets/images/img17.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Arts & Culture Festival</h5>
														<h6>San Francisco, California, USA</h6>
														<p class="card-text">Connecting local businesses and
															creatives to share in a , food, and...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$9,845 Raised</span>
															<span class="progress_flex_span">$10,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																aquatic
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-gold">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+1
															</span>
														</li>
													</ul>
													<img src="../assets/images/img18.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Local Watershed Stewardship</h5>
														<h6>Chelan, Washington, USA </h6>
														<p class="card-text">A volunteer run project committed to
															wise stewardship of local, so th...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																bookkeeping
															</span>
														</li>
													</ul>
													<img src="../assets/images/img19.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Open Mic & Benefit Show</h5>
														<h6>Nashville, Tennessee, USA </h6>
														<p class="card-text">Local artists and musicians coming
															together to for the food bank...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																aquatic
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+5
															</span>
														</li>
													</ul>
													<img src="../assets/images/img20.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Activist Arts Collective Workshop
														</h5>
														<h6>Providence, Rhode Island, USA</h6>
														<p class="card-text">How can we best engage community
															members the arts to benefit...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$10,345 Raised</span>
															<span class="progress_flex_span">$12,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																bookkeeping
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-gold">
																ACCOUNTING
															</span>
														</li>
													</ul>
													<img src="../assets/images/img21.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">After School Music Workshop</h5>
														<h6>Cleveland, Ohio, USA</h6>
														<p class="card-text">Immersing local teens with access to
															safe programming empowering...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$41,000 Raised</span>
															<span class="progress_flex_span">$45,300 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
													</ul>
													<img src="../assets/images/img22.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Infrastructure for Local Farms</h5>
														<h6>Sa Pa, Lao Cai, Vietnam</h6>
														<p class="card-text">Help equip local farms with tools they
															need to grow for their communit...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-red">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-gold">
																ACCOUNTING
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+1
															</span>
														</li>
													</ul>
													<img src="../assets/images/img23.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">Lunch for Kids</h5>
														<h6>CDMX, Mexico </h6>
														<p class="card-text">Help connect children with healthy
															lunches the intersection of San Migue...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$140,000 Raised</span>
															<span class="progress_flex_span">$150,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="card">
													<ul class="list-inline badge_tag">
														<li class="list-inline-item">
															<span class="badge bg-green">
																terrestrial
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																aquatic
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-green">
																hunger
															</span>
														</li>
														<li class="list-inline-item">
															<span class="badge bg-black">
																+5
															</span>
														</li>
													</ul>
													<img src="../assets/images/img24.png" class="card-img-top"
														alt="...">
													<div class="card-body">
														<h5 class="card-title">March for Climate Justice</h5>
														<h6>Harlem, New York, USA</h6>
														<p class="card-text">Support local movement builders in
															bringing voice crisis that fac...</p>
														<div class="progress_flex">
															<span class="progress_flex_span">$56,395 Raised</span>
															<span class="progress_flex_span">$70,000 Goal</span>
														</div>
														<div class="progress">
															<div class="progress-bar bg-green2" role="progressbar"
																style="width: 75%" aria-valuenow="75"
																aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="bottom_flex">
															<a href="#" class="btn btn-purple-dark">Contact</a>
															<a href="#" class="read_more_link">Read More</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</section>


<div class="modal fade" id="upload_photo_video" tabindex="-1" aria-labelledby="upload_photo_videoLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header">
	<h5 class="modal-title">Photo/Video</h5>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body">
	<ul class="nav nav-tabs" id="myTabContentUpload" role="tablist">
	<li class="nav-item" role="presentation">
		<button class="nav-link active" id="photo-tab" data-bs-toggle="tab" data-bs-target="#photo" type="button" role="tab" aria-controls="photo" aria-selected="true">Photo</button>
	</li>
	<li class="nav-item" role="presentation">
		<button class="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video" type="button" role="tab" aria-controls="video" aria-selected="false">Video</button>
	</li>
	</ul>
	<div class="tab-content" id="myTabContentUploadContent">
	<div class="tab-pane fade show active" id="photo" role="tabpanel" aria-labelledby="photo-tab">
		<div class="form-group">
			<label class="form-label">Select images</label>
			<div class="row">
				<div class="col-10">
					<input type="text" name="" class="form-control" placeholder="Or Drag and drop files here">
				</div>
				<div class="col-2 text-right">
					<a href="#" class="btn btn-purple-dark">Select</a>
				</div>
			</div>
		</div>
		<p>Select images to upload (jpg, jepg, png and gif). </p>
		<div class="form-group">
			<label class="form-label">Description</label>
			<textarea type="text" name="" class="form-control" placeholder="Description"></textarea>
		</div>
		<div class="form-group">
			<label class="form-label">Tags</label>
		</div>
		<div class="white_box">
			<ul class="list-inline badge_tag">
				<li class="list-inline-item">
					<span class="badge bg-green">
						<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
						</svg>
						AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
					</span>
				</li>
				<li class="list-inline-item">
					<span class="badge bg-green">
						<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
						</svg>
						HUNGER &amp; FOOD SYSTEMS <span class="close"><i class="fa fa-close"></i></span>
					</span>
				</li>
				<li class="list-inline-item">
					<span class="badge bg-green">
						<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
						</svg>
						TERRESTRIAL ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
					</span>
				</li>
			</ul>
		</div>
		<hr>
		<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Add</button>
	</div>
	<div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
		<div class="form-group">
			<label class="form-label">Video Link</label>
			<input type="text" name="" class="form-control" placeholder="YouTube Video URL">
		</div>
		<div class="form-group">
			<label class="form-label">Description</label>
			<textarea type="text" name="" class="form-control" placeholder="Description"></textarea>
		</div>
		<div class="form-group">
			<label class="form-label">Tags</label>
		</div>
		<div class="white_box">
			<ul class="list-inline badge_tag">
				<li class="list-inline-item">
					<span class="badge bg-green">
						<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
						</svg>
						AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
					</span>
				</li>
				<li class="list-inline-item">
					<span class="badge bg-green">
						<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
						</svg>
						HUNGER &amp; FOOD SYSTEMS <span class="close"><i class="fa fa-close"></i></span>
					</span>
				</li>
				<li class="list-inline-item">
					<span class="badge bg-green">
						<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
						</svg>
						TERRESTRIAL ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
					</span>
				</li>
			</ul>
		</div>
		<hr>
		<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Add</button>
	</div>
	</div>
</div>
</div>
</div>
</div>
<div class="modal fade" id="to_do_popup" tabindex="-1" aria-labelledby="to_do_popupLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header">
	<h5 class="modal-title">to do's</h5>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body">
	<div class="to_do_wrap">
		<div class="row">
			<div class="col-12 col-md-9">
				<div class="form-group">
					<label class="form-label">Title</label>
					<input type="text" name="" class="form-control" placeholder="">
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="form-group">
					<label class="form-label">End Date</label>
					<input type="text" name="" class="form-control" placeholder="DD/MM/YYYY">
				</div>
			</div>
		</div>
	</div>
	<div class="to_do_wrap">
		<div class="row">
			<div class="col-12 col-md-9">
				<div class="form-group">
					<label class="form-label">Title</label>
					<input type="text" name="" class="form-control" placeholder="">
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="form-group">
					<label class="form-label">End Date</label>
					<input type="text" name="" class="form-control" placeholder="DD/MM/YYYY">
				</div>
			</div>
		</div>
		<div class="rmv_goal">
			<span class="icon">
				<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"></path>
				</svg>
			</span>
		</div>
	</div>
	<div class="add_goal">
		<span class="icon">
			<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect y="14" width="30" height="2" fill="#3C7178"></rect>
				<rect x="16" width="30" height="2" transform="rotate(90 16 0)" fill="#3C7178"></rect>
			</svg>
		</span> Add another To Do
	</div>
	<hr>
	<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Save</button>
</div>
</div>
</div>
</div>
<div class="modal fade" id="privacy_popup" tabindex="-1" aria-labelledby="privacyLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header">
	<h5 class="modal-title">Privacy</h5>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body">
	<div class="form-group">
		<label class="form-label">Privacy</label>
		<select class="form-control">
			<option>Connections</option>
			<option>Connections</option>
			<option>Connections</option>
		</select>
	</div>
	<div class="form-group">
		<label class="form-label">Filter By</label>
		<div class="row">
			<div class="col-6 col-md-2">
				<label class="radio_check">All
				<input type="radio" name="t1" checked="checked">
				<span class="checkmark"></span>
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label class="radio_check">Group
				<input type="radio" name="t1" checked="checked">
				<span class="checkmark"></span>
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label class="radio_check">Individuals
				<input type="radio" name="t1" checked="checked">
				<span class="checkmark"></span>
				</label>
			</div>
		</div>
	</div>
	<div class="form-group">
		<label class="form-label">Groups</label>
		<select class="form-control">
			<option>All</option>
			<option>All</option>
			<option>All</option>
		</select>
	</div>
	<div class="form-group">
		<label class="form-label">Members</label>
		<select class="form-control">
			<option>Select Member</option>
			<option>Select Member</option>
			<option>Select Member</option>
		</select>
	</div>
	<div class="form-group">
		<label class="form-label">Rights</label>
		<div class="row">
			<div class="col-6 col-md-2">
				<label class="radio_check">View
				<input type="radio" name="t2" checked="checked">
				<span class="checkmark"></span>
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label class="radio_check">Allow
				<input type="radio" name="t2" checked="checked">
				<span class="checkmark"></span>
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label class="radio_check">Disallow
				<input type="radio" name="t2" checked="checked">
				<span class="checkmark"></span>
				</label>
			</div>
		</div>
	</div>
	<hr>
	<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Add</button>
</div>
</div>
</div>
</div>
<div class="modal fade" id="new_task_popup" tabindex="-1" aria-labelledby="new_taskLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header">
	<h5 class="modal-title">New Task</h5>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body">
	<div class="form-group">
		<label class="form-label">Name</label>
		<input type="text" name="" placeholder="Name" class="form-control">
	</div>
	<div class="form-group">
		<label class="form-label">Description</label>
		<textarea placeholder="Description" class="form-control"></textarea>
	</div>
	<div class="form-group">
		<label class="form-label">Assignee</label>
		<select class="form-control">
			<option>Bessie Cooper</option>
			<option>Bessie Cooper</option>
			<option>Bessie Cooper</option>
		</select>
	</div>
	<div class="form-group">
		<label class="form-label">Start Date / Time</label>
		<input type="text" name="" placeholder="DD/MM/YYYY" class="form-control">
	</div>
	<div class="form-group">
		<label class="form-label">End Date / Time</label>
		<input type="text" name="" placeholder="DD/MM/YYYY" class="form-control">
	</div>
	<div class="form-group">
		<label class="form-label">Hours Expected</label>
		<input type="text" name="" placeholder="Hours" class="form-control">
	</div>
	<div class="form-group">
		<label class="form-label">Skills Needed</label>
		<select class="form-control">
			<option>Item</option>
			<option>Item</option>
			<option>Item</option>
		</select>
	</div>
	<ul class="list-inline badge_tag mt-2">
		<li class="list-inline-item">
			<span class="badge bg-red">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
				</svg>
				ACCOUNTING <span class="close"><i class="fa fa-close"></i></span>
			</span>
		</li>
		<li class="list-inline-item">
			<span class="badge bg-red">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
				</svg>
				BOOKKEEPING <span class="close"><i class="fa fa-close"></i></span>
			</span>
		</li>
	</ul>
	<div class="form-group">
		<label class="form-label">Attachments</label>
		<div class="upload_wrap">
			<a href="#" class="btn btn-green">Upload</a>
			<div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename.pdf</div>
			<div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename2.pdf</div>
		</div>
	</div>
	<hr>
	<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Submit</button>
</div>
</div>
</div>
</div>
<div class="modal fade" id="calendar_filter_popup" tabindex="-1" aria-labelledby="calendar_filterLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header">
	<h5 class="modal-title">Calendar Filter</h5>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body">
	<div class="form-group">
		<label class="form-label">Group</label>
		<form method="post">
			<input type="text" name="" placeholder="Search Group" class="form-control">
			<button class="search-button">
				<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924" transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35" stroke-width="0.0972"></rect>
					<ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35" stroke-width="3"></ellipse>
				</svg>
			</button>
		</form>
	</div>
	<ul class="list-inline badge_tag mt-2">
		<li class="list-inline-item">
			<span class="badge bg-blue">
				Community Forest Alliance <span class="close"><i class="fa fa-close"></i></span>
			</span>
		</li>
	</ul>
	<hr>
	<div class="form-group">
		<label class="form-label mt-0">Project</label>
		<form method="post">
			<input type="text" name="" placeholder="Search Project" class="form-control">
			<button class="search-button">
				<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924" transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35" stroke-width="0.0972"></rect>
					<ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35" stroke-width="3"></ellipse>
				</svg>
			</button>
		</form>
	</div>
	<hr>
	<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">SAVE</button>
</div>
</div>
</div>
</div>
<div class="modal fade" id="add_thread_popup" tabindex="-1" aria-labelledby="add_threadLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header">
	<h5 class="modal-title">New Thread</h5>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body">
	<div class="form-group">
		<label class="form-label">Title</label>
		<input type="text" name="" placeholder="" class="form-control">
	</div>
	<div class="row">
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label class="form-label">Group</label>
				<select class="form-control">
					<option>Group</option>
					<option>Group</option>
					<option>Group</option>
				</select>
			</div>
		</div>
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label class="form-label">Active Day Range</label>
				<select class="form-control">
					<option>1 Month</option>
					<option>1 Month</option>
					<option>1 Month</option>
				</select>
			</div>
		</div>
	</div>
	<div class="form-group">
		<label class="form-label">Description</label>
		<img src="../assets/images/text-editor.png" alt="#" class="text-editor">
	</div>
	<div class="accordion" id="privacy_collapser">
	<div class="accordion-item">
		<h2 class="accordion-header" id="headingPrivacy">
		<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrivacy" aria-expanded="true" aria-controls="collapsePrivacy">
			Privacy
		</button>
		</h2>
		<div id="collapsePrivacy" class="accordion-collapse collapse show" aria-labelledby="headingPrivacy" data-bs-parent="#privacy_collapser">
		<div class="accordion-body">
			<div class="form-group">
				<label class="form-label">Privacy</label>
				<select class="form-control">
					<option>Connections</option>
					<option>Connections</option>
					<option>Connections</option>
				</select>
			</div>
			<div class="form-group">
				<label class="form-label">Filter By</label>
				<div class="row">
					<div class="col-6 col-md-2">
						<label class="radio_check">All
						<input type="radio" name="t1" checked="checked">
						<span class="checkmark"></span>
						</label>
					</div>
					<div class="col-6 col-md-2">
						<label class="radio_check">Group
						<input type="radio" name="t1" checked="checked">
						<span class="checkmark"></span>
						</label>
					</div>
					<div class="col-6 col-md-2">
						<label class="radio_check">Individuals
						<input type="radio" name="t1" checked="checked">
						<span class="checkmark"></span>
						</label>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label class="form-label">Groups</label>
				<select class="form-control">
					<option>All</option>
					<option>All</option>
					<option>All</option>
				</select>
			</div>
			<div class="form-group">
				<label class="form-label">Members</label>
				<select class="form-control">
					<option>Select Member</option>
					<option>Select Member</option>
					<option>Select Member</option>
				</select>
			</div>
			<div class="form-group">
				<label class="form-label">Rights</label>
				<div class="row">
					<div class="col-6 col-md-2">
						<label class="radio_check">View
						<input type="radio" name="t2" checked="checked">
						<span class="checkmark"></span>
						</label>
					</div>
					<div class="col-6 col-md-2">
						<label class="radio_check">Allow
						<input type="radio" name="t2" checked="checked">
						<span class="checkmark"></span>
						</label>
					</div>
					<div class="col-6 col-md-2">
						<label class="radio_check">Disallow
						<input type="radio" name="t2" checked="checked">
						<span class="checkmark"></span>
						</label>
					</div>
				</div>
			</div>
		</div>
		</div>
	</div>
	</div>
	<hr>
	<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">CREATE</button>
</div>
</div>
</div>
</div>
<div class="modal fade" id="donation_popup" tabindex="-1" aria-labelledby="donationLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header justify-content-center">
	<h5 class="modal-title">Make a Donation</h5>
	<button id="hideDonationModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body modal_steps">
	<div class="donation_step donation_step1 active">
		<div class="donation_wrap">
			<div class="row">
				<div class="col-12 col-md-4">
					<label class="radio_box">
					<input type="radio" name="radio1">
					<span class="checkmark">$20</span>
					</label>
				</div>
				<div class="col-12 col-md-4">
					<label class="radio_box">
					<input type="radio" name="radio1">
					<span class="checkmark">$50</span>
					</label>
				</div>
				<div class="col-12 col-md-4">
					<label class="radio_box">
					<input type="radio" name="radio1">
					<span class="checkmark">$100</span>
					</label>
				</div>
				<div class="col-12 col-md-4">
					<label class="radio_box">
					<input type="radio" name="radio1">
					<span class="checkmark">$250</span>
					</label>
				</div>
				<div class="col-12 col-md-4">
					<label class="radio_box">
					<input type="radio" name="radio1">
					<span class="checkmark">$1000</span>
					</label>
				</div>
				<div class="col-12 col-md-4">
					<label class="radio_box">
					<input type="radio" name="radio1">
					<span class="checkmark">Custom</span>
					</label>
				</div>
			</div>
			<div class="accordion" id="advanced_opt">
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingOne8">
				<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne8" aria-expanded="true" aria-controls="collapseOne8">
					Advanced Options
				</button>
				</h2>
				<div id="collapseOne8" class="accordion-collapse collapse show" aria-labelledby="headingOne8" data-bs-parent="#advanced_opt">
				<div class="accordion-body">
					<ul class="nav nav-tabs" id="myTabModal1" role="tablist">
					<li class="nav-item" role="presentation">
						<button class="nav-link active" id="purpose1-tab" data-bs-toggle="tab" data-bs-target="#purpose1" type="button" role="tab" aria-controls="purpose1" aria-selected="true">Purpose</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="place1-tab" data-bs-toggle="tab" data-bs-target="#place1" type="button" role="tab" aria-controls="place1" aria-selected="false">Place</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="funding1-tab" data-bs-toggle="tab" data-bs-target="#funding1" type="button" role="tab" aria-controls="funding1" aria-selected="false">Funding Goal</button>
					</li>
					</ul>
					<div class="tab-content" id="myTabModal1Content">
					<div class="tab-pane fade show active" id="purpose1" role="tabpanel" aria-labelledby="purpose1-tab">
						<div class="form-group">
							<select class="form-control">
								<option>Item</option>
								<option>Item</option>
								<option>Item</option>
							</select>
						</div>
						<ul class="list-inline badge_tag">
							<li class="list-inline-item">
								<span class="badge bg-green">
									<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
									</svg>
									AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
								</span>
							</li>
							<li class="list-inline-item">
								<span class="badge bg-green">
									<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
									</svg>
									HUNGER &amp; FOOD SYSTEMS <span class="close"><i class="fa fa-close"></i></span>
								</span>
							</li>
							<li class="list-inline-item">
								<span class="badge bg-green">
									<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z" fill="white"></path>
									</svg>
									TERRESTRIAL ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
								</span>
							</li>
						</ul>
					</div>
					<div class="tab-pane fade" id="place1" role="tabpanel" aria-labelledby="place1-tab">
						<form method="post">
							<input type="text" name="" placeholder="Search a location" class="form-control">
							<button class="search-button">
								<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924" transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35" stroke-width="0.0972"></rect>
									<ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35" stroke-width="3"></ellipse>
								</svg>
							</button>
						</form>
						<ul class="list-inline badge_tag mt-2">
							<li class="list-inline-item">
								<span class="badge bg-blue">
									AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
								</span>
							</li>
						</ul>
					</div>
					<div class="tab-pane fade" id="funding1" role="tabpanel" aria-labelledby="funding1-tab">
						<ul>
							<li class="form">
								<input type="number" maxlength="4" value="1000" class="from" />
								<input type="number" maxlength="4" value="500000" class="to" />
								<input type="text" class="js-range-slider" name="my_range" value="" data-skin="round" data-type="double" data-min="1000" data-max="500000" data-grid="false" />
							</li>
							<li>
								<label class="label green2">under 10% 
								<input type="checkbox" checked="checked">
								<span class="checkmark"></span>
								</label>
							</li>
							<li>
								<label class="label green2">Over 90%
								<input type="checkbox">
								<span class="checkmark"></span>
								</label>
							</li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col-6">
				<a href="#" class="cancel_link" data-bs-dismiss="modal">Cancel</a>
			</div>
			<div class="col-6 text-right">
				<button type="button" class="btn btn-purple-dark steps-btn" indexClass="donation_step" indexno="1">Give</button>
			</div>
		</div>
	</div>
	<div class="donation_step donation_step2">
		<div class="donation_wrap">
			<div class="row">
				<div class="col-12">
					<div class="price_box">
						You are giving<br>
						<span class="price">$250</span>
					</div>
				</div>
			</div>
			<h6>Summary</h6>
			<div class="row">
				<div class="col-12 col-md-4">
					<div class="card">
					<ul class="list-inline badge_tag">
						<li class="list-inline-item">
							<span class="badge bg-green">
								hunger
							</span>
						</li>
					</ul>
					<img src="../assets/images/img2.png" class="card-img-top" alt="...">
					<div class="card-body">
						<h5 class="card-title">Open Mic & Benefit Show</h5>
						<p class="card-text">Local artists and musicians coming together to raise...</p>
						<hr>
						<h6>Allocation</h6>
						<div class="price">$100</div>
					</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="card">
					<ul class="list-inline badge_tag">
						<li class="list-inline-item">
							<span class="badge bg-green">
								hunger
							</span>
						</li>
					</ul>
					<img src="../assets/images/img3.png" class="card-img-top" alt="...">
					<div class="card-body">
						<h5 class="card-title">Lunch for Kids</h5>
						<p class="card-text">Local artists and musicians coming together to raise...</p>
						<hr>
						<h6>Allocation</h6>
						<div class="price">$75</div>
					</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="card">
					<ul class="list-inline badge_tag">
						<li class="list-inline-item">
							<span class="badge bg-green">
								hunger
							</span>
						</li>
					</ul>
					<img src="../assets/images/img4.png" class="card-img-top" alt="...">
					<div class="card-body">
						<h5 class="card-title">March for Climate Justice</h5>
						<p class="card-text">Local artists and musicians coming together to raise...</p>
						<hr>
						<h6>Allocation</h6>
						<div class="price">$75</div>
					</div>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col-6">
				<a href="#" class="cancel_link" data-bs-dismiss="modal">Cancel</a>
			</div>
			<div class="col-6 text-right">
				<span class="adjust_link">Adjust</span><button type="button" class="btn btn-purple-dark steps-btn" indexClass="donation_step" indexno="2">Confirm</button>
			</div>
		</div>
	</div>
	<div class="donation_step donation_step3">
		<div class="donation_wrap">
			<div class="row">
				<div class="col-12">
					<div class="price_box">
						You are giving<br>
						<span class="price">$250</span>
					</div>
				</div>
			</div>
			<h6>Summary</h6>
			<div class="row">
				<div class="col-12 col-md-4">
					<div class="card">
						<span class="cancel">
							<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
						</svg>
						</span>
					<ul class="list-inline badge_tag">
						<li class="list-inline-item">
							<span class="badge bg-green">
								hunger
							</span>
						</li>
					</ul>
					<img src="../assets/images/img2.png" class="card-img-top" alt="...">
					<div class="card-body">
						<h5 class="card-title">Open Mic & Benefit Show</h5>
						<p class="card-text">Local artists and musicians coming together to raise...</p>
						<hr>
						<h6>Allocation</h6>
						<div class="form-group">
							<input type="text" name="" value="100" class="form-control">
						</div>
					</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="card">
						<span class="cancel">
							<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
						</svg>
						</span>
					<ul class="list-inline badge_tag">
						<li class="list-inline-item">
							<span class="badge bg-green">
								hunger
							</span>
						</li>
					</ul>
					<img src="../assets/images/img3.png" class="card-img-top" alt="...">
					<div class="card-body">
						<h5 class="card-title">Lunch for Kids</h5>
						<p class="card-text">Local artists and musicians coming together to raise...</p>
						<hr>
						<h6>Allocation</h6>
						<div class="form-group">
							<input type="text" name="" value="75" class="form-control">
						</div>
					</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="card">
						<span class="cancel">
							<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
						</svg>
						</span>
					<ul class="list-inline badge_tag">
						<li class="list-inline-item">
							<span class="badge bg-green">
								hunger
							</span>
						</li>
					</ul>
					<img src="../assets/images/img4.png" class="card-img-top" alt="...">
					<div class="card-body">
						<h5 class="card-title">March for Climate Justice</h5>
						<p class="card-text">Local artists and musicians coming together to raise...</p>
						<hr>
						<h6>Allocation</h6>
						<div class="form-group">
							<input type="text" name="" value="75" class="form-control">
						</div>
					</div>
					</div>
				</div>
			</div>
			<h5 class="small_text">Related projects</h5>
			<form method="post" class="form_with_searchDrop">
				<input type="text" name="" placeholder="Search a location" class="form-control search_dropdown--btn">
				<button class="search-button">
					<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924" transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35" stroke-width="0.0972"></rect>
						<ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35" stroke-width="3"></ellipse>
					</svg>
				</button>
				<div class="search_dropdown">
					<h6>Recommended</h6>
					<div class="search_flex">
						<img src="../assets/images/img1.png" alt="#">
						<div class="text_box">
							<h5>Beach Cleanup</h5>
							<p>Volunteer cleanup of public beaches that line the coast of...</p>
							<ul class="list-inline badge_tag">
								<li class="list-inline-item">
									<span class="badge bg-green">
										terrestrial
									</span>
								</li>
							</ul>
							<a href="#" class="btn btn-purple-dark">ADD</a>
						</div>
					</div>
					<hr>
					<div class="search_flex">
						<img src="../assets/images/img2.png" alt="#">
						<div class="text_box">
							<h5>Arts & Culture Festival</h5>
							<p>Volunteer cleanup of public beaches that line the coast of...</p>
							<ul class="list-inline badge_tag">
								<li class="list-inline-item">
									<span class="badge bg-green">
										terrestrial
									</span>
								</li>
							</ul>
							<a href="#" class="btn btn-purple-dark">ADD</a>
						</div>
					</div>
					<hr>
					<div class="search_flex">
						<img src="../assets/images/img3.png" alt="#">
						<div class="text_box">
							<h5>Food Train Nashik</h5>
							<p>Volunteer cleanup of public beaches that line the coast of...</p>
							<ul class="list-inline badge_tag">
								<li class="list-inline-item">
									<span class="badge bg-green">
										terrestrial
									</span>
								</li>
							</ul>
							<a href="#" class="btn btn-purple-dark">ADD</a>
						</div>
					</div>
				</div>
			</form>
		</div>
		<hr>
		<div class="row">
			<div class="col-6">
				<a href="#" class="cancel_link" data-bs-dismiss="modal">Cancel</a>
			</div>
			<div class="col-6 text-right">
				<span class="adjust_link">Adjust</span>
				<button type="button" class="btn btn-purple-dark steps-btn" indexClass="donation_step" indexno="3"
				data-bs-toggle="modal" data-bs-target="#all_set_popup" (click)="hideModal('hideDonationModal')">Confirm</button>
			</div>
		</div>
	</div>
</div>
</div>
</div>
</div>
<div class="modal fade" id="checkout_popup" tabindex="-1" aria-labelledby="checkoutLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="checkout_form">
	<div class="modal-body">
		<div class="text-center">
			<h4>Checkout</h4>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
		</div>
		<hr>
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="form-group">
					<label class="form-label">First Name</label>
					<input type="text" name="" placeholder="First Name" class="form-control">
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="form-group">
					<label class="form-label">Last Name</label>
					<input type="text" name="" placeholder="Last Name" class="form-control">
				</div>
			</div>
		</div>
		<div class="form-group">
			<label class="form-label">Email</label>
			<input type="text" name="" placeholder="Email" class="form-control">
		</div>
		<div class="row">
			<div class="col-12 col-md-8">
				<div class="form-group">
					<label class="form-label">Mailing Address</label>
					<input type="text" name="" placeholder="Mailing Address" class="form-control">
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label class="form-label">City</label>
					<input type="text" name="" placeholder="City" class="form-control">
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label class="form-label">State</label>
					<input type="text" name="" placeholder="State" class="form-control">
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label class="form-label">Zip</label>
					<input type="text" name="" placeholder="Zip" class="form-control">
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label class="form-label">Country</label>
					<input type="text" name="" placeholder="Country" class="form-control">
				</div>
			</div>
		</div>
		<hr>
		<h5>Credit Card</h5>
		<div class="row">
			<div class="col-12 col-md-8">
				<div class="form-group">
					<label class="form-label">Card Number</label>
					<input type="text" name="" placeholder="Card Number" class="form-control">
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label class="form-label">Expiration date</label>
					<input type="text" name="" placeholder="DD/MM/YYYY" class="form-control">
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-8">
				<div class="form-group">
					<label class="form-label">Card Holder’s Name</label>
					<input type="text" name="" placeholder="Card Holder’s Name" class="form-control">
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label class="form-label">Security code</label>
					<input type="text" name="" placeholder="Security code" class="form-control">
				</div>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col-6">
				<h5>Billing address</h5>
			</div>
			<div class="col-6">
				<div class="check_box">
					<label class="label blue">Same as mailing address
					<input type="checkbox" checked="checked">
					<span class="checkmark"></span>
					</label>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="checkout_side">
	<div class="modal-header justify-content-center">
		<img src="../assets/images/checkout_img.png" alt="#" class="profile_overview_img">
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
			<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
			</svg>
		</button>
	</div>
	<div class="modal-body">
		<h4 class="summary_heading">Summary <a href="#">Edit Cart</a></h4>
		<div class="summary_content">
			<div class="summary_flex">
				Open Mic & Benefit Show<span>$100.00</span>
			</div>
			<div class="summary_flex">
				Lunch for Kids<span>$75.00</span>
			</div>
			<div class="summary_flex">
				March for Climate Justice<span>$75.00</span>
			</div>
			<div class="summary_flex">
				Beach Cleanup<span>$20.00</span>
			</div>
		</div>
		<h4 class="total_heading">
			Total <span class="total">$270</span>
		</h4>
	</div>
	<div class="modal-footer">
		<div class="gutter_padding">
			<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Donate</button>
		</div>
		<img src="../assets/images/purple_print_check.png" alt="#">
	</div>
</div>
</div>
</div>
</div>
<div class="modal fade" id="all_set_popup" tabindex="-1" aria-labelledby="all_setLabel" aria-hidden="true">
<div class="modal-dialog">
<div class="modal-content">
	<div class="modal-header justify-content-center">
		<button type="button" id="hideAllSetModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
			<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
			</svg>
		</button>
	</div>
	<div class="modal-body">
		<img src="../assets/images/modal_mask.png" alt="#" class="mask">
		<img src="../assets/images/all_set.svg" alt="#" class="ill">
		<h4>All Set!</h4>
		<p>Proin vulputate leo ac bibendum rutrum.</p>
		<a href="#" class="btn btn-purple-dark" (click)="hideModal('hideAllSetModal')"
		data-bs-toggle="modal" data-bs-target="#checkout_popup">Ok</a>
	</div>
</div>
</div>
</div>
<div class="modal fade" id="upload" tabindex="-1" aria-labelledby="upload_photo_videoLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">Attachment</h5>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
				<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
						fill="#7B3E6D" />
				</svg>
			</button>
		</div>
		<div class="modal-body">
			<ul class="nav nav-tabs" id="myTabContentUpload" role="tablist">
				<li class="nav-item" role="presentation">
					<button class="nav-link active" id="upload-new-tab" data-bs-toggle="tab"
						data-bs-target="#upload-new" type="button" role="tab" aria-controls="upload-new"
						aria-selected="true">Upload New</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link" id="existing-tab" data-bs-toggle="tab" data-bs-target="#existing"
						type="button" role="tab" aria-controls="existing" aria-selected="false">Existing From
						Library</button>
				</li>
			</ul>
			<div class="tab-content" id="myTabContentUploadContent">
				<div class="tab-pane fade show active" id="upload-new" role="tabpanel"
					aria-labelledby="upload-new-tab">
					<div class="form-group">
						<label class="form-label">Select files to upload</label>
						<div class="row">
							<div class="col-12 col-10">
								<input type="text" name="" class="form-control"
									placeholder="Or Drag and drop files here">
							</div>
							<div class="col-12 col-2 text-right">
								<a href="#" class="btn btn-purple-dark">Select</a>
							</div>
						</div>
					</div>
					<p>Select images to upload (jpg, jepg, png and gif). </p>
					<div class="form-group">
						<label class="form-label">Description</label>
						<textarea type="text" name="" class="form-control" placeholder="Description"></textarea>
					</div>
					<div class="form-group">
						<label class="form-label">Tags</label>
					</div>
					<div class="white_box">
						<ul class="list-inline badge_tag">
							<li class="list-inline-item">
								<span class="badge bg-green">
									<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
											fill="white"></path>
									</svg>
									AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
								</span>
							</li>
							<li class="list-inline-item">
								<span class="badge bg-green">
									<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
											fill="white"></path>
									</svg>
									HUNGER &amp; FOOD SYSTEMS <span class="close"><i class="fa fa-close"></i></span>
								</span>
							</li>
							<li class="list-inline-item">
								<span class="badge bg-green">
									<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
											fill="white"></path>
									</svg>
									TERRESTRIAL ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
								</span>
							</li>
						</ul>
					</div>
					<hr>
					<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Add</button>
				</div>
				<div class="tab-pane fade" id="existing" role="tabpanel" aria-labelledby="existing-tab">
					<div class="white_box white_box_manage">
						<div class="top_option">
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="button_flex">
										<a href="#" class="btn-white">NEW FOLDER</a>
										<a href="#" class="btn-white">UPLOAD</a>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="list_flex">
										<form method="post">
											<input type="text" name="" placeholder="" class="form-control">
											<button class="search-button">
												<svg width="27" height="27" viewBox="0 0 27 27" fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<rect x="1.91188e-07" y="0.0450582" width="8.81618"
														height="3.95849" rx="1.97924"
														transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)"
														fill="#272D35" stroke="#272D35" stroke-width="0.0972">
													</rect>
													<ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594"
														transform="rotate(45 13.5 13.5)" stroke="#272D35"
														stroke-width="3"></ellipse>
												</svg>
											</button>
										</form>
										<ul class="nav nav-tabs" id="myTabManagement" role="tablist">
											<li class="nav-item" role="presentation">
												<button class="nav-link active" id="gridd-tab" data-bs-toggle="tab"
													data-bs-target="#gridd" type="button" role="tab"
													aria-controls="gridd" aria-selected="true">
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0 11.0769H4.15385V6.92308H0V11.0769ZM0 4.15385H4.15385V0H0V4.15385ZM0 18H4.15385V13.8462H0V18ZM6.92308 18H11.0769V13.8462H6.92308V18ZM13.8462 11.0769H18V6.92308H13.8462V11.0769ZM6.92308 11.0769H11.0769V6.92308H6.92308V11.0769ZM13.8462 18H18V13.8462H13.8462V18ZM13.8462 0V4.15385H18V0H13.8462ZM6.92308 4.15385H11.0769V0H6.92308V4.15385Z"
															fill="white" />
													</svg>
												</button>
											</li>
											<li class="nav-item" role="presentation">
												<button class="nav-link" id="listt-tab" data-bs-toggle="tab"
													data-bs-target="#listt" type="button" role="tab"
													aria-controls="listt" aria-selected="false">
													<svg width="24" height="14" viewBox="0 0 24 14" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M0.75 8.25H3.25V5.75H0.75V8.25ZM0.75 13.25H3.25V10.75H0.75V13.25ZM0.75 3.25H3.25V0.75H0.75V3.25ZM5.75 8.25H23.25V5.75H5.75V8.25ZM5.75 13.25H23.25V10.75H5.75V13.25ZM5.75 0.75V3.25H23.25V0.75H5.75Z"
															fill="white" />
													</svg>
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-content" id="myTabManagementContent">
							<div class="tab-pane fade show active p-0" id="gridd" role="tabpanel"
								aria-labelledby="gridd-tab">
								<div class="row">
									<div class="col-12 col-md-4">
										<div class="side_opt">
											<a href="#" class="side_opt_link">Document</a>
											<a href="#" class="side_opt_link active">Picture</a>
										</div>
									</div>
									<div class="col-12 col-md-8">
										<div class="uploaded_wrap">
											<div class="row">
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box selected">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
											</div>
											<div class="row">
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade p-0" id="listt" role="tabpanel" aria-labelledby="listt-tab">
								<div class="row">
									<div class="col-12 col-md-4">
										<div class="side_opt">
											<a href="#" class="side_opt_link">Document</a>
											<a href="#" class="side_opt_link active">Picture</a>
										</div>
									</div>
									<div class="col-12 col-md-8">
										<div class="uploaded_wrap">
											<div class="row">
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box selected">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
											</div>
											<div class="row">
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
												<div class="col-6 col-md-3">
													<a href="#" class="upload_col">
														<div class="img_box">
															<img src="../assets/images/donate_img.png" alt="#">
															<span class="check"><i class="fa fa-check"></i></span>
														</div>
														<div class="file_name">File name</div>
														<div class="file_type">JPG</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr>
					<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Add</button>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
<div class="modal fade modal_large" id="new_task_review_popup" tabindex="-1" aria-labelledby="new_task_reviewLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="grey_side">
		<div class="modal-header">
		<h5 class="modal-title">Task</h5>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label class="form-label mt-0">Assignment Name</label>
			<div class="saved_value">Create a better world</div>
		</div>
		<div class="form-group">
			<label class="form-label">Assignment Description</label>
			<div class="saved_value">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ac imperdiet nibh. Curabitur sem erat, elementum ut lacus at, dapibus efficitur felis. Fusce pulvinar dui eu ante placerat.</div>
		</div>
		<div class="form-group">
			<label class="form-label">Assignee</label>
			<div class="saved_value">Bessie Cooper</div>
		</div>
		<div class="row m-0">
			<div class="col-12 col-md-6 p-0">
				<div class="form-group">
					<label class="form-label">Start Date / Time</label>
					<div class="saved_value">12/12/2021</div>
				</div>
			</div>
			<div class="col-12 col-md-6 p-0">
				<div class="form-group">
					<label class="form-label">End Date / Time</label>
					<div class="saved_value">12/12/2021</div>
				</div>
			</div>
		</div>
		<div class="form-group">
			<label class="form-label">Hours Expected</label>
			<div class="saved_value">15</div>
		</div>
		<div class="form-group">
			<label class="form-label">Skills Needed</label>
			<div class="saved_value">
				<ul class="list-inline badge_tag">
					<li class="list-inline-item">
						<span class="badge bg-red">
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
							</svg>
							ACCOUNTING <span class="close"><i class="fa fa-close"></i></span>
						</span>
					</li>
					<li class="list-inline-item">
						<span class="badge bg-red">
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
							</svg>
							BOOKKEEPING <span class="close"><i class="fa fa-close"></i></span>
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="form-group">
			<label class="form-label">Attachments</label>
			<div class="saved_value">
				<a href="#" class="download_link">Filename.pdf</a>
				<a href="#" class="download_link">Filename2.pdf</a>
			</div>
		</div>
	</div>
</div>
<div class="white_side">
		<div class="modal-header justify-content-center">
		<h5 class="modal-title text-center">Project Task Review</h5>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
			<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
			</svg>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label class="form-label mt-0">How would you rate your experience working on this project?</label>
			<ul class="list-inline rating">
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star-o"></i>
					</a>
				</li>
			</ul>
		</div>
		<div class="form-group">
			<label class="form-label">How would you assess your own contribution to this project?</label>
			<ul class="list-inline rating">
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star-o"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star-o"></i>
					</a>
				</li>
			</ul>
		</div>
		<div class="form-group">
			<label class="form-label">One other question as placeholder here</label>
			<ul class="list-inline rating">
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star-o"></i>
					</a>
				</li>
				<li class="list-inline-item">
					<a href="#">
						<i class="fa fa-star-o"></i>
					</a>
				</li>
			</ul>
		</div>
		<div class="form-group">
			<label class="form-label">Notes & Comments</label>
			<textarea placeholder="Comment.." class="form-control"></textarea>
		</div>
		<div class="form-group">
			<label class="form-label">Attachments</label>
			<div class="upload_wrap">
				<a href="#" class="btn btn-green">Upload</a>
				<div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename.pdf</div>
				<div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename2.pdf</div>
			</div>
		</div>
		<hr>
		<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Submit</button>
	</div>
</div>
</div>
</div>
</div>
<div class="modal fade" id="project_task_review_popup" tabindex="-1" aria-labelledby="project_task_reviewLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header">
	<h5 class="modal-title">Project Task Review</h5>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body">
	<div class="form-group form-group2">
		<label class="form-label mt-0">Assignment Name</label>
		<input type="text" name="" value="Create a better world" class="form-control">
	</div>
	<div class="form-group form-group2">
		<label class="form-label">Collaborator Name</label>
		<input type="text" name="" value="Albert Flores" class="form-control">
	</div>
	<div class="row">
		<div class="col-6">
			<div class="form-group form-group2">
				<label class="form-label">Completed Date / Time</label>
				<input type="text" name="" value="6/23/2021  14:35" class="form-control">
			</div>
		</div>
		<div class="col-6">
			<div class="form-group form-group2">
				<label class="form-label">Hours Spent</label>
				<input type="text" name="" value="20" class="form-control">
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="form-group form-group2">
				<label class="form-label">Completed Date / Time</label>
				<input type="text" name="" value="6/23/2021  14:35" class="form-control">
			</div>
		</div>
		<div class="col-6">
			<div class="form-group form-group2">
				<label class="form-label">Hours Spent</label>
				<input type="text" name="" value="20" class="form-control">
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="form-group form-group2">
				<label class="form-label">Completed Date / Time</label>
				<input type="text" name="" value="6/23/2021  14:35" class="form-control">
			</div>
		</div>
		<div class="col-6">
			<div class="form-group form-group2">
				<label class="form-label">Hours Spent</label>
				<input type="text" name="" value="20" class="form-control">
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-6">
			<div class="form-group form-group2">
				<label class="form-label">Completed Date / Time</label>
				<input type="text" name="" value="6/23/2021  14:35" class="form-control">
			</div>
		</div>
		<div class="col-6">
			<div class="form-group form-group2">
				<label class="form-label">Hours Spent</label>
				<input type="text" name="" value="20" class="form-control">
			</div>
		</div>
	</div>
	<div class="form-group">
		<label class="form-label">Skills Needed</label>
	</div>
	<ul class="list-inline badge_tag mt-2 mb-4">
		<li class="list-inline-item">
			<span class="badge bg-red">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
				</svg>
				ACCOUNTING <span class="close"><i class="fa fa-close"></i></span>
			</span>
		</li>
		<li class="list-inline-item">
			<span class="badge bg-red">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
				</svg>
				BOOKKEEPING <span class="close"><i class="fa fa-close"></i></span>
			</span>
		</li>
	</ul>
	<div class="form-group">
		<label class="form-label mt-0">How would you rate your experience working on this project?</label>
		<ul class="list-inline rating">
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star-o"></i>
				</a>
			</li>
		</ul>
	</div>
	<div class="form-group">
		<label class="form-label">How would you assess your own contribution to this project?</label>
		<ul class="list-inline rating">
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star-o"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star-o"></i>
				</a>
			</li>
		</ul>
	</div>
	<div class="form-group">
		<label class="form-label">One other question as placeholder here</label>
		<ul class="list-inline rating">
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star-o"></i>
				</a>
			</li>
			<li class="list-inline-item">
				<a href="#">
					<i class="fa fa-star-o"></i>
				</a>
			</li>
		</ul>
	</div>
	<div class="form-group form-group2">
		<label class="form-label">Notes & Comments</label>
		<textarea value="" class="form-control">I loved it</textarea>
	</div>
	<div class="form-group">
		<label class="form-label">Attachments</label>
		<div class="upload_wrap">
			<div class="uploaded_file">Filename.pdf <a href="#" class="download_link">Download</a></div>
			<div class="uploaded_file">Filename2.pdf <a href="#" class="download_link">Download</a></div>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-6">
			<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Submit</button>
		</div>
		<div class="col-6 text-right">
			<button type="button" class="btn btn-white" data-bs-dismiss="modal">Suggest Changes</button>
		</div>
	</div>
</div>
</div>
</div>
</div>
<div class="modal fade modal_large" id="new_form_popup" tabindex="-1" aria-labelledby="new_formLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="grey_side">
		<div class="modal-header">
		<h5 class="modal-title">Task</h5>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label class="form-label mt-0">Assignment Name</label>
			<div class="saved_value">Create a better world</div>
		</div>
		<div class="form-group">
			<label class="form-label">Assignment Description</label>
			<div class="saved_value">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ac imperdiet nibh. Curabitur sem erat, elementum ut lacus at, dapibus efficitur felis. Fusce pulvinar dui eu ante placerat.</div>
		</div>
		<div class="form-group">
			<label class="form-label">Assignee</label>
			<div class="saved_value">Bessie Cooper</div>
		</div>
		<div class="row m-0">
			<div class="col-12 col-md-6 p-0">
				<div class="form-group">
					<label class="form-label">Start Date / Time</label>
					<div class="saved_value">12/12/2021</div>
				</div>
			</div>
			<div class="col-12 col-md-6 p-0">
				<div class="form-group">
					<label class="form-label">End Date / Time</label>
					<div class="saved_value">12/12/2021</div>
				</div>
			</div>
		</div>
		<div class="form-group">
			<label class="form-label">Hours Expected</label>
			<div class="saved_value">15</div>
		</div>
		<div class="form-group">
			<label class="form-label">Skills Needed</label>
			<div class="saved_value">
				<ul class="list-inline badge_tag">
					<li class="list-inline-item">
						<span class="badge bg-red">
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
							</svg>
							ACCOUNTING <span class="close"><i class="fa fa-close"></i></span>
						</span>
					</li>
					<li class="list-inline-item">
						<span class="badge bg-red">
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
							</svg>
							BOOKKEEPING <span class="close"><i class="fa fa-close"></i></span>
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="form-group">
			<label class="form-label">Attachments</label>
			<div class="saved_value">
				<a href="#" class="download_link">Filename.pdf</a>
				<a href="#" class="download_link">Filename2.pdf</a>
			</div>
		</div>
	</div>
</div>
<div class="white_side">
		<div class="modal-header justify-content-center">
		<h5 class="modal-title text-center">Log Time</h5>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
			<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
			</svg>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-6">
				<div class="form-group">
					<label class="form-label mt-0">Completed Date / Time</label>
					<input type="text" name="" value="6/23/2021  14:35" class="form-control">
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label class="form-label mt-0">Hours Spent</label>
					<input type="text" name="" value="20" class="form-control">
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<div class="form-group">
					<label class="form-label">Completed Date / Time</label>
					<input type="text" name="" value="6/23/2021  14:35" class="form-control">
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label class="form-label">Hours Spent</label>
					<input type="text" name="" value="20" class="form-control">
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<div class="form-group">
					<label class="form-label">Completed Date / Time</label>
					<input type="text" name="" value="6/23/2021  14:35" class="form-control">
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label class="form-label">Hours Spent</label>
					<input type="text" name="" value="20" class="form-control">
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<div class="form-group">
					<label class="form-label">Completed Date / Time</label>
					<input type="text" name="" value="6/23/2021  14:35" class="form-control">
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label class="form-label">Hours Spent</label>
					<input type="text" name="" value="20" class="form-control">
				</div>
			</div>
		</div>
		<div class="add_goal">
			<span class="icon">
				<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect y="14" width="30" height="2" fill="#3C7178"></rect>
					<rect x="16" width="30" height="2" transform="rotate(90 16 0)" fill="#3C7178"></rect>
				</svg>
			</span> Add Time
		</div>
		<div class="log_time">
			Total Log time <span>20 hours</span>
		</div>
		<div class="form-group">
			<label class="form-label">Skill Used</label>
			<form method="post mb-3">
				<input type="text" name="" placeholder="Search" class="form-control">
				<button class="search-button">
					<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924" transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35" stroke-width="0.0972"></rect>
						<ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35" stroke-width="3"></ellipse>
					</svg>
				</button>
			</form>
			<div class="saved_value">
				<ul class="list-inline badge_tag">
					<li class="list-inline-item">
						<span class="badge bg-red">
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z" fill="white"/>
							</svg>
							ACCOUNTING <span class="close"><i class="fa fa-close"></i></span>
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="form-group">
			<label class="form-label">Comment</label>
			<textarea placeholder="Comment.." class="form-control"></textarea>
		</div>
		<div class="form-group">
			<label class="form-label">Attachments</label>
			<div class="upload_wrap">
				<a href="#" class="btn btn-green">Upload</a>
				<div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename.pdf</div>
				<div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename2.pdf</div>
			</div>
		</div>
		<hr>
		<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Submit</button>
	</div>
</div>
</div>
</div>
</div>
<div class="modal fade" id="reporting_popup" tabindex="-1" aria-labelledby="reportingLabel" aria-hidden="true">
<div class="modal-dialog modal-lg">
<div class="modal-content">
<div class="modal-header">
	<h5 class="modal-title">Reporting Measurable Results</h5>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z" fill="#7B3E6D"/>
		</svg>
	</button>
</div>
<div class="modal-body">
	<div class="form-group">
		<label class="form-label">Project Name</label>
		<select class="form-control">
			<option>Plant more tress</option>
			<option>Plant more tress</option>
			<option>Plant more tress</option>
		</select>
	</div>
	<div class="form-group">
		<label class="form-label">Reporter Name</label>
		<input type="text" name="" class="form-control" value="Albert Flores">
	</div>
	<div class="form-group">
		<label class="form-label">Date / Time</label>
		<input type="text" name="" class="form-control" placeholder="DD/MM/YYYY">
	</div>
	<div class="form-group">
		<label class="form-label">Measurable Result</label>
		<select class="form-control">
			<option>100 tress planted</option>
			<option>100 tress planted</option>
			<option>100 tress planted</option>
		</select>
	</div>
	<div class="row">
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label class="form-label">Number</label>
				<select class="form-control">
					<option>50</option>
					<option>50</option>
					<option>50</option>
				</select>
			</div>
		</div>
	</div>
	<div class="form-group">
		<label class="form-label">Description</label>
		<img src="../assets/images/text-editor.png" alt="#" class="text-editor">
	</div>
	<div class="form-group">
		<label class="form-label">Attachments</label>
		<div class="upload_wrap">
			<a href="#" class="btn btn-green">Upload</a>
			<div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename.pdf</div>
			<div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename2.pdf</div>
		</div>
	</div>
	<hr>
	<button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Submit</button>
</div>
</div>
</div>
</div>