<div class="modal fade" id="new_task_popup" tabindex="-1" aria-labelledby="new_taskLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">New Task</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="form-label">Name</label>
          <input type="text" name="" placeholder="Name" class="form-control">
        </div>
        <div class="form-group">
          <label class="form-label">Description</label>
          <textarea placeholder="Description" class="form-control"></textarea>
        </div>
        <div class="form-group">
          <label class="form-label">Assignee</label>
          <select class="form-control">
            <option>Bessie Cooper</option>
            <option>Bessie Cooper</option>
            <option>Bessie Cooper</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label">Start Date / Time</label>
          <input type="text" name="" placeholder="DD/MM/YYYY" class="form-control">
        </div>
        <div class="form-group">
          <label class="form-label">End Date / Time</label>
          <input type="text" name="" placeholder="DD/MM/YYYY" class="form-control">
        </div>
        <div class="form-group">
          <label class="form-label">Hours Expected</label>
          <input type="text" name="" placeholder="Hours" class="form-control">
        </div>
        <div class="form-group">
          <label class="form-label">Skills Needed</label>
          <select class="form-control">
            <option>Item</option>
            <option>Item</option>
            <option>Item</option>
          </select>
        </div>
        <ul class="list-inline badge_tag mt-2">
          <li class="list-inline-item">
            <span class="badge bg-red">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
                  fill="white" />
              </svg>
              ACCOUNTING <span class="close"><i class="fa fa-close"></i></span>
            </span>
          </li>
          <li class="list-inline-item">
            <span class="badge bg-red">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
                  fill="white" />
              </svg>
              BOOKKEEPING <span class="close"><i class="fa fa-close"></i></span>
            </span>
          </li>
        </ul>
        <div class="form-group">
          <label class="form-label">Attachments</label>
          <div class="upload_wrap">
            <a class="btn btn-green">Upload</a>
            <div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename.pdf</div>
            <div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename2.pdf</div>
          </div>
        </div>
        <hr>
        <button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>
