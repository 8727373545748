<div class="modal fade" id="all_set_popup" tabindex="-1" aria-labelledby="all_setLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <img src="../assets/images/modal_mask.png" alt="#" class="mask">
        <img src="../assets/images/all_set.svg" alt="#" class="ill">
        <h4>All Set!</h4>
        <p>Proin vulputate leo ac bibendum rutrum.</p>
        <a href="#" class="btn btn-purple-dark" data-bs-dismiss="modal">Ok</a>
      </div>
    </div>
  </div>
</div>
