<div class="modal fade" id="about_project_popup" tabindex="-1" aria-labelledby="project_cardLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <a class="arrow-left">
        <img src="../assets/images/slider_left.svg" alt="#">
      </a>
      <a class="arrow-right">
        <img src="../assets/images/slider_right.svg" alt="#">
      </a>
      <div class="modal-header p-0">
        <img src="../assets/images/project_modal_img.png" alt="#" class="lg_img">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="card_modal">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="img_box">
                <img src="../assets/images/people1.png" alt="#">
                <a class="btn-purple-dark">Contact</a>
              </div>
            </div>
            <div class="col-12 col-md-9">
              <div class="text_box">
                <h3>Santa Ana Trail Cleanup</h3>
                <h6>Ann Arbor, Michigan, USA | <a>Community Forest Alliance</a></h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tempus magna et semper tempus.
                  Aenean facilisis libero nunc.</p>
                <h5 class="grey_heading">Purpose</h5>
                <ul class="list-inline badge_tag">
                  <li class="list-inline-item">
                    <span class="badge bg-green">
                      <img src="../assets/images/heart.svg" alt="#"> terrestrial ecology
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr>
          <h5>About PROJECT</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec maximus ultrices vehicula. Phasellus mi
            lacus, imperdiet eu ex eget, rutrum sodales lacus. Cras commodo feugiat dolor, ac laoreet sem iaculis
            elementum. Nunc tempor ultrices consectetur. Curabitur ligula dui, sollicitudin a viverra id, egestas ut
            magna. Pellentesque arcu erat, feugiat sed risus eget, imperdiet ullamcorper urna. Nullam at arcu a ipsum
            malesuada dignissim non id magna. Proin ultrices, erat laoreet vehicula molestie, erat sapien pharetra quam,
            vel auctor lacus nisi at orci. Vestibulum ligula est, molestie ac ante a, convallis condimentum nisi. Nulla
            quis metus vitae leo porta iaculis.</p>
          <a class="view_link" href="/profile/project">View Project Profile</a>
          <hr>
          <h5>Progress</h5>
          <div class="progress_wrap">
            <div class="progress_text_lg">$56,395 <span>Raised</span>
              <div class="progress_text_grey">/ $70,000 Goal</div>
            </div>
            <div class="progress">
              <div class="progress-bar bg-green2" role="progressbar" style="width: 75%;height: 7px;" aria-valuenow="75"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <h5 class="sm_heading">Results</h5>
          <div class="row">
            <div class="col-12 col-md-3 br-grey">
              <div class="progress_wrap">
                <div class="progress_text_lg progress_text_lg_2">10<span class="grey">/100</span></div>
                <div class="progress_text_grey">Tons litter cleaned</div>
                <div class="progress">
                  <div class="progress-bar bg-green2" role="progressbar" style="width: 75%" aria-valuenow="75"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3 br-grey">
              <div class="progress_wrap">
                <div class="progress_text_lg progress_text_lg_2">70<span class="grey">/200</span></div>
                <div class="progress_text_grey">Trees planted</div>
                <div class="progress">
                  <div class="progress-bar bg-green2" role="progressbar" style="width: 75%" aria-valuenow="75"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3 br-grey">
              <div class="progress_wrap">
                <div class="progress_text_lg progress_text_lg_2">30<span class="grey">/200</span></div>
                <div class="progress_text_grey">Miles marked</div>
                <div class="progress">
                  <div class="progress-bar bg-green2" role="progressbar" style="width: 75%" aria-valuenow="75"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="progress_wrap">
                <div class="progress_text_lg progress_text_lg_2">141<span class="grey">/300</span></div>
                <div class="progress_text_grey">Volunteers</div>
                <div class="progress">
                  <div class="progress-bar bg-green2" role="progressbar" style="width: 75%" aria-valuenow="75"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <h5>Needs</h5>
          <h5 class="badge_heading">Skills</h5>
          <ul class="list-inline badge_tag">
            <li class="list-inline-item">
              <span class="badge bg-red">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
                    fill="white" />
                </svg>
                ADVERTISING
              </span>
            </li>
            <li class="list-inline-item">
              <span class="badge bg-red">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
                    fill="white" />
                </svg>
                SOIL ANALYSIS
              </span>
            </li>
            <li class="list-inline-item">
              <span class="badge bg-red">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.440995 10.8131C0.156915 11.0972 0 11.4752 0 11.9115C0 12.7416 0.674909 13.4165 1.50498 13.4165C1.9279 13.4165 2.33389 13.2369 2.61855 12.9242L7.88483 7.13117L6.00418 5.24994L0.440995 10.8131ZM13.9147 5.33511L12.748 4.16845C12.6932 4.11362 12.6191 4.08329 12.5415 4.08329H12.2499V3.79162C12.2499 3.71404 12.2189 3.63996 12.1647 3.58513L9.83139 1.25182C9.36356 0.783991 8.57957 0 6.99992 0C5.42144 0 4.69753 0.723909 4.16845 1.25182C4.08504 1.33523 4.05995 1.46065 4.10545 1.56973C4.15037 1.67881 4.25712 1.74998 4.37495 1.74998C6.72751 1.74998 6.99992 2.74864 6.99992 3.49996C6.99992 4.12412 6.64643 4.59953 6.42068 4.84161L8.32641 6.74734C8.84207 6.39851 9.57473 5.98668 9.91656 6.01702V6.41659C9.91656 6.49418 9.94747 6.56826 10.0017 6.62309L11.46 8.08141C11.5149 8.13566 11.589 8.16658 11.6665 8.16658H11.6765C11.7575 8.16424 11.8334 8.12808 11.8859 8.06741L13.9275 5.7341C14.029 5.61802 14.0232 5.44419 13.9147 5.33511Z"
                    fill="white" />
                </svg>
                ENVIRONMENTAL POLICY
              </span>
            </li>
          </ul>
          <h5 class="badge_heading">Knowledge</h5>
          <ul class="list-inline badge_tag">
            <li class="list-inline-item">
              <span class="badge bg-gold">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75043 11.91C0.43704 12.1354 0 11.9115 0 11.5254V2.05293C0 0.91913 0.91913 0 2.05293 0H6.78941C7.92326 0 8.84234 0.91913 8.84234 2.05293V11.5254C8.84234 11.9115 8.40535 12.1354 8.09191 11.91L4.42116 9.26898L0.75043 11.91Z"
                    fill="white" />
                </svg>
                EARTH SCIENCE <span class="close"><i class="fa fa-close"></i></span>
              </span>
            </li>
            <li class="list-inline-item">
              <span class="badge bg-gold">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75043 11.91C0.43704 12.1354 0 11.9115 0 11.5254V2.05293C0 0.91913 0.91913 0 2.05293 0H6.78941C7.92326 0 8.84234 0.91913 8.84234 2.05293V11.5254C8.84234 11.9115 8.40535 12.1354 8.09191 11.91L4.42116 9.26898L0.75043 11.91Z"
                    fill="white" />
                </svg>
                CLIMATE <span class="close"><i class="fa fa-close"></i></span>
              </span>
            </li>
          </ul>
          <hr>
          <div class="row">
            <div class="col-3">
              <a data-bs-dismiss="modal" class="cancel_link">Cancel</a>
            </div>
            <div class="col-9 text-right">
              <a class="btn btn-purple-dark border-0 me-2" style="background: #7b3e6d;">Join</a>
              <a href="/profile/project" class="btn btn-purple-dark">View Project Profile</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
