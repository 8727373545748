<div class="modal fade" id="reporting_popup" tabindex="-1" aria-labelledby="reportingLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reporting Measurable Results</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="form-label">Project Name</label>
          <select class="form-control">
            <option>Plant more tress</option>
            <option>Plant more tress</option>
            <option>Plant more tress</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label">Reporter Name</label>
          <input type="text" name="" class="form-control" value="Albert Flores">
        </div>
        <div class="form-group">
          <label class="form-label">Date / Time</label>
          <input type="text" name="" class="form-control" placeholder="DD/MM/YYYY">
        </div>
        <div class="form-group">
          <label class="form-label">Measurable Result</label>
          <select class="form-control">
            <option>100 tress planted</option>
            <option>100 tress planted</option>
            <option>100 tress planted</option>
          </select>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="form-label">Number</label>
              <select class="form-control">
                <option>50</option>
                <option>50</option>
                <option>50</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Description</label>
          <img src="../assets/images/text-editor.png" alt="#" class="text-editor">
        </div>
        <div class="form-group">
          <label class="form-label">Attachments</label>
          <div class="upload_wrap">
            <a class="btn btn-green">Upload</a>
            <div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename.pdf</div>
            <div class="uploaded_file"><img src="../assets/images/cross.svg" alt="#"> Filename2.pdf</div>
          </div>
        </div>
        <hr>
        <button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>
