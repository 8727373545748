import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyModalComponent } from './modals/privacy-modal/privacy-modal.component';
import { UploadPhotoVideoModalComponent } from './modals/upload-photo-video-modal/upload-photo-video-modal.component';
import { CalendarFilterModalComponent } from './modals/calendar-filter-modal/calendar-filter-modal.component';
import { NewTaskReviewModalComponent } from './modals/new-task-review-modal/new-task-review-modal.component';
import { NewFormPopupComponent } from './modals/new-form-popup/new-form-popup.component';
import { NewTaskPopupComponent } from './modals/new-task-popup/new-task-popup.component';
import { ReportingPopupComponent } from './modals/reporting-popup/reporting-popup.component';
import { ProjectTaskReviewPopupComponent } from './modals/project-task-review-popup/project-task-review-popup.component';
import { AddThreadPopupComponent } from './modals/add-thread-popup/add-thread-popup.component';
import { AboutProjectPopupComponent } from './modals/about-project-popup/about-project-popup.component';
import { DonationPopupComponent } from './modals/donation-popup/donation-popup.component';
import { CheckoutPopupComponent } from './modals/checkout-popup/checkout-popup.component';
import { AllSetPopupComponent } from './modals/all-set-popup/all-set-popup.component';
import { TodoPopupComponent } from './modals/todo-popup/todo-popup.component';
import { AboutGroupProfileComponent } from './modals/about-group-profile/about-group-profile.component';
import { AboutMemberProfileComponent } from './modals/about-member-profile/about-member-profile.component';
import { FiltersPanelComponent } from './components/filters-panel/filters-panel.component';

@NgModule({
  declarations: [
    PrivacyModalComponent,
    UploadPhotoVideoModalComponent,
    CalendarFilterModalComponent,
    NewTaskReviewModalComponent,
    NewFormPopupComponent,
    NewTaskPopupComponent,
    ReportingPopupComponent,
    ProjectTaskReviewPopupComponent,
    AddThreadPopupComponent,
    AboutProjectPopupComponent,
    DonationPopupComponent,
    CheckoutPopupComponent,
    AllSetPopupComponent,
    TodoPopupComponent,
    AboutGroupProfileComponent,
    AboutMemberProfileComponent,
    FiltersPanelComponent,
  ],
  imports: [CommonModule],
  exports: [
    PrivacyModalComponent,
    UploadPhotoVideoModalComponent,
    CalendarFilterModalComponent,
    NewTaskReviewModalComponent,
    NewFormPopupComponent,
    NewTaskPopupComponent,
    ReportingPopupComponent,
    ProjectTaskReviewPopupComponent,
    AddThreadPopupComponent,
    AboutProjectPopupComponent,
    DonationPopupComponent,
    CheckoutPopupComponent,
    AllSetPopupComponent,
    TodoPopupComponent,
    AboutGroupProfileComponent,
    AboutMemberProfileComponent,
    FiltersPanelComponent,
  ],
})
export class SharedModule {}
