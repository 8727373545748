interface IFetchHeaders {
  showToast?: boolean;
  hasLoading?: boolean;
  token?: boolean;
  auth?: any;
}

export const fetchApiHeaders = (args?: IFetchHeaders) => {
  const { hasLoading = true, showToast = true, token = true, auth } = args || {};

  const headers = {
    hasLoading,
    showToast,
    token,
  };

  if (auth) {
    return {
      headers,
      auth: {
        ...auth,
      },
    };
  }

  return {
    headers,
  };
};
