<section class="notification_section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-7">
        <div class="notification_wrap">
          <div class="top_heading">
            <h4>Notification <span>(6)</span></h4>
            <a href="#" class="clear_all">Mark All As Read</a>
          </div>
          <div class="bottom_notif">
            <div class="notify_flex alerted">
              <div class="img_box">
                <img src="../assets/images/drop_img7.png" alt="#" />
                <div class="alert_icon"><i class="fa fa-exclamation" aria-hidden="true"></i></div>
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex alerted">
              <div class="img_box">
                <img src="../assets/images/drop_img7.png" alt="#" />
                <div class="alert_icon"><i class="fa fa-exclamation" aria-hidden="true"></i></div>
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link">Frank Virtucio Ona sent you a friend</a>
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
                <div class="button_flex">
                  <a href="#" class="btn btn-green">Accept</a>
                  <a href="#" class="btn btn-red">DECLINE</a>
                </div>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notify_flex">
              <div class="img_box">
                <img src="../assets/images/collab1.png" alt="#" />
              </div>
              <div class="text_box">
                <a href="#" class="notifi_link"
                  >Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem non.</a
                >
                <ul class="list-inline">
                  <li class="list-inline-item">Art In Revolt</li>
                  <li class="list-inline-item">2 hours ago</li>
                  <li class="list-inline-item">
                    <a href="#">Dismiss</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
