import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
})
export class ChatBoxComponent implements OnInit {
  slickSliderLoaded: boolean = false;
  routerEvent: Subscription;

  constructor(private router: Router) {
    this.routerEvent = this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (document.location.pathname.includes('onboarding')) {
          this.slickSliderLoaded = false;
        } else {
          setTimeout(() => {
            this.intialiseChatBox();
          }, 500);
        }
      }
    });
  }

  ngOnInit(): void {}

  intialiseChatBox() {
    /*Chat_Box*/
    $('.chat_icon').click(function (event) {
      $('.chat_box').addClass('active');
      event.stopPropagation();
      return false;
    });

    $('.chat_box').click(function (event) {
      event.stopPropagation();
    });

    $('html').click(function () {
      $('#my-project-menu, #my-project').removeClass('active');
      $('#my-group-menu, #my-group').removeClass('active');
      $('.chat_box, .chat_box .person_chat, .chat_box .person_chat .message_typer .upload_flex').removeClass('active');
    });

    $('.chat_box .msg_flex').click(function () {
      $('.chat_box .person_chat').addClass('active');
    });

    $('.chat_box .person_chat .back_icon').click(function () {
      $('.chat_box .person_chat').removeClass('active');
      return false;
    });

    if (!this.slickSliderLoaded) {
      this.slickSliderLoaded = true;
      $('.chat_user_slider').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
      });
    }
  }

  ngOnDestroy() {
    this.routerEvent.unsubscribe();
  }
}
