import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-checkout-popup',
  templateUrl: './checkout-popup.component.html',
  styleUrls: ['./checkout-popup.component.scss'],
})
export class CheckoutPopupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  hideModal() {
    $(`#checkout_popup`).modal('hide');
  }
}
