<!-- private route header -->
<section class="nav_section" *ngIf="!isPublicRoute">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container">

      <div class="mobile-navbrand visible-xs">
        <a class="navbar-brand" routerLink="/profile"><img src="../assets/images/logo_icon.svg" alt="#"> Open Impact</a>
      </div>

      <a class="navbar-brand hidden-xs" routerLink="/profile"><img src="../assets/images/logo_icon.svg" alt="#"> Open
        Impact</a>

      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 visible-xs">
        <li class="nav-item">
          <a class="nav-link round_icon chat_icon" href="#"><img src="../assets/images/chat.svg" alt="#"></a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle round_icon bell_icon" href="#" id="navbarDropdown" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="../assets/images/bell.svg" alt="#">
          </a>
          <div class="dropdown-menu notificate" aria-labelledby="navbarDropdown">
            <div class="top_heading">
              <h4>Notification <span>(6)</span></h4> <a href="#" class="clear_all">Clear All</a>
            </div>
            <div class="bottom_notif">
              <div class="notify_flex">
                <div class="img_box">
                  <img src="../assets/images/collab1.png" alt="#">
                </div>
                <div class="text_box">
                  <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem
                    non.</a>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      Art In Revolt
                    </li>
                    <li class="list-inline-item">
                      2 hours ago
                    </li>
                    <li class="list-inline-item">
                      <a href="#">Dismiss</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="notify_flex">
                <div class="img_box">
                  <img src="../assets/images/collab1.png" alt="#">
                </div>
                <div class="text_box">
                  <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem
                    non.</a>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      Art In Revolt
                    </li>
                    <li class="list-inline-item">
                      2 hours ago
                    </li>
                    <li class="list-inline-item">
                      <a href="#">Dismiss</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="notify_flex">
                <div class="img_box">
                  <img src="../assets/images/collab1.png" alt="#">
                </div>
                <div class="text_box">
                  <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem
                    non.</a>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      Art In Revolt
                    </li>
                    <li class="list-inline-item">
                      2 hours ago
                    </li>
                    <li class="list-inline-item">
                      <a href="#">Dismiss</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="notify_flex">
                <div class="img_box">
                  <img src="../assets/images/collab1.png" alt="#">
                </div>
                <div class="text_box">
                  <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula lorem
                    non.</a>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      Art In Revolt
                    </li>
                    <li class="list-inline-item">
                      2 hours ago
                    </li>
                    <li class="list-inline-item">
                      <a href="#">Dismiss</a>
                    </li>
                  </ul>
                </div>
              </div>
              <a routerLink="/notifications" class="view_all">View All Notifications</a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img src="../assets/images/user.png" alt="#">
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="/profile?tab=discussion-tab">Discussions</a></li>
            <!-- <li><a class="dropdown-item" href="/profile?tab=dashboard-tab">Dashboard</a></li> -->
            <li><a class="dropdown-item" href="/profile?tab=overview-tab">Overview</a></li>
            <li><a class="dropdown-item" href="/profile?tab=feed-tab">Feed</a></li>
            <li><a class="dropdown-item" href="/profile?tab=projects-tab">My projects</a></li>
            <li><a class="dropdown-item" href="/profile?tab=management-tab">Management</a></li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li><a class="dropdown-item" href="/management/create-user-preferences?tab=2">Settings</a></li>
            <li><a class="dropdown-item" (click)="authService.logout()">Log out</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </li>
      </ul>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto">
          <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:" id="managementDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Management
            </a>
            <ul class="dropdown-menu" aria-labelledby="managementDropdown">
              <li>
                <a class="dropdown-item" routerLink="/management/create-group" routerLinkActive="active">Create
                  Group</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/management/create-project" routerLinkActive="active">Create
                  Project</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/management/create-user-preferences"
                  routerLinkActive="active">Create User Preferences</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/management/create-user" routerLinkActive="active">Create User</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/management/create-user-profile" routerLinkActive="active">Create
                  User Profile</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" id="my-project">My Projects</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" id="my-group">My Groups</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/marketplace/card-grid">Marketplace</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#donation_popup">Donate</a>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 hidden-xs">
          <li class="nav-item">
            <a class="nav-link round_icon chat_icon" href="javascript:">
              <img src="../assets/images/chat.svg" alt="#" />
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle round_icon bell_icon" href="#" id="navbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../assets/images/bell.svg" alt="#">
            </a>
            <div class="dropdown-menu notificate" aria-labelledby="navbarDropdown">
              <div class="top_heading">
                <h4>Notification <span>(6)</span></h4> <a href="#" class="clear_all">Clear All</a>
              </div>
              <div class="bottom_notif">
                <div class="notify_flex">
                  <div class="img_box">
                    <img src="../assets/images/collab1.png" alt="#">
                  </div>
                  <div class="text_box">
                    <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                      lorem non.</a>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        Art In Revolt
                      </li>
                      <li class="list-inline-item">
                        2 hours ago
                      </li>
                      <li class="list-inline-item">
                        <a href="#">Dismiss</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="notify_flex">
                  <div class="img_box">
                    <img src="../assets/images/collab1.png" alt="#">
                  </div>
                  <div class="text_box">
                    <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                      lorem non.</a>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        Art In Revolt
                      </li>
                      <li class="list-inline-item">
                        2 hours ago
                      </li>
                      <li class="list-inline-item">
                        <a href="#">Dismiss</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="notify_flex">
                  <div class="img_box">
                    <img src="../assets/images/collab1.png" alt="#">
                  </div>
                  <div class="text_box">
                    <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                      lorem non.</a>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        Art In Revolt
                      </li>
                      <li class="list-inline-item">
                        2 hours ago
                      </li>
                      <li class="list-inline-item">
                        <a href="#">Dismiss</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="notify_flex">
                  <div class="img_box">
                    <img src="../assets/images/collab1.png" alt="#">
                  </div>
                  <div class="text_box">
                    <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                      lorem non.</a>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        Art In Revolt
                      </li>
                      <li class="list-inline-item">
                        2 hours ago
                      </li>
                      <li class="list-inline-item">
                        <a href="#">Dismiss</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <a routerLink="/notifications" class="view_all">View All Notifications</a>
              </div>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Leslie Alexander <img src="../assets/images/user.png" alt="#">
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="/profile?tab=discussion-tab">Discussions</a></li>
              <!-- <li><a class="dropdown-item" href="/profile?tab=dashboard-tab">Dashboard</a></li> -->
              <li><a class="dropdown-item" href="/profile?tab=overview-tab">Overview</a></li>
              <li><a class="dropdown-item" href="/profile?tab=feed-tab">Feed</a></li>
              <li><a class="dropdown-item" href="/profile?tab=projects-tab">My projects</a></li>
              <li><a class="dropdown-item" href="/profile?tab=management-tab">Management</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="/management/create-user-preferences?tab=2">Settings</a></li>
              <li><a class="dropdown-item" (click)="authService.logout()">Log out</a></li>
            </ul>
          </li>
        </ul>
      </div>



      <ng-template [ngIf]="false">

        <!--  -->
        <div class="mobile-navbrand visible-xs">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <a routerLink="/profile" class="navbar-brand"><img src="../assets/images/logo_icon.svg" alt="#" /> Open
            Impact</a>
        </div>

        <a routerLink="/profile" class="navbar-brand hidden-xs"><img src="../assets/images/logo_icon.svg" alt="#" />
          Open Impact</a>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 visible-xs flex-dir">
          <li class="nav-item">
            <a class="nav-link round_icon chat_icon" href="#"><img src="../assets/images/chat.svg" alt="#" /></a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle round_icon bell_icon" href="#" id="navbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../assets/images/bell.svg" alt="#" />
            </a>
            <div class="dropdown-menu notificate" aria-labelledby="navbarDropdown">
              <div class="top_heading">
                <h4>Notification <span>(6)</span></h4>
                <a href="#" class="clear_all">Clear All</a>
              </div>
              <div class="bottom_notif">
                <div class="notify_flex">
                  <div class="img_box">
                    <img src="../assets/images/collab1.png" alt="#" />
                  </div>
                  <div class="text_box">
                    <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                      lorem non.</a>
                    <ul class="list-inline">
                      <li class="list-inline-item">Art In Revolt</li>
                      <li class="list-inline-item">2 hours ago</li>
                      <li class="list-inline-item">
                        <a href="#">Dismiss</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="notify_flex">
                  <div class="img_box">
                    <img src="../assets/images/collab1.png" alt="#" />
                  </div>
                  <div class="text_box">
                    <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                      lorem non.</a>
                    <ul class="list-inline">
                      <li class="list-inline-item">Art In Revolt</li>
                      <li class="list-inline-item">2 hours ago</li>
                      <li class="list-inline-item">
                        <a href="#">Dismiss</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="notify_flex">
                  <div class="img_box">
                    <img src="../assets/images/collab1.png" alt="#" />
                  </div>
                  <div class="text_box">
                    <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                      lorem non.</a>
                    <ul class="list-inline">
                      <li class="list-inline-item">Art In Revolt</li>
                      <li class="list-inline-item">2 hours ago</li>
                      <li class="list-inline-item">
                        <a href="#">Dismiss</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="notify_flex">
                  <div class="img_box">
                    <img src="../assets/images/collab1.png" alt="#" />
                  </div>
                  <div class="text_box">
                    <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                      lorem non.</a>
                    <ul class="list-inline">
                      <li class="list-inline-item">Art In Revolt</li>
                      <li class="list-inline-item">2 hours ago</li>
                      <li class="list-inline-item">
                        <a href="#">Dismiss</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <a href="#" class="view_all">View All Notifications</a>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="../assets/images/user.png" alt="#" />
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Discussions</a></li>
              <li><a class="dropdown-item" href="#">Dashboard</a></li>
              <li><a class="dropdown-item" href="#">Overview</a></li>
              <li><a class="dropdown-item" href="#">My projects</a></li>
              <li><a class="dropdown-item" href="#">Management</a></li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li><a class="dropdown-item" href="#">Settings</a></li>
              <li><a class="dropdown-item" (click)="authService.logout()">Log out</a></li>
            </ul>
          </li>
        </ul>

        <!-- <a class="navbar-brand" href="/"><img src="../assets/images/logo_icon.svg" alt="#" /> Open Impact</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav m-auto">
            <li class="nav-item dropdown">
              <a class="nav-link" href="javascript:" id="managementDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Management
              </a>
              <ul class="dropdown-menu" aria-labelledby="managementDropdown">
                <li (click)="toggleNavbar()">
                  <a class="dropdown-item" routerLink="/management/create-group" routerLinkActive="active">Create
                    Group</a>
                </li>
                <li (click)="toggleNavbar()">
                  <a class="dropdown-item" routerLink="/management/create-project" routerLinkActive="active">Create
                    Project</a>
                </li>
                <li (click)="toggleNavbar()">
                  <a class="dropdown-item" routerLink="/management/create-user-preferences"
                    routerLinkActive="active">Create User Preferences</a>
                </li>
                <li (click)="toggleNavbar()">
                  <a class="dropdown-item" routerLink="/management/create-user" routerLinkActive="active">Create
                    User</a>
                </li>
                <li (click)="toggleNavbar()">
                  <a class="dropdown-item" routerLink="/management/create-user-profile" routerLinkActive="active">Create
                    User Profile</a>
                </li>
              </ul>
            </li>
            <li class="nav-item" (click)="toggleNavbar()">
              <a class="nav-link active" href="javascript:void(0);" id="my-project">My Projects</a>
            </li>
            <li class="nav-item" (click)="toggleNavbar()">
              <a class="nav-link" href="javascript:void(0);" id="my-group">My Groups</a>
            </li>
            <li class="nav-item" (click)="toggleNavbar()">
              <a class="nav-link" routerLink="/marketplace/card-grid">Marketplace</a>
            </li>
            <li class="nav-item" (click)="toggleNavbar()">
              <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#donation_popup">Donate</a>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 hidden-xs">
            <li class="nav-item">
              <a class="nav-link round_icon chat_icon" href="javascript:">
                <img src="../assets/images/chat.svg" alt="#" />
              </a>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle round_icon bell_icon" href="#" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img src="../assets/images/bell.svg" alt="#" />
              </a>
              <div class="dropdown-menu notificate" aria-labelledby="navbarDropdown">
                <div class="top_heading">
                  <h4>Notification <span>(6)</span></h4>
                  <a href="#" class="clear_all">Clear All</a>
                </div>
                <div class="bottom_notif">
                  <div class="notify_flex alerted">
                    <div class="img_box">
                      <img src="../assets/images/drop_img7.png" alt="#" />
                      <div class="alert_icon"><i class="fa fa-exclamation" aria-hidden="true"></i></div>
                    </div>
                    <div class="text_box">
                      <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                        lorem non.</a>
                      <ul class="list-inline">
                        <li class="list-inline-item">Art In Revolt</li>
                        <li class="list-inline-item">2 hours ago</li>
                        <li class="list-inline-item">
                          <a href="#">Dismiss</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="notify_flex alerted">
                    <div class="img_box">
                      <img src="../assets/images/drop_img7.png" alt="#" />
                      <div class="alert_icon"><i class="fa fa-exclamation" aria-hidden="true"></i></div>
                    </div>
                    <div class="text_box">
                      <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                        lorem non.</a>
                      <ul class="list-inline">
                        <li class="list-inline-item">Art In Revolt</li>
                        <li class="list-inline-item">2 hours ago</li>
                        <li class="list-inline-item">
                          <a href="#">Dismiss</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="notify_flex">
                    <div class="img_box">
                      <img src="../assets/images/collab1.png" alt="#" />
                    </div>
                    <div class="text_box">
                      <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                        lorem non.</a>
                      <ul class="list-inline">
                        <li class="list-inline-item">Art In Revolt</li>
                        <li class="list-inline-item">2 hours ago</li>
                        <li class="list-inline-item">
                          <a href="#">Dismiss</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="notify_flex">
                    <div class="img_box">
                      <img src="../assets/images/collab1.png" alt="#" />
                    </div>
                    <div class="text_box">
                      <a href="#" class="notifi_link">Aliquam consequat dolor ex. Aenean nec justo tincidunt, vehicula
                        lorem non.</a>
                      <ul class="list-inline">
                        <li class="list-inline-item">Art In Revolt</li>
                        <li class="list-inline-item">2 hours ago</li>
                        <li class="list-inline-item">
                          <a href="#">Dismiss</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <a routerLink="/notifications" class="view_all">View All Notifications</a>
                </div>
              </div>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                Leslie Alexander
                <img src="../assets/images/user.png" alt="#" />
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a class="dropdown-item" href="javascript:">Discussions</a></li>
                <li><a class="dropdown-item" href="javascript:">Dashboard</a></li>
                <li><a class="dropdown-item" href="javascript:">Overview</a></li>
                <li><a class="dropdown-item" href="javascript:">My projects</a></li>
                <li><a class="dropdown-item" href="javascript:">Management</a></li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li><a class="dropdown-item" href="javascript:">Settings</a></li>
                <li><a class="dropdown-item" (click)="authService.logout()">Log out</a></li>
              </ul>
            </li>
          </ul>
        </div>


      </ng-template>

    </div>
  </nav>

  <div class="mega_dropdown" id="my-project-menu">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <form method="post">
            <app-autocomplete [placeholder]="'Search Project..'"></app-autocomplete>
            <!--            <input type="text" name="" placeholder="Search Project" class="form-control" />-->
            <button class="search-button">
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924"
                  transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35"
                  stroke-width="0.0972" />
                <ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35"
                  stroke-width="3" />
              </svg>
            </button>
          </form>
        </div>
        <div class="col-12 col-md-3">
          <ul class="drop_list">
            <li>
              <a routerLink="/profile/project"><img src="../assets/images/drop_img1.png" />Santa Ana Trail Cleanup</a>
            </li>
            <li>
              <a routerLink="/profile/project"><img src="../assets/images/drop_img2.png" />Urban Farm Initiative</a>
            </li>
            <li>
              <a routerLink="/profile/project"><img src="../assets/images/drop_img3.png" />Food Train Nashik</a>
            </li>
            <li>
              <a routerLink="/profile/project"><img src="../assets/images/drop_img4.png" />Gardens of Giving</a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-3">
          <ul class="drop_list">
            <li>
              <a routerLink="/profile/project"><img src="../assets/images/drop_img5.png" />Arts & Culture Festival</a>
            </li>
            <li>
              <a routerLink="/profile/project"><img src="../assets/images/drop_img6.png" />Urban Farm Initiative</a>
            </li>
            <li>
              <a routerLink="/profile/project">
                <img src="../assets/images/drop_img7.png" />
                Local Watershed Stewardship
              </a>
            </li>
            <li>
              <a routerLink="/profile/project"><img src="../assets/images/drop_img8.png" />Open Mic & Benefit Show</a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-3">
          <a routerLink="/management/create-project" class="btn btn-white">+Add New Project</a>
        </div>
      </div>
    </div>
  </div>

  <div class="mega_dropdown" id="my-group-menu">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <form method="post">
            <app-autocomplete [placeholder]="'Search Group..'"></app-autocomplete>
            <!--            <input type="text" name="" placeholder="Search Group" class="form-control" />-->
            <button class="search-button">
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924"
                  transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35"
                  stroke-width="0.0972" />
                <ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35"
                  stroke-width="3" />
              </svg>
            </button>
          </form>
        </div>
        <div class="col-12 col-md-3">
          <ul class="drop_list">
            <li>
              <a routerLink="/profile/group"><img src="../assets/images/drop_img1.png" />Community Forest Alliance</a>
            </li>
            <li>
              <a routerLink="/profile/group"><img src="../assets/images/drop_img2.png" />Urban Farm Initiative</a>
            </li>
            <li>
              <a routerLink="/profile/group"><img src="../assets/images/drop_img3.png" />Food Train Nashik</a>
            </li>
            <li>
              <a routerLink="/profile/group"><img src="../assets/images/drop_img4.png" />Gardens of Giving</a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-3">
          <a routerLink="/management/create-group" class="btn btn-white">+Add New Group</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- private route header -->





















<!-- public route header -->
<section class="nav_section" *ngIf="isPublicRoute">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <a routerLink="/profile" class="navbar-brand"><img src="../assets/images/logo_icon.svg" alt="#" /> Open Impact</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/">Sign In</a>
          </li>
          <li class="nav-item">
            <a class="nav-link btn-purple-dark" href="#">Sign Up</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</section>
<!-- public route header -->

<!-- chat sidebar -->
<!-- <div class="chat_box">
  <div class="gutter_padding pb-0">
    <h4>Chat</h4>
    <form method="post">
      <app-autocomplete [placeholder]="'Search users, groups or chat history..'"></app-autocomplete>
    </form>
    <div class="active_wrap chat_user_slider">
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab1.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          John
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab2.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Tom
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab3.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Peter
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab4.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Mary
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab5.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Bobby
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab6.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Jan
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab7.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          John
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab8.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Tom
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab9.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Peter
        </a>
      </div>
    </div>
  </div>
  <hr />
  <div class="gutter_padding pt-0 pb-0">
    <div class="msg_flex_wrap">
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
    </div>
  </div>
  <div class="person_chat">
    <div class="chat_top_opt">
      <a href="#" class="back_icon">
        <img src="../assets/images/arrow-left.svg" alt="#" />
      </a>
      <a href="#" class="name_flex">
        <span class="profile_img"><img src="../assets/images/collab1.png" alt="#" /></span>
        <span>Courtney Henry</span>
        <img src="../assets/images/gear.svg" alt="#" class="gear" />
      </a>
      <ul class="list-inline">
        <li class="list-inline-item">
          <a href="#">
            <img src="../assets/images/phone_call.svg" alt="#" />
          </a>
        </li>
        <li class="list-inline-item">
          <a href="#">
            <img src="../assets/images/video_call.svg" alt="#" />
          </a>
        </li>
      </ul>
    </div>
    <div class="person_profile">
      <img src="../assets/images/people1.png" alt="#" class="user_img" />
      <h3>Courtney Henry</h3>
      <p>
        You’re both in the group<br />
        “Community Forest Alliance”
      </p>
    </div>
    <div class="msg_timeline">
      <div class="time">21:32</div>
      <div class="message_flex">
        <div class="message_content right">
          <img src="../assets/images/hand.png" alt="#" class="hand" />
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="time">16:44</div>
      <div class="message_flex">
        <div class="message_content left">
          <img src="../assets/images/people1.png" alt="#" class="user_writer" />
          <div class="message_content_wrap">
            <div class="message_box">Hello, Leslie!</div>
            <div class="message_box">
              How are you doing?
              <span class="emo_icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0ZM9.74864 9.16994C9.05225 10.0619 8.15271 10.4968 7.0001 10.4968C5.84749 10.4968 4.94795 10.0619 4.25155 9.16994C4.05329 8.91601 3.68671 8.87088 3.43277 9.06915C3.17884 9.26741 3.13371 9.63399 3.33198 9.88792C4.25042 11.0642 5.48977 11.6635 7.0001 11.6635C8.51043 11.6635 9.74977 11.0642 10.6682 9.88792C10.8665 9.63399 10.8214 9.26741 10.5674 9.06915C10.3135 8.87088 9.94691 8.91601 9.74864 9.16994ZM4.6666 4.6667C4.18335 4.6667 3.7916 5.18904 3.7916 5.83337C3.7916 6.4777 4.18335 7.00004 4.6666 7.00004C5.14984 7.00004 5.5416 6.4777 5.5416 5.83337C5.5416 5.18904 5.14984 4.6667 4.6666 4.6667ZM9.3334 4.6667C8.85016 4.6667 8.4584 5.18904 8.4584 5.83337C8.4584 6.4777 8.85016 7.00004 9.3334 7.00004C9.81665 7.00004 10.2084 6.4777 10.2084 5.83337C10.2084 5.18904 9.81665 4.6667 9.3334 4.6667Z"
                    fill="#D6DCE4" />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="time">16:50</div>
      <div class="message_flex">
        <div class="message_content right">
          <div class="message_content_wrap">
            <div class="message_box">It’s morning in Tokyo 😎</div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="message_flex">
        <div class="message_content left">
          <img src="../assets/images/people1.png" alt="#" class="user_writer" />
          <div class="message_content_wrap">
            <div class="message_box">What is the most popular meal in Japan?</div>
            <div class="message_box">Do you like it?</div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="message_flex">
        <div class="message_content right">
          <div class="message_content_wrap">
            <div class="message_box">I think top two are:</div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="message_flex">
        <div class="message_content right">
          <div class="message_content_wrap">
            <div class="uploaded_img">
              <img src="../assets/images/upload_img1.png" alt="#" />
              <img src="../assets/images/upload_img2.png" alt="#" />
            </div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
    </div>
    <div class="message_typer">
      <div class="upload_flex">
        <div class="flex_col">
          <span class="camera_icon">
            <img src="../assets/images/camera.png" alt="#" />
          </span>
        </div>
        <div class="flex_col">
          <span class="gallery_icon">
            <img src="../assets/images/gallery.png" alt="#" />
          </span>
        </div>
        <div class="flex_col">
          <span class="mic_icon">
            <img src="../assets/images/mic.png" alt="#" />
          </span>
        </div>
      </div>
      <form method="post">
        <div class="form-group">
          <input type="text" name="" placeholder="Aa" class="form-control" />
          <span class="emoji_icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0ZM9.74864 9.16994C9.05225 10.0619 8.15271 10.4968 7.0001 10.4968C5.84749 10.4968 4.94795 10.0619 4.25155 9.16994C4.05329 8.91601 3.68671 8.87088 3.43277 9.06915C3.17884 9.26741 3.13371 9.63399 3.33198 9.88792C4.25042 11.0642 5.48977 11.6635 7.0001 11.6635C8.51043 11.6635 9.74977 11.0642 10.6682 9.88792C10.8665 9.63399 10.8214 9.26741 10.5674 9.06915C10.3135 8.87088 9.94691 8.91601 9.74864 9.16994ZM4.6666 4.6667C4.18335 4.6667 3.7916 5.18904 3.7916 5.83337C3.7916 6.4777 4.18335 7.00004 4.6666 7.00004C5.14984 7.00004 5.5416 6.4777 5.5416 5.83337C5.5416 5.18904 5.14984 4.6667 4.6666 4.6667ZM9.3334 4.6667C8.85016 4.6667 8.4584 5.18904 8.4584 5.83337C8.4584 6.4777 8.85016 7.00004 9.3334 7.00004C9.81665 7.00004 10.2084 6.4777 10.2084 5.83337C10.2084 5.18904 9.81665 4.6667 9.3334 4.6667Z"
                fill="#D6DCE4"></path>
            </svg>
          </span>
        </div>
        <span class="btn-white">
          <img src="../assets/images/plus.svg" alt="#" />
        </span>
      </form>
    </div>
  </div>
</div> -->
<!-- chat sidebar -->

<app-donation-popup></app-donation-popup>
<app-checkout-popup></app-checkout-popup>
<app-all-set-popup></app-all-set-popup>
