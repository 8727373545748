import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AxiosInterceptorServiceV2 {
  loadingBar: any;
  constructor(
    private loadingBarService: LoadingBarService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.loadingBar = this.loadingBarService.useRef();
    this.initAxiosInterceptor();
  }

  initAxiosInterceptor() {
    axios.interceptors.request.use(
      (request): AxiosRequestConfig => {
        if (request.headers.token) {
          request.headers.common[`Authorization`] = localStorage.getItem('token');
        }

        if (request.headers.hasLoading) {
          this.loadingBar.start();
        }
        return request;
      },
      (error) => {
        this.loadingBar.stop();
        this.toastr.error(error && error.message, 'Error');
        throw new Error(error);
      }
    );

    axios.interceptors.response.use(
      (response): AxiosResponse => {
        this.loadingBar.stop();
        return response;
      },
      (error) => {
        this.loadingBar.stop();
        if (error && error.status === 403) {
          this.toastr.error('Invalid Login or token expired');
          setTimeout(() => {
            this.authService.logout();
          }, 2500);
        } else if (error.config.headers.showToast) {
          this.toastr.error(error && error.message, 'Error');
        }
        return Promise.reject(error);
      }
    );
  }
}
