import { Component, OnInit } from '@angular/core';
import { createKendoSelectBox } from '../../../../utils/createKendoSelectBox';

@Component({
  selector: 'app-calendar-filter-modal',
  templateUrl: './calendar-filter-modal.component.html',
  styleUrls: ['./calendar-filter-modal.component.scss'],
})
export class CalendarFilterModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.createProjectSelectBox();
  }

  createProjectSelectBox() {
    const data = [
      {
        projectId: 0,
        projectImage: '../../../../assets/images/img1.png',
        projectTitle: 'First Project',
        projectDescription: 'Lorem Ipsum is dummy text',
      },
      {
        projectId: 1,
        projectImage: '../../../../assets/images/img1.png',
        projectTitle: 'Second Project',
        projectDescription: 'Lorem Ipsum is dummy text',
      },
    ];

    const headerTemplate = `<div class="dropdown-header k-widget k-header">
    <span>Section</span>
    <span>Title</span>
    </div>`;

    const itemTemplate = `<span class="k-state-default" style="background-image: url(\'../../../../assets/images/img1.png\')"></span>
    <span class="k-state-default">
      <div class="mb-2 d-flex">
        <div>
          <h3>#: data.projectTitle #</h3>
          <h5>#: data.projectDescription #</h5>
          <button class="btn btn-success">TAG</button>
        </div>
      <div>
    </span>
    `;

    const tagTemplate = `<span class="selected-value" style="background-image: url(\'../../../../assets/images/img1.png\')"></span><span>#:data.projectTitle#</span>`;

    createKendoSelectBox(
      data,
      'projectTitle',
      'projectId',
      '#projects-dropdown',
      headerTemplate,
      itemTemplate,
      tagTemplate,
      'project-profile'
    );
  }
}
