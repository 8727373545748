import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

declare var $: any;
declare let L;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit {
  options: any;
  layers: any;
  @Input() mapId;
  map: any;

  constructor() {}

  ngOnInit(): void {
    console.log('in map');
  }

  ngAfterViewInit(): void {
    var container = L.DomUtil.get(`map-${this.mapId}`);
    if (container != null) {
      container._leaflet_id = null;
    }

    this.map = L.map(`map-${this.mapId}`).setView([34.03927027245833, -118.58292632203701], 13);

    // map.invalidateSize();
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    L.marker([34.03927027245833, -118.58292632203701]).addTo(this.map).bindPopup('Topanga canyon').openPopup();
  }

  invalidateSize() {
    this.map.invalidateSize();
  }
}
