<div class="modal fade" id="to_do_popup" tabindex="-1" aria-labelledby="to_do_popupLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">to do's</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="to_do_wrap">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="form-group">
                <label class="form-label">Title</label>
                <input type="text" name="" class="form-control" placeholder="">
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <label class="form-label">End Date</label>
                <input type="text" name="" class="form-control" placeholder="DD/MM/YYYY">
              </div>
            </div>
          </div>
        </div>
        <div class="to_do_wrap">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="form-group">
                <label class="form-label">Title</label>
                <input type="text" name="" class="form-control" placeholder="">
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <label class="form-label">End Date</label>
                <input type="text" name="" class="form-control" placeholder="DD/MM/YYYY">
              </div>
            </div>
          </div>
          <div class="rmv_goal">
            <span class="icon">
              <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
                  fill="#7B3E6D"></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="add_goal">
          <span class="icon">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="14" width="30" height="2" fill="#3C7178"></rect>
              <rect x="16" width="30" height="2" transform="rotate(90 16 0)" fill="#3C7178"></rect>
            </svg>
          </span> Add another To Do
        </div>
        <hr>
        <button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>
