import { Component, OnInit } from '@angular/core';
import { createKendoSelectBox } from '../../../../utils/createKendoSelectBox';
declare var $: any;

@Component({
  selector: 'app-donation-popup',
  templateUrl: './donation-popup.component.html',
  styleUrls: ['./donation-popup.component.scss'],
})
export class DonationPopupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    $('#donation_popup')[0].addEventListener('hidden.bs.modal', function (event) {
      $($(`.donation_step1`)[0]).addClass('active');
      $($(`.donation_step2`)[0]).removeClass('active');
      $($(`.donation_step3`)[0]).removeClass('active');
    });

    this.createProjectSelectBox();
  }

  updateStep(stepClassName, currentActiveClass) {
    $($(`.${stepClassName}`)[0]).addClass('active');
    $($(`.${currentActiveClass}`)[0]).removeClass('active');
  }

  hideModal() {
    $(`#donation_popup`).modal('hide');
  }

  createProjectSelectBox() {
    const data = [
      {
        dProjectId: 0,
        dProjectTitle: 'Beach Cleanup',
        dProjectDescription: 'Lorem Ipsum is dummy text',
        dTag: 'Terestrial',
      },
      {
        dProjectId: 1,
        dProjectTitle: 'Art & Cultural Festival',
        dProjectDescription: 'Lorem Ipsum is dummy text',
        dTag: 'Aquatic',
      },
    ];

    const headerTemplate = `<div class="dropdown-header k-widget k-header">
    <span>Section</span>
    <span>Title</span>
    </div>`;

    const itemTemplate = `<span class="k-state-default" style="background-image: url(\'../../../../assets/images/img1.png\')"></span>
    <span class="k-state-default">
      <div class="mb-2 d-flex">
        <div>
          <h3>#: data.dProjectTitle #</h3>
          <h5>#: data.dProjectDescription #</h5>
          <button class="btn btn-success">#: data.dTag #</button>
        </div>
      <div>
    </span>
    `;

    const tagTemplate = `<span class="selected-value" style="background-image: url(\'../../../../assets/images/img1.png\')"></span><span>#:data.dProjectTitle#</span>`;

    createKendoSelectBox(
      data,
      'dProjectTitle',
      'dProjectId',
      '#donation-projects-dropdown',
      headerTemplate,
      itemTemplate,
      tagTemplate,
      'project-profile'
    );
  }
}
