import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.scss'],
})
export class FiltersPanelComponent implements OnInit {
  @Input() filterId;
  constructor() {}

  ngOnInit(): void {
    console.log(this.filterId, 'filter');
  }
}
