declare var $;

export const createKendoSelectBox = (
  data,
  dataTextField,
  dataValueField,
  id,
  headerTemplate,
  itemTemplate,
  tagTemplate,
  className = null
) => {
  $(id).kendoMultiSelect({
    dataTextField,
    dataValueField,
    autoClose: false,
    headerTemplate,
    footerTemplate: 'Total #: instance.dataSource.total() # items found',
    itemTemplate,
    tagTemplate,
    dataSource: {
      data: data,
    },
    height: 400,
  });

  const kendoSelect = $(id).data('kendoMultiSelect');
  kendoSelect.wrapper.attr('id', `${id}-wrapper`);

  $(`${id}-list`).addClass('circle-profile');
  if (className) {
    $(`${id}-list`).addClass(className);
  }
};
