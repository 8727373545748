<div class="modal fade" id="checkout_popup" tabindex="-1" aria-labelledby="checkoutLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="checkout_form">
        <div class="modal-body">
          <div class="text-center">
            <h4>Checkout</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <hr>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label">First Name</label>
                <input type="text" name="" placeholder="First Name" class="form-control">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label">Last Name</label>
                <input type="text" name="" placeholder="Last Name" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">Email</label>
            <input type="text" name="" placeholder="Email" class="form-control">
          </div>
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="form-group">
                <label class="form-label">Mailing Address</label>
                <input type="text" name="" placeholder="Mailing Address" class="form-control">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label class="form-label">City</label>
                <input type="text" name="" placeholder="City" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label class="form-label">State</label>
                <input type="text" name="" placeholder="State" class="form-control">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label class="form-label">Zip</label>
                <input type="text" name="" placeholder="Zip" class="form-control">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label class="form-label">Country</label>
                <input type="text" name="" placeholder="Country" class="form-control">
              </div>
            </div>
          </div>
          <hr>
          <h5>Credit Card</h5>
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="form-group">
                <label class="form-label">Card Number</label>
                <input type="text" name="" placeholder="Card Number" class="form-control">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label class="form-label">Expiration date</label>
                <input type="text" name="" placeholder="DD/MM/YYYY" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="form-group">
                <label class="form-label">Card Holder’s Name</label>
                <input type="text" name="" placeholder="Card Holder’s Name" class="form-control">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label class="form-label">Security code</label>
                <input type="text" name="" placeholder="Security code" class="form-control">
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-6">
              <h5>Billing address</h5>
            </div>
            <div class="col-6">
              <div class="check_box">
                <label class="label blue">Same as mailing address
                  <input type="checkbox" checked="checked">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="checkout_side">
        <div class="modal-header justify-content-center">
          <img src="../assets/images/checkout_img.png" alt="#" class="profile_overview_img">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
                fill="#7B3E6D" />
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <h4 class="summary_heading">Summary <a>Edit Cart</a></h4>
          <div class="summary_content">
            <div class="summary_flex">
              Open Mic & Benefit Show<span>$100.00</span>
            </div>
            <div class="summary_flex">
              Lunch for Kids<span>$75.00</span>
            </div>
            <div class="summary_flex">
              March for Climate Justice<span>$75.00</span>
            </div>
            <div class="summary_flex">
              Beach Cleanup<span>$20.00</span>
            </div>
          </div>
          <h4 class="total_heading">
            Total <span class="total">$270</span>
          </h4>
        </div>
        <div class="modal-footer">
          <div class="gutter_padding">
            <button type="button" class="btn btn-purple-dark" (click)="hideModal()" data-bs-toggle="modal"
              data-bs-target="#all_set_popup">Donate</button>
          </div>
          <img src="../assets/images/purple_print_check.png" alt="#">
        </div>
      </div>
    </div>
  </div>
</div>
