import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-form-popup',
  templateUrl: './new-form-popup.component.html',
  styleUrls: ['./new-form-popup.component.scss']
})
export class NewFormPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
