<div class="modal fade" id="donation_popup" tabindex="-1" aria-labelledby="donationLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <h5 class="modal-title">Make a Donation</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body modal_steps">
        <div class="donation_step donation_step1 active">
          <div class="donation_wrap">
            <div class="row">
              <div class="col-12 col-md-4">
                <img src="../assets/images/donate_img.png" alt="#" class="donate_img">
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label class="radio_box">
                      <input type="radio" name="radio1">
                      <span class="checkmark">$20</span>
                    </label>
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="radio_box">
                      <input type="radio" name="radio1">
                      <span class="checkmark">$50</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label class="radio_box">
                      <input type="radio" name="radio1">
                      <span class="checkmark">$100</span>
                    </label>
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="radio_box">
                      <input type="radio" name="radio1">
                      <span class="checkmark">$250</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label class="radio_box">
                      <input type="radio" name="radio1">
                      <span class="checkmark">$1000</span>
                    </label>
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="radio_box">
                      <input type="radio" name="radio1">
                      <span class="checkmark">Custom</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion" id="advanced_opt">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne8">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne8" aria-expanded="true" aria-controls="collapseOne8">
                    Advanced Options
                  </button>
                </h2>
                <div id="collapseOne8" class="accordion-collapse collapse show" aria-labelledby="headingOne8"
                  data-bs-parent="#advanced_opt">
                  <div class="accordion-body">
                    <ul class="nav nav-tabs" id="myTabModal1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="purpose1-tab" data-bs-toggle="tab"
                          data-bs-target="#purpose1" type="button" role="tab" aria-controls="purpose1"
                          aria-selected="true">Purpose</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="place1-tab" data-bs-toggle="tab" data-bs-target="#place1"
                          type="button" role="tab" aria-controls="place1" aria-selected="false">Place</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="funding1-tab" data-bs-toggle="tab" data-bs-target="#funding1"
                          type="button" role="tab" aria-controls="funding1" aria-selected="false">Funding Goal</button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabModal1Content">
                      <div class="tab-pane fade show active" id="purpose1" role="tabpanel"
                        aria-labelledby="purpose1-tab">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Item</option>
                            <option>Item</option>
                            <option>Item</option>
                          </select>
                        </div>
                        <ul class="list-inline badge_tag">
                          <li class="list-inline-item">
                            <span class="badge bg-green">
                              <svg width="12" height="11" viewBox="0 0 12 11" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                                  fill="white"></path>
                              </svg>
                              AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
                            </span>
                          </li>
                          <li class="list-inline-item">
                            <span class="badge bg-green">
                              <svg width="12" height="11" viewBox="0 0 12 11" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                                  fill="white"></path>
                              </svg>
                              HUNGER &amp; FOOD SYSTEMS <span class="close"><i class="fa fa-close"></i></span>
                            </span>
                          </li>
                          <li class="list-inline-item">
                            <span class="badge bg-green">
                              <svg width="12" height="11" viewBox="0 0 12 11" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M6.04992 10.6592L6.00193 10.688L5.97314 10.67C-3.11516 5.20811 0.00384399 -0.0630103 3.37717 0.000569407C3.48513 0.000569407 3.5943 0.0113659 3.70227 0.0245617C5.06023 0.188909 5.69963 0.942269 6.00193 1.59006C6.30304 0.942269 6.94363 0.188909 8.3016 0.0305598C11.7793 -0.397703 15.4009 5.02457 6.04992 10.6592Z"
                                  fill="white"></path>
                              </svg>
                              TERRESTRIAL ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="tab-pane fade" id="place1" role="tabpanel" aria-labelledby="place1-tab">
                        <form method="post">
                          <input type="text" name="" placeholder="Search a location" class="form-control">
                          <button class="search-button">
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924"
                                transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35"
                                stroke="#272D35" stroke-width="0.0972"></rect>
                              <ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)"
                                stroke="#272D35" stroke-width="3"></ellipse>
                            </svg>
                          </button>
                        </form>
                        <ul class="list-inline badge_tag mt-2">
                          <li class="list-inline-item">
                            <span class="badge bg-blue">
                              AQUATIC ECOLOGY <span class="close"><i class="fa fa-close"></i></span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="tab-pane fade" id="funding1" role="tabpanel" aria-labelledby="funding1-tab">
                        <ul>
                          <li class="form">
                            <input type="number" maxlength="4" value="1000" class="from" />
                            <input type="number" maxlength="4" value="500000" class="to" />
                            <input type="text" class="js-range-slider" name="my_range" value="" data-skin="round"
                              data-type="double" data-min="1000" data-max="500000" data-grid="false" />
                          </li>
                          <li>
                            <label class="label green2">under 10%
                              <input type="checkbox" checked="checked">
                              <span class="checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label class="label green2">Over 90%
                              <input type="checkbox">
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-6">
              <a href="#" class="cancel_link" data-bs-dismiss="modal">Cancel</a>
            </div>
            <div class="col-6 text-right">
              <button type="button" (click)="updateStep('donation_step2', 'donation_step1')"
                class="btn btn-purple-dark steps-btn" indexClass="donation_step" indexno="1">Give</button>
            </div>
          </div>
        </div>
        <div class="donation_step donation_step2">
          <div class="donation_wrap">
            <div class="row">
              <div class="col-12">
                <div class="price_box">
                  You are giving<br>
                  <span class="price">$250</span>
                </div>
              </div>
            </div>
            <h6>Summary</h6>
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="card">
                  <ul class="list-inline badge_tag">
                    <li class="list-inline-item">
                      <span class="badge bg-green">
                        hunger
                      </span>
                    </li>
                  </ul>
                  <img src="../assets/images/img2.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">Open Mic & Benefit Show</h5>
                    <p class="card-text">Local artists and musicians coming together to raise...</p>
                    <hr>
                    <h6>Allocation</h6>
                    <div class="price">$100</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card">
                  <ul class="list-inline badge_tag">
                    <li class="list-inline-item">
                      <span class="badge bg-green">
                        hunger
                      </span>
                    </li>
                  </ul>
                  <img src="../assets/images/img3.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">Lunch for Kids</h5>
                    <p class="card-text">Local artists and musicians coming together to raise...</p>
                    <hr>
                    <h6>Allocation</h6>
                    <div class="price">$75</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card">
                  <ul class="list-inline badge_tag">
                    <li class="list-inline-item">
                      <span class="badge bg-green">
                        hunger
                      </span>
                    </li>
                  </ul>
                  <img src="../assets/images/img4.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">March for Climate Justice</h5>
                    <p class="card-text">Local artists and musicians coming together to raise...</p>
                    <hr>
                    <h6>Allocation</h6>
                    <div class="price">$75</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-6">
              <a href="#" class="cancel_link" data-bs-dismiss="modal">Cancel</a>
            </div>
            <div class="col-6 text-right">
              <span class="adjust_link">Adjust</span><button type="button" class="btn btn-purple-dark steps-btn"
                indexClass="donation_step" indexno="2"
                (click)="updateStep('donation_step3', 'donation_step2')">Confirm</button>
            </div>
          </div>
        </div>
        <div class="donation_step donation_step3">
          <div class="donation_wrap">
            <div class="row">
              <div class="col-12">
                <div class="price_box">
                  You are giving<br>
                  <span class="price">$250</span>
                </div>
              </div>
            </div>
            <h6>Summary</h6>
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="card">
                  <span class="cancel">
                    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
                        fill="#7B3E6D" />
                    </svg>
                  </span>
                  <ul class="list-inline badge_tag">
                    <li class="list-inline-item">
                      <span class="badge bg-green">
                        hunger
                      </span>
                    </li>
                  </ul>
                  <img src="../assets/images/img2.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">Open Mic & Benefit Show</h5>
                    <p class="card-text">Local artists and musicians coming together to raise...</p>
                    <hr>
                    <h6>Allocation</h6>
                    <div class="form-group">
                      <input type="text" name="" value="100" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card">
                  <span class="cancel">
                    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
                        fill="#7B3E6D" />
                    </svg>
                  </span>
                  <ul class="list-inline badge_tag">
                    <li class="list-inline-item">
                      <span class="badge bg-green">
                        hunger
                      </span>
                    </li>
                  </ul>
                  <img src="../assets/images/img3.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">Lunch for Kids</h5>
                    <p class="card-text">Local artists and musicians coming together to raise...</p>
                    <hr>
                    <h6>Allocation</h6>
                    <div class="form-group">
                      <input type="text" name="" value="75" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card">
                  <span class="cancel">
                    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
                        fill="#7B3E6D" />
                    </svg>
                  </span>
                  <ul class="list-inline badge_tag">
                    <li class="list-inline-item">
                      <span class="badge bg-green">
                        hunger
                      </span>
                    </li>
                  </ul>
                  <img src="../assets/images/img4.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">March for Climate Justice</h5>
                    <p class="card-text">Local artists and musicians coming together to raise...</p>
                    <hr>
                    <h6>Allocation</h6>
                    <div class="form-group">
                      <input type="text" name="" value="75" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 class="small_text">Related projects</h5>
            <form method="post">
              <!-- <input type="text" name="" placeholder="Search a location" class="form-control search_dropdown--btn">
              <button class="search-button">
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924"
                    transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35"
                    stroke="#272D35" stroke-width="0.0972"></rect>
                  <ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)"
                    stroke="#272D35" stroke-width="3"></ellipse>
                </svg>
              </button> -->

              <select id="donation-projects-dropdown" class="form-control" style="width: 100%;">
              </select>
              <!-- <div class="search_dropdown">
                <h6>Recommended</h6>
                <div class="search_flex">
                  <img src="../assets/images/img1.png" alt="#">
                  <div class="text_box">
                    <h5>Beach Cleanup</h5>
                    <p>Volunteer cleanup of public beaches that line the coast of...</p>
                    <ul class="list-inline badge_tag">
                      <li class="list-inline-item">
                        <span class="badge bg-green">
                          terrestrial
                        </span>
                      </li>
                    </ul>
                    <a class="btn btn-purple-dark">ADD</a>
                  </div>
                </div>
                <hr>
                <div class="search_flex">
                  <img src="../assets/images/img2.png" alt="#">
                  <div class="text_box">
                    <h5>Arts & Culture Festival</h5>
                    <p>Volunteer cleanup of public beaches that line the coast of...</p>
                    <ul class="list-inline badge_tag">
                      <li class="list-inline-item">
                        <span class="badge bg-green">
                          terrestrial
                        </span>
                      </li>
                    </ul>
                    <a class="btn btn-purple-dark">ADD</a>
                  </div>
                </div>
                <hr>
                <div class="search_flex">
                  <img src="../assets/images/img3.png" alt="#">
                  <div class="text_box">
                    <h5>Food Train Nashik</h5>
                    <p>Volunteer cleanup of public beaches that line the coast of...</p>
                    <ul class="list-inline badge_tag">
                      <li class="list-inline-item">
                        <span class="badge bg-green">
                          terrestrial
                        </span>
                      </li>
                    </ul>
                    <a class="btn btn-purple-dark">ADD</a>
                  </div>
                </div>
              </div> -->
            </form>
          </div>
          <hr>
          <div class="row">
            <div class="col-6">
              <a href="#" class="cancel_link" data-bs-dismiss="modal">Cancel</a>
            </div>
            <div class="col-6 text-right">
              <span class="adjust_link">Adjust</span><button type="button" class="btn btn-purple-dark steps-btn"
                indexClass="donation_step" indexno="3" (click)="hideModal()" data-bs-toggle="modal"
                data-bs-target="#checkout_popup">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
