<div class="modal fade" id="calendar_filter_popup" tabindex="-1" aria-labelledby="calendar_filterLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Calendar Filter</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="form-label">Group</label>
          <form method="post">
            <input type="text" name="" placeholder="Search Group" class="form-control">
            <button class="search-button">
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924"
                  transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35"
                  stroke-width="0.0972"></rect>
                <ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35"
                  stroke-width="3"></ellipse>
              </svg>
            </button>
          </form>
        </div>
        <ul class="list-inline badge_tag mt-2">
          <li class="list-inline-item">
            <span class="badge bg-blue">
              Community Forest Alliance <span class="close"><i class="fa fa-close"></i></span>
            </span>
          </li>
        </ul>
        <hr>
        <div class="form-group">
          <label class="form-label mt-0">Project</label>
          <form method="post">
            <!-- <input type="text" name="" placeholder="Search Project" class="form-control">
            <button class="search-button">
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.91188e-07" y="0.0450582" width="8.81618" height="3.95849" rx="1.97924"
                  transform="matrix(0.46356 0.46356 -0.463556 0.463564 20.1889 18.3167)" fill="#272D35" stroke="#272D35"
                  stroke-width="0.0972"></rect>
                <ellipse cx="13.5" cy="13.5" rx="9.54594" ry="9.54594" transform="rotate(45 13.5 13.5)" stroke="#272D35"
                  stroke-width="3"></ellipse>
              </svg>
            </button> -->
            <select id="projects-dropdown" class="form-control" style="width: 100%;">
            </select>
          </form>
        </div>
        <hr>
        <button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">SAVE</button>
      </div>
    </div>
  </div>
</div>
