import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-member-profile',
  templateUrl: './about-member-profile.component.html',
  styleUrls: ['./about-member-profile.component.scss']
})
export class AboutMemberProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
