<div class="modal fade" id="privacy_popup" tabindex="-1" aria-labelledby="privacyLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Privacy</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.18922 8.12L3.98922 5.32L6.80922 8.12L7.84922 7.08L5.02922 4.28L7.84922 1.46L6.82922 0.44L3.98922 3.26L1.16922 0.44L0.149219 1.46L2.96922 4.28L0.149219 7.08L1.18922 8.12Z"
              fill="#7B3E6D" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="form-label">Privacy</label>
          <select class="form-control">
            <option>Connections</option>
            <option>Connections</option>
            <option>Connections</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label">Filter By</label>
          <div class="row">
            <div class="col-6 col-md-2">
              <label class="radio_check">All
                <input type="radio" name="t1" checked="checked">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-6 col-md-2">
              <label class="radio_check">Group
                <input type="radio" name="t1" checked="checked">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-6 col-md-2">
              <label class="radio_check">Individuals
                <input type="radio" name="t1" checked="checked">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Groups</label>
          <select class="form-control">
            <option>All</option>
            <option>All</option>
            <option>All</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label">Members</label>
          <select id="members-dropdown" class="form-control" style="width: 100%;">
          </select>
        </div>
        <div class="form-group">
          <label class="form-label">Rights</label>
          <div class="row">
            <div class="col-6 col-md-2">
              <label class="radio_check">View
                <input type="radio" name="t2" checked="checked">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-6 col-md-2">
              <label class="radio_check">Allow
                <input type="radio" name="t2" checked="checked">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-6 col-md-2">
              <label class="radio_check">Disallow
                <input type="radio" name="t2" checked="checked">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <hr>
        <button type="button" class="btn btn-purple-dark" data-bs-dismiss="modal">Add</button>
      </div>
    </div>
  </div>
</div>
