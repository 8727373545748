import { Component, OnInit } from '@angular/core';
import { createKendoSelectBox } from '../../../../utils/createKendoSelectBox';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss'],
})
export class PrivacyModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.createMemberSelectBox();
  }

  createMemberSelectBox() {
    const data = [
      {
        memberId: 0,
        memberName: 'Ontario',
      },
      {
        memberId: 1,
        memberName: 'Honey',
      },
    ];

    const headerTemplate = `<div class="dropdown-header k-widget k-header">
    <span>Section</span>
    <span>Title</span>
    </div>`;

    const itemTemplate = `<span class="k-state-default" style="background-image: url(\'../../../../assets/images/collab1.png\')"></span>
    <span class="k-state-default">
      <h3>#: data.memberName #</h3>
    </span>
    `;

    const tagTemplate = `<span class="selected-value" style="background-image: url(\'../../../../assets/images/collab1.png\')"></span><span>#:data.memberName#</span>`;

    createKendoSelectBox(
      data,
      'memberName',
      'memberId',
      '#members-dropdown',
      headerTemplate,
      itemTemplate,
      tagTemplate
    );
  }
}
