import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService as ToasterService } from 'ngx-toastr';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import apiCaller from '../utils/apiCaller';
import { createApiUrl } from '../utils/createApiUrl';
import { fetchApiHeaders } from '../utils/fetchApiHeaders';
import { getFromLocalStorage } from '../utils/getFromLocalStorage';
import { removeFromLocalStorage } from '../utils/removeFromLocalStorage';
import { saveToLocalStorage } from '../utils/saveToLocalStorage';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private toastr: ToasterService, private router: Router) {}

  login(payload) {
    const url = createApiUrl(API_ENDPOINTS.AUTH.LOGIN);
    return apiCaller.post(url, null, fetchApiHeaders({ auth: payload }));
  }

  updateAuthStorage(response) {
    console.log(response, 'res');
    const { MessageKey } = response?.data || {};
    saveToLocalStorage('token', MessageKey);
    saveToLocalStorage('user', response.data);
  }

  clearStorage() {
    removeFromLocalStorage('token');
    removeFromLocalStorage('user');
  }

  logout() {
    this.clearStorage();
    this.router.navigate(['/']);
  }

  getToken() {
    return getFromLocalStorage('token');
  }
}
