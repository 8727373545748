<div class="chat_box">
  <div class="gutter_padding pb-0">
    <h4>Chat</h4>
    <form method="post">
      <app-autocomplete [placeholder]="'Search users, groups or chat history..'"></app-autocomplete>
      <!--      <input type="text" name="" placeholder="Search users, groups or chat history" class="form-control" />-->
    </form>
    <div class="active_wrap chat_user_slider">
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab1.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          John
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab2.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Tom
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab3.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Peter
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab4.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Mary
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab5.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Bobby
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab6.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Jan
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab7.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          John
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab8.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Tom
        </a>
      </div>
      <div>
        <a href="#" class="user">
          <div class="img_box">
            <img src="../assets/images/collab9.png" alt="#" />
            <div class="active_icon"></div>
          </div>
          Peter
        </a>
      </div>
    </div>
  </div>
  <hr />
  <div class="gutter_padding pt-0 pb-0">
    <div class="msg_flex_wrap">
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
      <div class="msg_flex">
        <div class="img_box">
          <img src="../assets/images/collab1.png" alt="#" />
          <div class="active_icon"></div>
        </div>
        <div class="text_box">
          <div class="name">Leslie Alexander, Wade Warren</div>
          <div class="msg_text">Sure. I strained it the other day...</div>
          <div class="time">6m</div>
        </div>
      </div>
    </div>
  </div>
  <div class="person_chat">
    <div class="chat_top_opt">
      <a href="#" class="back_icon">
        <img src="../assets/images/arrow-left.svg" alt="#" />
      </a>
      <a href="#" class="name_flex">
        <span class="profile_img"><img src="../assets/images/collab1.png" alt="#" /></span>
        <span>Courtney Henry</span>
        <img src="../assets/images/gear.svg" alt="#" class="gear" />
      </a>
      <ul class="list-inline">
        <li class="list-inline-item">
          <a href="#">
            <img src="../assets/images/phone_call.svg" alt="#" />
          </a>
        </li>
        <li class="list-inline-item">
          <a href="#">
            <img src="../assets/images/video_call.svg" alt="#" />
          </a>
        </li>
      </ul>
    </div>
    <div class="person_profile">
      <img src="../assets/images/people1.png" alt="#" class="user_img" />
      <h3>Courtney Henry</h3>
      <p>
        You’re both in the group<br />
        “Community Forest Alliance”
      </p>
    </div>
    <div class="msg_timeline">
      <div class="time">21:32</div>
      <div class="message_flex">
        <div class="message_content right">
          <img src="../assets/images/hand.png" alt="#" class="hand" />
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="time">16:44</div>
      <div class="message_flex">
        <div class="message_content left">
          <img src="../assets/images/people1.png" alt="#" class="user_writer" />
          <div class="message_content_wrap">
            <div class="message_box">Hello, Leslie!</div>
            <div class="message_box">
              How are you doing?
              <span class="emo_icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0ZM9.74864 9.16994C9.05225 10.0619 8.15271 10.4968 7.0001 10.4968C5.84749 10.4968 4.94795 10.0619 4.25155 9.16994C4.05329 8.91601 3.68671 8.87088 3.43277 9.06915C3.17884 9.26741 3.13371 9.63399 3.33198 9.88792C4.25042 11.0642 5.48977 11.6635 7.0001 11.6635C8.51043 11.6635 9.74977 11.0642 10.6682 9.88792C10.8665 9.63399 10.8214 9.26741 10.5674 9.06915C10.3135 8.87088 9.94691 8.91601 9.74864 9.16994ZM4.6666 4.6667C4.18335 4.6667 3.7916 5.18904 3.7916 5.83337C3.7916 6.4777 4.18335 7.00004 4.6666 7.00004C5.14984 7.00004 5.5416 6.4777 5.5416 5.83337C5.5416 5.18904 5.14984 4.6667 4.6666 4.6667ZM9.3334 4.6667C8.85016 4.6667 8.4584 5.18904 8.4584 5.83337C8.4584 6.4777 8.85016 7.00004 9.3334 7.00004C9.81665 7.00004 10.2084 6.4777 10.2084 5.83337C10.2084 5.18904 9.81665 4.6667 9.3334 4.6667Z"
                    fill="#D6DCE4" />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="time">16:50</div>
      <div class="message_flex">
        <div class="message_content right">
          <div class="message_content_wrap">
            <div class="message_box">It’s morning in Tokyo 😎</div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="message_flex">
        <div class="message_content left">
          <img src="../assets/images/people1.png" alt="#" class="user_writer" />
          <div class="message_content_wrap">
            <div class="message_box">What is the most popular meal in Japan?</div>
            <div class="message_box">Do you like it?</div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="message_flex">
        <div class="message_content right">
          <div class="message_content_wrap">
            <div class="message_box">I think top two are:</div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
      <div class="message_flex">
        <div class="message_content right">
          <div class="message_content_wrap">
            <div class="uploaded_img">
              <img src="../assets/images/upload_img1.png" alt="#" />
              <img src="../assets/images/upload_img2.png" alt="#" />
            </div>
          </div>
        </div>
        <img src="../assets/images/check_icon.svg" alt="#" class="check" />
      </div>
    </div>
    <div class="message_typer">
      <div class="upload_flex">
        <div class="flex_col">
          <span class="camera_icon">
            <img src="../assets/images/camera.png" alt="#" />
          </span>
        </div>
        <div class="flex_col">
          <span class="gallery_icon">
            <img src="../assets/images/gallery.png" alt="#" />
          </span>
        </div>
        <div class="flex_col">
          <span class="mic_icon">
            <img src="../assets/images/mic.png" alt="#" />
          </span>
        </div>
      </div>
      <form method="post">
        <div class="form-group">
          <input type="text" name="" placeholder="Aa" class="form-control" />
          <span class="emoji_icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0ZM9.74864 9.16994C9.05225 10.0619 8.15271 10.4968 7.0001 10.4968C5.84749 10.4968 4.94795 10.0619 4.25155 9.16994C4.05329 8.91601 3.68671 8.87088 3.43277 9.06915C3.17884 9.26741 3.13371 9.63399 3.33198 9.88792C4.25042 11.0642 5.48977 11.6635 7.0001 11.6635C8.51043 11.6635 9.74977 11.0642 10.6682 9.88792C10.8665 9.63399 10.8214 9.26741 10.5674 9.06915C10.3135 8.87088 9.94691 8.91601 9.74864 9.16994ZM4.6666 4.6667C4.18335 4.6667 3.7916 5.18904 3.7916 5.83337C3.7916 6.4777 4.18335 7.00004 4.6666 7.00004C5.14984 7.00004 5.5416 6.4777 5.5416 5.83337C5.5416 5.18904 5.14984 4.6667 4.6666 4.6667ZM9.3334 4.6667C8.85016 4.6667 8.4584 5.18904 8.4584 5.83337C8.4584 6.4777 8.85016 7.00004 9.3334 7.00004C9.81665 7.00004 10.2084 6.4777 10.2084 5.83337C10.2084 5.18904 9.81665 4.6667 9.3334 4.6667Z"
                fill="#D6DCE4"></path>
            </svg>
          </span>
        </div>
        <span class="btn-white">
          <img src="../assets/images/plus.svg" alt="#" />
        </span>
      </form>
    </div>
  </div>
</div>
